/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import Alert from "@amzn/meridian/alert";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Toaster from "@amzn/meridian/toaster";
import { ToasterToasts } from "@amzn/meridian/toaster/toaster";
import React, { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalDialog from "../../common/modal/ModalDialog";
import { BarcodeImage, ScanReader } from "../../common/scans/ScanReader";
import { SoundType } from "../../common/soundProvider/SoundProvider";
import IdentifyByContainer, {
  IdentifyContainerResult,
} from "../../functions/identifyContainer/identifyByContainer/IdentifyByContainer";
import useMoveContainer from "../../functions/move/MoveContainer";
import Workflow, {
  MoreOptionRefProps,
  Task as WorkflowTask,
} from "../Workflow";
import ProblemContainerWorkflow, {
  Task as ProblemType,
} from "./ProblemContainerWorkflow";
import { useLazyQuery } from "@apollo/client";
import { IdentifyContainerDocument } from "../../../graphql.generated";
import ProgressMeter from "../../common/loading/ProgressMeter";

const TRANSLATION_NS = "components.workflows.subworkflows.FlowWorkflow";

enum Task {
  /** Prompt user to scan destination container barcode to move packages multiple-at-a-time. */
  ScanDestination,
  /** Move packages by scanning barcode.  */
  ScanPackage,
  /** A workflow to handle hazardous packages. */
  Hazmat,
  /** A workflow to handle packages for problem solve. */
  ProblemSolve,
}

enum Substate {
  None,
  ProblemWithPackage,
}

/**
 * The enumerated ErrorTypes that can occur in this workflow.
 */
enum ErrorType {
  ContainerNotCompatible,
  ContainerNotOpened,
  ContainerWeightLimitExceeded,
  CustomsFlagged,
  ExclusiveParent,
  Hazmat,
  InvalidDestination,
  InvalidInput,
  InvalidStackingFilter,
  InvalidTrailer,
  MoveToItself,
  ParentContainerNotOpened,
  TooManyChildren,
  UnexpectedNode,
  Unknown,
}

/**
 * Map to lookup string versions of ErrorTypes to the corresponding enumerated value.
 */
const ErrorState: Partial<{ [key: string]: ErrorType }> = {
  ContainerNotCompatible: ErrorType.ContainerNotCompatible,
  ContainerNotOpened: ErrorType.ContainerNotOpened,
  ContainerWeightLimitExceeded: ErrorType.ContainerWeightLimitExceeded,
  CustomsFlagged: ErrorType.CustomsFlagged,
  ExclusiveParent: ErrorType.ExclusiveParent,
  Hazmat: ErrorType.Hazmat,
  InvalidDestination: ErrorType.InvalidDestination,
  InvalidInput: ErrorType.InvalidInput,
  InvalidStackingFilter: ErrorType.InvalidStackingFilter,
  InvalidTrailer: ErrorType.InvalidTrailer,
  MoveToItself: ErrorType.MoveToItself,
  ParentContainerNotOpened: ErrorType.ParentContainerNotOpened,
  TooManyChildren: ErrorType.TooManyChildren,
  UnexpectedNode: ErrorType.UnexpectedNode,
};

interface FlowWorkflowProps {
  currentTask?: Task;
  localizedBannerTitle?: string;
  moreOptionsList: () => Array<MoreOptionRefProps<Task>>;
}

interface FlowWorkflowStateData {
  currentTask: Task;
  currentSubstate: Substate;
  errorState?: ErrorType;
  /** Destination container information. */
  destinationScannable: string;
  destinationDisplayName?: string;
  destinationContainerType?: string;
  destinationStackingFilter?: string;
  destinationScannableCpt?: string;
  destinationWeightUtilization?: number;
  destinationVolumeUtilization?: number;
  /** Source container information. */
  previousSourceScannable: string;
  /** Previous successful container scan, displayed as a toast. */
  toasts: ToasterToasts[];
  toastDisplayName?: string;
  toastContainerType?: string;
}

const ScanSourceContainer = (props: {
  destinationDisplayName?: string;
  destinationStackingFilter?: string;
  destinationCpt?: string;
  barcodeImage?: BarcodeImage;
  destinationScannable: string;
  onSuccess: (
    sourceScannable: string,
    destinationScannable: string,
    destinationWeightUtilization: number,
    destinationVolumeUtilization: number
  ) => void;
  onFailure: (
    sourceScannable: string,
    errorType: string,
    message: string
  ) => void;
}) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const [sourceScannable, setSourceScannable] = useState("");

  const [getWeightAndVolumeUtilization] = useLazyQuery(
    IdentifyContainerDocument,
    {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          scannable: props.destinationScannable,
        },
        withHazmat: false,
        withHierarchyInfo: false,
        withAggregateProperty: true,
      },
      onCompleted: (data) => {
        const aggregateProperty = data?.identifyContainer?.aggregateProperty;
        const destinationWeightUtilization =
          aggregateProperty?.weightProperty?.utilization ?? -1;
        const destinationVolumeUtilization =
          aggregateProperty?.volumeProperty?.currentUtilization ?? -1;

        props.onSuccess(
          sourceScannable,
          props.destinationScannable,
          destinationWeightUtilization,
          destinationVolumeUtilization
        );
      },
      onError: (error) => {
        // Swallow the error if failed to get weight and volume information
        props.onSuccess(sourceScannable, props.destinationScannable, -1, -1);
      },
    }
  );

  const moveContainer = useMoveContainer();

  return (
    <React.Fragment>
      <Box>
        <Box className="title" spacingInset="medium">
          <Text alignment="left">
            <Heading type={"h400"} level={1}>
              {t("package-scan-title")}
            </Heading>
          </Text>
        </Box>
        <Box className="message" spacingInset="none medium">
          <Text alignment="left" type="b400">
            <strong>
              {t("container-info-container-type", {
                containerType: props.destinationDisplayName,
              })}
            </strong>{" "}
            {props.destinationDisplayName}
          </Text>
          <Text alignment="left" type="b400">
            <strong>{t("container-info-stacking-filter")}</strong>{" "}
            {props.destinationStackingFilter}
          </Text>
          <Text alignment="left" type="b400">
            <strong>{t("container-info-cpt")}</strong> {props.destinationCpt}
          </Text>
        </Box>
      </Box>
      <Box className="message" spacingInset="large">
        <ScanReader
          barcodeImage={props.barcodeImage}
          callback={(sourceScannable: string) => {
            setSourceScannable(sourceScannable);
            moveContainer({
              sourceScannable: sourceScannable,
              destinationScannable: props.destinationScannable,
              isForceMove: false,
              onMoveSuccess: () => {
                getWeightAndVolumeUtilization();
              },
              onMoveFailure: (errorType, message) => {
                /** Treat MoveToSameParent as success. Associates do this to double-check the move. */
                if (errorType === "MoveToSameParent") {
                  getWeightAndVolumeUtilization();
                } else {
                  props.onFailure(sourceScannable, errorType, message);
                }
              },
            });
          }}
        />
      </Box>
    </React.Fragment>
  );
};

export const FlowWorkflow = (props: FlowWorkflowProps) => {
  const { t } = useTranslation(
    "components.workflows.subworkflows.FlowWorkflow"
  );

  const dateTimeFormat = new Intl.DateTimeFormat([], {
    dateStyle: "short",
    timeStyle: "long",
  });

  const [state, setState] = useReducer(
    (
      state: FlowWorkflowStateData,
      newState: Partial<FlowWorkflowStateData>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      currentTask: props.currentTask ?? Task.ScanDestination,
      currentSubstate: Substate.None,
      destinationScannable: "",
      previousSourceScannable: "",
      toasts: [],
    }
  );

  const resetState = (task: Task) => {
    setState({
      currentTask: task,
      errorState: undefined,
      destinationScannable: undefined,
      destinationDisplayName: undefined,
      destinationContainerType: undefined,
      destinationStackingFilter: undefined,
      destinationScannableCpt: undefined,
      toastDisplayName: undefined,
      toastContainerType: undefined,
    });
  };

  const onCloseToast = (id: string) =>
    setState({ toasts: state.toasts.filter((t) => t.id !== id) });

  const problemWithPackageOnClickAction = (optionNumber: number) => {
    setState({ currentSubstate: Substate.None });
    if (optionNumber === 0) {
      setState({ currentTask: Task.ProblemSolve });
    }
  };

  const createMoreOptionsList = (
    parentMoreOptionsList: () => Array<MoreOptionRefProps<Task>>
  ) => {
    const moreOptionList = parentMoreOptionsList();
    if (state.currentTask === Task.ScanPackage) {
      moreOptionList.push({
        displayName: t("leave-container"),
        action: () => resetState(Task.ScanDestination),
      });

      if (state.previousSourceScannable) {
        moreOptionList.push({
          displayName: t("problem-with-package"),
          action: () =>
            setState({ currentSubstate: Substate.ProblemWithPackage }),
        });
      }
    }
    return () => {
      return moreOptionList;
    };
  };

  return (
    <React.Fragment>
      <ModalDialog
        displayStatus={state.currentSubstate === Substate.ProblemWithPackage}
        headerTitle={t("problem-with-package-banner-title")}
        alertType="error"
        alertTitle={t("problem-with-package-title")}
        message={t("problem-with-package-message")}
        localizedSubmessages={[
          t("container-info-container-type", {
            // Move container is executed without first identifying the source scannable
            // so the container type is not known.
            containerType: undefined,
          }) +
            " " +
            state.previousSourceScannable,
          t("problem-with-package-wet-package-sub-message"),
        ]}
        primaryActionMessage={t("bring-to-problem-solve")}
        secondaryActionMessage={t("cancel")}
        onClickAction={problemWithPackageOnClickAction}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.ContainerNotCompatible}
        headerTitle={t("container-not-compatible-error-header")}
        alertTitle={t("container-not-compatible-error-header")}
        message={t("container-not-compatible-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("try-again")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.ContainerNotOpened}
        headerTitle={t("container-not-opened-error-header")}
        alertTitle={t("container-not-opened-error-header")}
        message={t("container-not-opened-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("back-to-home")}
        onClickAction={() => {
          setState({
            currentTask:
              state.currentTask === Task.ProblemSolve ||
              state.currentTask === Task.Hazmat
                ? state.currentTask
                : Task.ScanDestination,
            errorState: undefined,
          });
        }}
      />

      <ModalDialog
        displayStatus={
          state.errorState === ErrorType.ContainerWeightLimitExceeded
        }
        headerTitle={t("container-weight-limit-exceeded-error-header")}
        alertTitle={t("container-weight-limit-exceeded-error-header")}
        message={t("container-weight-limit-exceeded-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("package-removed")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.CustomsFlagged}
        headerTitle={t("customs-flagged-error-header")}
        alertTitle={t("customs-flagged-error-header")}
        message={t("customs-flagged-error-message")}
        alertType={"warning"}
        soundType={SoundType.WARNING}
        primaryActionMessage={t("sideline-container")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.ExclusiveParent}
        headerTitle={t("exclusive-parent-error-header")}
        alertTitle={t("exclusive-parent-error-header")}
        message={t("exclusive-parent-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("back-to-home")}
        onClickAction={() => {
          setState({
            currentTask:
              state.currentTask === Task.ProblemSolve ||
              state.currentTask === Task.Hazmat
                ? state.currentTask
                : Task.ScanDestination,
            errorState: undefined,
          });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.Hazmat}
        headerTitle={t("hazmat-error-header")}
        alertTitle={t("hazmat-error-header")}
        message={t("hazmat-error-message")}
        alertType={"warning"}
        soundType={SoundType.WARNING}
        primaryActionMessage={t("scan-hazmat-area")}
        onClickAction={() => {
          setState({
            currentTask: Task.Hazmat,
            errorState: undefined,
          });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.InvalidDestination}
        headerTitle={t("invalid-destination-header")}
        alertTitle={t("invalid-destination-header")}
        message={t("invalid-destination-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("try-again")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.InvalidInput}
        headerTitle={t("invalid-input-error-header")}
        alertTitle={t("invalid-input-error-header")}
        message={t("invalid-input-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("try-again")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.InvalidStackingFilter}
        headerTitle={t("invalid-stacking-filter-error-header")}
        alertTitle={t("invalid-stacking-filter-error-header")}
        message={t("invalid-stacking-filter-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("try-again")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.InvalidTrailer}
        headerTitle={t("invalid-trailer-error-header")}
        alertTitle={t("invalid-trailer-error-header")}
        message={t("invalid-trailer-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("back-to-home")}
        onClickAction={() => {
          setState({
            currentTask:
              state.currentTask === Task.ProblemSolve ||
              state.currentTask === Task.Hazmat
                ? state.currentTask
                : Task.ScanDestination,
            errorState: undefined,
          });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.ParentContainerNotOpened}
        headerTitle={t("parent-container-not-opened-error-header")}
        alertTitle={t("parent-container-not-opened-error-header")}
        message={t("parent-container-not-opened-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("back-to-home")}
        onClickAction={() => {
          setState({
            currentTask:
              state.currentTask === Task.ProblemSolve ||
              state.currentTask === Task.Hazmat
                ? state.currentTask
                : Task.ScanDestination,
            errorState: undefined,
          });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.TooManyChildren}
        headerTitle={t("too-many-children-error-header")}
        alertTitle={t("too-many-children-error-header")}
        message={t("too-many-children-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("package-removed")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.UnexpectedNode}
        headerTitle={t("unexpected-node-error-header")}
        alertTitle={t("unexpected-node-error-header")}
        message={t("unexpected-node-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("try-again")}
        onClickAction={() => {
          setState({
            currentTask: Task.ProblemSolve,
            errorState: undefined,
          });
        }}
      />

      <ModalDialog
        displayStatus={state.errorState === ErrorType.MoveToItself}
        headerTitle={t("move-to-itself-error-header")}
        alertTitle={t("move-to-itself-error-header")}
        message={t("move-to-itself-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("try-again")}
        onClickAction={() => {
          setState({ errorState: undefined });
        }}
      />

      {/*
        ToDo: Define proper verbiage and handling of unknown errors
      */}
      <ModalDialog
        displayStatus={state.errorState === ErrorType.Unknown}
        headerTitle={t("connection-issue-error-banner")}
        alertTitle={t("move-issue-error-header")}
        message={t("move-issue-error-message")}
        alertType={"error"}
        soundType={SoundType.ERROR}
        primaryActionMessage={t("try-again")}
        onClickAction={() => {
          setState({
            errorState: undefined,
          });
        }}
      />

      <Workflow
        title={props.localizedBannerTitle ?? t("flow-banner-title")}
        currentTaskId={state.currentTask}
        moreOptionsCallback={createMoreOptionsList(props.moreOptionsList)}
        updateCurrentTaskId={(currentTask: Task) =>
          setState({ currentTask: currentTask })
        }
      >
        <WorkflowTask taskId={Task.ScanDestination}>
          <IdentifyByContainer
            header={t("destination-scan-title")}
            message={t("destination-scan-message")}
            additionalContainerInfo={{
              withHazmat: true,
            }}
            onSuccess={(result: IdentifyContainerResult) => {
              setState({
                destinationScannable: result.directSourceScannable,
                destinationDisplayName:
                  result.containerInfo.containerLabel ??
                  result.directSourceScannable,
                destinationContainerType: result.containerInfo.containerType,
                destinationStackingFilter:
                  result.containerInfo.stackingFilter ?? undefined,
                destinationScannableCpt: result.containerInfo.cpt
                  ? dateTimeFormat.format(Number(result.containerInfo.cpt))
                  : undefined,
                toasts: [
                  ...state.toasts,
                  { id: "destinationScan", timeout: 5000 },
                ],
                toastDisplayName:
                  result.containerInfo.containerLabel ??
                  result.directSourceScannable,
                toastContainerType: result.containerInfo.containerType,
                currentTask: Task.ScanPackage,
              });
            }}
            onFailure={() => resetState(Task.ScanDestination)}
          />
        </WorkflowTask>

        <WorkflowTask taskId={Task.ScanPackage}>
          <Toaster toasts={state.toasts} onCloseToast={onCloseToast}>
            {(toast) => (
              <Alert toast={true} type="success" onClose={toast.onClose}>
                {t("scan-success-toast", {
                  containerType: state.toastContainerType,
                  scannable: state.toastDisplayName,
                })}
              </Alert>
            )}
          </Toaster>
          <ScanSourceContainer
            destinationScannable={state.destinationScannable}
            destinationDisplayName={state.destinationDisplayName}
            destinationStackingFilter={state.destinationStackingFilter}
            destinationCpt={state.destinationScannableCpt}
            barcodeImage={BarcodeImage.QR_BARCODE}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string,
              destinationWeightUtilization: number,
              destinationVolumeUtilization: number
            ) => {
              setState({
                toasts: [...state.toasts, { id: "packageScan", timeout: 5000 }],
                toastDisplayName: sourceScannable,
                toastContainerType: undefined,
                destinationScannable: destinationScannable,
                previousSourceScannable: sourceScannable,
                destinationWeightUtilization: destinationWeightUtilization,
                destinationVolumeUtilization: destinationVolumeUtilization,
              });
            }}
            onFailure={(sourceScannable, errorType) => {
              const error = ErrorState[errorType];
              /**
               * ScanSourceContainer does NOT check if the source scannable is a valid barcode. InvalidInput errors mean the
               * source scannable was not a valid barcode. In this case, the user needs to scan another barcode. The previous
               * source scannable should only be replaced by valid scannables/barcodes.
               */
              const shouldReplacePrevSourceScannable =
                error !== undefined && error !== ErrorType.InvalidInput;
              setState({
                previousSourceScannable: shouldReplacePrevSourceScannable
                  ? sourceScannable
                  : state.previousSourceScannable,
                errorState: ErrorState[errorType] ?? ErrorType.Unknown,
              });
            }}
          />
          <Box spacingInset={"medium"}>
            {state.destinationWeightUtilization !== undefined &&
              state.destinationWeightUtilization >= 0 && (
                <ProgressMeter
                  label={t("destination-weight-utilization-title")}
                  percentage={state.destinationWeightUtilization}
                />
              )}
            {state.destinationVolumeUtilization !== undefined &&
              state.destinationVolumeUtilization >= 0 && (
                <ProgressMeter
                  label={t("destination-volume-utilization-title")}
                  percentage={state.destinationVolumeUtilization}
                />
              )}
          </Box>
        </WorkflowTask>

        <WorkflowTask taskId={Task.ProblemSolve}>
          <ProblemContainerWorkflow
            problemType={ProblemType.ProblemSolve}
            localizedBannerTitle={
              props.localizedBannerTitle ?? t("flow-banner-title")
            }
            sourceScannable={state.previousSourceScannable}
            moreOptionsList={(): Array<MoreOptionRefProps<ProblemType>> => {
              const flowMoreOptionsList = createMoreOptionsList(
                props.moreOptionsList
              )();
              const newMoreOptionsList: MoreOptionRefProps<ProblemType>[] =
                flowMoreOptionsList.map((moreOptionsRef) => {
                  return {
                    displayName: moreOptionsRef.displayName,
                    action: moreOptionsRef.action,
                  };
                });
              return newMoreOptionsList;
            }}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string
            ) => {
              setState({
                toasts: [...state.toasts, { id: "packageScan", timeout: 5000 }],
                toastDisplayName: sourceScannable,
                toastContainerType: undefined,
                previousSourceScannable: sourceScannable,
                currentTask: Task.ScanPackage,
              });
            }}
            onFailure={(errorType: string, message: string) => {
              setState({
                errorState: ErrorState[errorType] ?? ErrorType.Unknown,
              });
            }}
          />
        </WorkflowTask>

        <WorkflowTask taskId={Task.Hazmat}>
          <ProblemContainerWorkflow
            problemType={ProblemType.Hazmat}
            localizedBannerTitle={
              props.localizedBannerTitle ?? t("flow-banner-title")
            }
            sourceScannable={state.previousSourceScannable}
            moreOptionsList={(): Array<MoreOptionRefProps<ProblemType>> => {
              const flowMoreOptionsList = createMoreOptionsList(
                props.moreOptionsList
              )();
              const newMoreOptionsList: MoreOptionRefProps<ProblemType>[] =
                flowMoreOptionsList.map((moreOptionsRef) => {
                  return {
                    displayName: moreOptionsRef.displayName,
                    action: moreOptionsRef.action,
                  };
                });
              return newMoreOptionsList;
            }}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string
            ) => {
              setState({
                toasts: [...state.toasts, { id: "packageScan", timeout: 5000 }],
                toastDisplayName: sourceScannable,
                toastContainerType: undefined,
                previousSourceScannable: sourceScannable,
                currentTask: Task.ScanPackage,
              });
            }}
            onFailure={(errorType: string, message: string) => {
              setState({
                errorState: ErrorState[errorType] ?? ErrorType.Unknown,
              });
            }}
          />
        </WorkflowTask>
      </Workflow>
    </React.Fragment>
  );
};

export default FlowWorkflow;
