/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { subMessagesMap } from "../../common/message/PlainMessage";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";
import { useQuery } from "@apollo/client";
import { GET_DESTINATIONS_FOR_CONTAINER_QUERY } from "./GetDestinationsForContainer.gql";
import { MoveInternalState } from "../move/Move";
import FreezeScreen from "../../common/loading/FreezeScreen";

export interface GetDestinationsForContainerProps {
  scannable: string;
  setAdditionalActions: React.Dispatch<React.SetStateAction<MoveInternalState>>;
  onSuccess: (
    priorityDestinations: subMessagesMap[],
    priorityDestinationsLabels: string[],
    isShowStaticDestinationMessage: boolean
  ) => void;
}

export function GetDestinationsForContainer(
  props: GetDestinationsForContainerProps
) {
  const { t } = useTranslation(
    "components.functions.getDestinationsForContainer.GetDestinationsForContainer"
  );
  const { trainingModeStatus, fetchPolicy } = useContext(AppContext);
  const { loading, refetch } = useQuery(GET_DESTINATIONS_FOR_CONTAINER_QUERY, {
    fetchPolicy: { trainingModeStatus } ? fetchPolicy : "no-cache",
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        scannable: props.scannable,
      },
    },
    onCompleted: (data) => {
      const priorityDestinations: subMessagesMap[] = [];
      const priorityDestinationsLabels: string[] = [];
      const isShowStaticDestinationMessage: boolean =
        data?.getDestinationsForContainer?.isShowStaticDestinationMessage;
      if (!isShowStaticDestinationMessage) {
        data?.getDestinationsForContainer?.candidateDestinationContainers.forEach(
          (item: { containerType: string; labels: string[] }) => {
            priorityDestinations.push({
              subTitle: t("container-destination-title", {
                containerType: item?.containerType,
              }),
              subMessageList: item?.labels,
            });
            priorityDestinationsLabels.push(...item?.labels);
          }
        );
      } else {
        props.setAdditionalActions("noAdditionalActions");
      }

      props.onSuccess(
        priorityDestinations,
        priorityDestinationsLabels,
        isShowStaticDestinationMessage
      );
    },
  });

  useEffect(() => {
    refetch({
      input: {
        scannable: props.scannable,
      },
    });
  }, [refetch, props.scannable]);

  if (loading) {
    return <FreezeScreen message={t("getting-container-destinations")} />;
  }
  return null;
}

export default GetDestinationsForContainer;
