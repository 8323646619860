import i18next from "i18next"
import ICU from "i18next-icu"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { WebpackImportBackend } from "./backend"
import { FcMenuLanguageDetector } from "./fcMenuLanguageDetector"

// Whether initialization is complete
let isInitialized = false // eslint-disable-line @typescript-eslint/no-unused-vars
// If initialization is complete, the last error, if any
let initializationError: string | undefined = undefined

type I18nCallback = (err: string | undefined) => void
// Registered callbacks for when i18n initialization completes
let i18nInitializationCallbacks: Array<I18nCallback> = []

const languageDetector = new LanguageDetector()
languageDetector.addDetector(FcMenuLanguageDetector)

const defaultNamespace = "strings" //this matches the strings.puff.json file name

i18next
    .use(ICU)
    .use(languageDetector)
    .use(initReactI18next)
    .use(WebpackImportBackend)
    //.loadNamespaces("strings")
    .init(
        {
            fallbackLng: ["en-US", "en"],
            debug: true, // change this to enable console logs
            interpolation: {
                escapeValue: false, // react already escapes values to prevent xss attacks
            },
            react: {
                useSuspense: false, // we do not have suspense set up
            },
            ns: [defaultNamespace],
            defaultNS: defaultNamespace,
            detection: {
                // See https://github.com/i18next/i18next-browser-languageDetector for options
                order: [
                    "querystring",
                    FcMenuLanguageDetector.name,
                    "cookie",
                    "localStorage",
                    "sessionStorage",
                    "navigator",
                ],
            },
        },
        (err?: string) => {
            if (err) {
                console.error(
                    "initialization is complete but with errors: " + err
                )
            }
            initializationError = err
            isInitialized = true // eslint-disable-line @typescript-eslint/no-unused-vars
            i18nInitializationCallbacks.forEach((callback) => {
                callback(initializationError)
            })
            i18nInitializationCallbacks.length = 0
        }
    )
