/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { AreSiblingsDocument } from "../../../graphql.generated";
import { GraphQLError } from "graphql";

export const IdentifyContainerBy2PackageMock = [
  {
    request: {
      query: AreSiblingsDocument,
      variables: {
        identifier: {
          scannables: ["test_002", "test_003"],
        },
      },
    },
    newData: () => ({
      data: {
        areSiblings: {
          scannable: "Pallet_sj939js_01",
          containerType: "Pallet",
        },
      },
    }),
  },
  {
    request: {
      query: AreSiblingsDocument,
      variables: {
        identifier: {
          scannables: ["test_001", "test_002"],
        },
      },
    },
    newData: () => ({
      data: {
        areSiblings: {
          scannable: "test_002",
          containerType: "Bag",
        },
      },
    }),
  },
  {
    request: {
      query: AreSiblingsDocument,
      variables: {
        identifier: {
          scannables: ["test_001", "test_003"],
        },
      },
    },
    newData: () => {
      return {
        errors: [
          new GraphQLError(
            "NotSiblingsException",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            {
              rootCause: "NotSiblingsException",
              classification: "DataFetchingException",
            }
          ),
        ],
        data: null,
      };
    },
  },
];
