/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { GET_DESTINATIONS_FOR_CONTAINER_QUERY } from "../../functions/getDestinationsForContainer/GetDestinationsForContainer.gql";

export const VALID_SCANNABLE = "Pallet_sj939js_01";
export const SHOW_STATIC_MESSAGE_SCANNABLE = "test";
export const GetDestinationsForContainerMock = [
  {
    request: {
      query: GET_DESTINATIONS_FOR_CONTAINER_QUERY,
      variables: {
        input: {
          scannable: VALID_SCANNABLE,
        },
      },
    },
    newData: () => ({
      data: {
        getDestinationsForContainer: {
          candidateDestinationContainers: [
            {
              containerType: "STACKING_AREA",
              labels: ["test_SA", "test_3"],
            },
            {
              containerType: "STAGING_AREA",
              labels: ["test_Staging_Area", "test_3", "test_1"],
            },
            {
              containerType: "DOCK_DOOR",
              labels: ["test_DD", "test_3"],
            },
          ],
          isShowStaticDestinationMessage: false,
        },
      },
    }),
  },
  {
    request: {
      query: GET_DESTINATIONS_FOR_CONTAINER_QUERY,
      variables: {
        input: {
          scannable: SHOW_STATIC_MESSAGE_SCANNABLE,
        },
      },
    },
    newData: () => ({
      data: {
        getDestinationsForContainer: {
          candidateDestinationContainers: [],
          isShowStaticDestinationMessage: true,
        },
      },
    }),
  },
  {
    request: {
      query: GET_DESTINATIONS_FOR_CONTAINER_QUERY,
      variables: {
        input: {
          scannable: "Package_002",
        },
      },
    },
    newData: () => ({
      data: {
        getDestinationsForContainer: {
          candidateDestinationContainers: [
            {
              containerType: "STACKING_AREA",
              labels: ["SAM-SA-1", "SAM-SA-2", "SAM-SA-3", "SAM-SA-4"],
            },
            {
              containerType: "PALLET",
              labels: ["PALLET_mVrVKGA_Z"],
            },
          ],
          isShowStaticDestinationMessage: false,
        },
      },
    }),
  },
];
