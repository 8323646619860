import Box from "@amzn/meridian/box"
import Heading from "@amzn/meridian/heading"
import Button from "@amzn/meridian/button"
import Column from "@amzn/meridian/column"
import {useHistory} from "react-router-dom"

export function ExperimentSelector() {
    let history = useHistory()

    return (
        <Box spacingInset="100 300">
            <Heading level={2} alignment={"center"}>Welcome to the Process Tech Labs page!</Heading>
            <Column alignmentHorizontal={"center"} spacingInset={"500"} spacing={"400"}>
                <Button onClick={() => history.push("/audit/trailer")}>
                    Audit Carts On A Trailer
                </Button>
                <Button onClick={() => history.push("/audit/site")}>
                    Audit Carts At A Site
                </Button>
                <Button onClick={() => history.push("/cartToRABMapping")}>
                    Cart to RAB Mapping
                </Button>
                <Button onClick={() => history.push("/redTagCart")}>
                    Red Tag Cart
                </Button>
            </Column>
        </Box>
    )
}
