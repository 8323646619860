/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import { MockedResponse } from "@apollo/client/testing";
import { GraphQLError } from "graphql/error/GraphQLError";
import { IdentifyContainerDocument } from "../../../graphql.generated";

export const IdentifyByContainerMock: ReadonlyArray<MockedResponse> = [
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "SAM-SA-1",
        },
      },
    },
    newData: () => ({
      data: {
        identifyContainer: {
          containerId: "SAM-SA-1",
          containerType: "Stacking Area",
          containerLabel: "SAM-SA-1",
          scannables: ["SAM-SA-1"],
        },
      },
    }),
  },
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "Package_001",
        },
        withHazmat: true,
        withHierarchyInfo: false,
        withAggregateProperty: false,
      },
    },
    newData: () => ({
      data: {
        identifyContainer: {
          containerId: "Package_001",
          containerType: "PACKAGE",
          containerLabel: "Package_001",
          scannables: ["Package_001"],
          stackingFilter: "MDW5-CYCLE1",
          vsm: "MDW5",
          cpt: "1660465800000",
          hazmatInfo: {
            hasHazmat: false,
          },
        },
      },
    }),
  },
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "Package_002",
        },
        withHazmat: true,
        withHierarchyInfo: false,
        withAggregateProperty: false,
      },
    },
    newData: () => ({
      data: {
        identifyContainer: {
          containerId: "Package_002",
          containerType: "PACKAGE",
          containerLabel: "Package_002",
          scannables: ["Package_002"],
          stackingFilter: "CVG5-CYCLE1",
          vsm: "CVG5",
          cpt: "1660465800000",
          hazmatInfo: {
            hasHazmat: false,
          },
        },
      },
    }),
  },
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "PALLET_mVrVKGA_Z",
        },
        withHazmat: true,
        withHierarchyInfo: false,
        withAggregateProperty: false,
      },
    },
    newData: () => ({
      data: {
        identifyContainer: {
          containerId: "PALLET_mVrVKGA_Z",
          containerType: "PALLET",
          containerLabel: "PALLET_mVrVKGA_Z",
          scannables: ["PALLET_mVrVKGA_Z"],
          stackingFilter: "SAM_DWS_SF_1",
          cpt: "1660465800000",
          hazmatInfo: {
            hasHazmat: false,
          },
          vsm: "CVG5",
        },
      },
    }),
  },
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "PALLET_mVrVKGA_Z",
        },
        withHazmat: false,
        withHierarchyInfo: false,
        withAggregateProperty: true,
      },
    },
    newData: () => ({
      data: {
        identifyContainer: {
          containerId: "PALLET_mVrVKGA_Z",
          containerType: "PALLET",
          containerLabel: "PALLET_mVrVKGA_Z",
          scannables: ["PALLET_mVrVKGA_Z"],
          stackingFilter: "SAM_DWS_SF_1",
          cpt: "1660465800000",
          aggregateProperty: {
            volumeProperty: {
              currentUtilization: 20,
              targetUtilization: 45,
            },
            weightProperty: {
              currentWeight: 10,
              unitOfMeasurement: "kg",
              utilization: 45,
            },
          },
          vsm: "CVG5",
        },
      },
    }),
  },
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "DCM_TEST_DOCK_DOOR",
        },
      },
    },
    newData: () => ({
      data: {
        identifyContainer: {
          containerId: "DCM_TEST_DOCK_DOOR",
          containerType: "Dock Door",
          containerLabel: "DCM_TEST_DOCK_DOOR",
          scannables: ["DCM_TEST_DOCK_DOOR"],
        },
      },
    }),
  },
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "INVALID_SCANNABLE_01",
        },
        withHazmat: true,
        withHierarchyInfo: false,
        withAggregateProperty: false,
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidInput",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: IdentifyContainerDocument,
      variables: {
        input: {
          scannable: "WRONG_NODE_SCANNABLE_01",
        },
        withHazmat: true,
        withHierarchyInfo: false,
        withAggregateProperty: false,
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "UnexpectedNode",
          }
        ),
      ],
    }),
  },
];
