import { BackendModule } from "i18next"

/**
 * i18next backend module for loading files as json.
 * Given a language tag, find the correct file name and loads it.
 *
 * Note that the file name must be calculated inside the import() statement.
 * Webpack uses it to determine which files to pack during build time.
 * If the calculation is moved to a separate function or variable, the files will be missing.
 *
 * Adapted from https://www.i18next.com/overview/plugins-and-utils#backends
 * see "Webpack import backend".
 *
 * Also see https://www.i18next.com/principles/fallback for fallback behavior
 * in case this backend fails.
 */
export const WebpackImportBackend: BackendModule = {
    type: "backend",
    init: (_services, _backendOptions, _i18nextOptions) => {},
    read: (language, namespace, callback) =>
        import(`./translations/${language.substring(0, 2)}.json`)
            .then((resources) => callback(null, resources[language][namespace]))
            .catch((error) => callback(error, null)),
}
