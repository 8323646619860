import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import SideMenu, { SideMenuLink, SideMenuTitle } from "@amzn/meridian/side-menu"
import { useHistory, useLocation } from "react-router-dom"
import { AppContext } from "../App"
import Toggle from "@amzn/meridian/toggle"
import Divider from "@amzn/meridian/divider"
import Link from "@amzn/meridian/link"
import Button from "@amzn/meridian/button"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import { Cookies } from "react-cookie"
import {FCMENU_SENSITIVE_COOKIE_NAME} from "../../environment/fcMenu";
import {getConfiguration} from "../../configuration/configuration";

const cookies = new Cookies()

export interface AppSideMenuProps {
    /**
     * Represent the current display state of the side menu.
     */
    openStatus: boolean

    /**
     * This will be triggered
     * 1) on click of 'x' or
     * 2) after selecting a menu
     * 3) click any other area of screen, other than menu
     * This method will close/hide the side menu.
     */
    closeMenu: () => void
}

export function AppSideMenu(props: AppSideMenuProps) {
    const { t } = useTranslation("components.layout.AppSideMenu")
    let history = useHistory()
    const location = useLocation()
    const [currentPath, setCurrentPath] = useState(location.pathname)
    const { trainingModeStatus, toggleTrainingMode } = useContext(AppContext)

    const onChangeTrainingMode = () => {
        toggleTrainingMode()
        onClickInternalLink("/")
    }

    const onClickInternalLink = (href: string) => {
        setCurrentPath(href)
        props.closeMenu()
        history.push(href)
    }

    const onClickSignOut = () => {
        props.closeMenu()
        cookies.remove(FCMENU_SENSITIVE_COOKIE_NAME)
        window.location.href = getConfiguration().loginUrl + "/logout"
    }

    return (
        <SideMenu
            open={props.openStatus}
            onClose={props.closeMenu}
            type="overlay"
        >
            <SideMenuTitle>
                <b>{trainingModeStatus && t("training-mode-subtitle")}</b>
            </SideMenuTitle>
            <SideMenuLink
                href="/"
                selected={currentPath === "/"}
                onClick={onClickInternalLink}
            >
                <Text>Labs Home</Text>
            </SideMenuLink>
            <SideMenuLink
                href="/audit/trailer"
                selected={currentPath === "/audit/trailer"}
                onClick={onClickInternalLink}
            >
                <Text>Audit Carts On A Trailer</Text>
            </SideMenuLink>
            <SideMenuLink
                href="/audit/site"
                selected={currentPath === "/audit/site"}
                onClick={onClickInternalLink}
            >
                <Text>Audit Carts At A Site</Text>
            </SideMenuLink>
            <SideMenuLink
                href="/edgeDeviceMapping"
                selected={currentPath === "/cartToRABMapping"}
                onClick={onClickInternalLink}
            >
                <Text>Cart to RAB Mapping</Text>
            </SideMenuLink>
            <div>
                <Divider
                    size="small"
                    spacingBefore="none"
                    spacingAfter="none"
                />
                <Box spacingInset="small">
                    <b>{t("training-mode-toggle")}</b>
                    <Toggle
                        checked={trainingModeStatus}
                        onChange={onChangeTrainingMode}
                        size="medium"
                    />
                </Box>
                <Button
                    type="link"
                    href="/textFeedback"
                    onClick={onClickInternalLink}
                    size="small"
                >
                    <Text alignment="left" type="b300">
                        {t("give-feedback")}
                    </Text>
                </Button>
                <Box spacingInset="small">
                    <Link type={"secondary"} onClick={onClickSignOut}>
                        {t("sign-out")}
                    </Link>
                </Box>
            </div>
        </SideMenu>
    )
}
