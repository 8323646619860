/**
 * Helper function to emit a beep sound in the browser using the Web Audio API.
 *
 * @param {number} duration - The duration of the beep sound in milliseconds.
 * @param {number} frequency - The frequency of the beep sound.
 * @param {number} volume - The volume of the beep sound.
 *
 * @returns {Promise} - A promise that resolves when the beep sound is finished.
 */

const myAudioContext = new AudioContext();

export async function successBeep() {
    await beep()
}

export async function errrorBeep() {
    const props = {
        ...beepPropsDefaults,
        frequency: 600
    }
    await beep(props)
    await beep(props)
    await beep(props)
}

interface BeepProps {
    duration?: number,
    frequency?: number,
    volume?: number
}

const beepPropsDefaults: BeepProps = {
    duration: 200,
    frequency: 440,
    volume: 100
}

function beep(props?: BeepProps) {
    return new Promise<void>((resolve, reject) => {
        const propValues = {
            ...beepPropsDefaults,
            ...props
        }

        try {
            let oscillatorNode = myAudioContext.createOscillator();
            let gainNode = myAudioContext.createGain();
            oscillatorNode.connect(gainNode);

            // Set the oscillator frequency in hertz
            oscillatorNode.frequency.value = propValues.frequency!;

            // Set the type of oscillator
            oscillatorNode.type = "square";
            gainNode.connect(myAudioContext.destination);

            // Set the gain to the volume
            gainNode.gain.value = propValues.volume! * 0.01;

            // Start audio with the desired duration
            oscillatorNode.start(myAudioContext.currentTime);
            oscillatorNode.stop(myAudioContext.currentTime + propValues.duration! * 0.001);

            // Resolve the promise when the sound is finished
            oscillatorNode.onended = () => {
                resolve();
            };
        } catch (error) {
            reject(error);
        }
    });
}