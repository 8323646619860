export enum StageEnum {
    BETA = "beta",
    GAMMA = "gamma",
    PROD_NA = "prod_na",
    PROD_EU = "prod_eu",
}

export enum GoCartServiceRegion {
    BETA = "us-west-2",
    GAMMA = "us-east-1",
    PROD_NA = "us-east-1",
    PROD_EU = "eu-west-1",
}

export enum HeiseServiceRegion {
    BETA = "us-east-1",
    GAMMA = "us-east-1",
    PROD_NA = "us-east-1",
    PROD_EU = "eu-west-1",
}

export interface Configuration {
    loginUrl: string
    credentialsUrl: string
    edgeMappingServiceUrl: string
    lightControlServiceUrl: string
    dynamicContainerizationServiceUrl: string
    goCartServiceUrl: string
    heiseServiceUrl: string
    region: string
}

export function getStage(): StageEnum {
    //TODO: we could get this from the URL, but it would be better if there was an ENV variable
    const hostname = window.location.hostname
    if (hostname.includes("localhost")) {
        return StageEnum.BETA
    }
    if (hostname.startsWith("beta")) {
        return StageEnum.BETA
    }
    if (hostname.startsWith("gamma")) {
        return StageEnum.GAMMA
    }
    if (hostname.startsWith("prod.na")) {
        return StageEnum.PROD_NA
    }
    if (hostname.startsWith("fcmenu.amazon.com")) {
        return StageEnum.PROD_NA
    }
    if (hostname.startsWith("prod.eu")) {
        return StageEnum.PROD_EU
    }
    throw new Error("unable to determine region from hostname: " + hostname)
}

export function getGoCartServiceRegion(): string {
    //TODO: we could get this from the URL, but it would be better if there was an ENV variable
    const hostname = window.location.hostname
    if (hostname.includes("localhost")) {
        return GoCartServiceRegion.BETA
    }
    if (hostname.startsWith("beta")) {
        return GoCartServiceRegion.BETA
    }
    if (hostname.startsWith("gamma")) {
        return GoCartServiceRegion.GAMMA
    }
    if (hostname.startsWith("prod.na")) {
        return GoCartServiceRegion.PROD_NA
    }
    if (hostname.startsWith("fcmenu.amazon.com")) {
        return GoCartServiceRegion.PROD_NA
    }
    if (hostname.startsWith("prod.eu")) {
        return GoCartServiceRegion.PROD_EU
    }
    throw new Error("unable to determine GoCartService region from hostname: " + hostname)
}

export function getHeiseServiceRegion(): string {
    //TODO: we could get this from the URL, but it would be better if there was an ENV variable
    const hostname = window.location.hostname
    if (hostname.includes("localhost")) {
        return HeiseServiceRegion.BETA
    }
    if (hostname.startsWith("beta")) {
        return HeiseServiceRegion.BETA
    }
    if (hostname.startsWith("gamma")) {
        return HeiseServiceRegion.GAMMA
    }
    if (hostname.startsWith("prod.na")) {
        return HeiseServiceRegion.PROD_NA
    }
    if (hostname.startsWith("fcmenu.amazon.com")) {
        return HeiseServiceRegion.PROD_NA
    }
    if (hostname.startsWith("prod.eu")) {
        return HeiseServiceRegion.PROD_EU
    }
    throw new Error("unable to determine HeiseService region from hostname: " + hostname)
}

export function getConfiguration(): Configuration {
    const stage = getStage()
    switch (stage) {
        case StageEnum.BETA:
            return {
                loginUrl: "https://fcmenu.integ.amazon.com",
                credentialsUrl: "https://q738gecwtj.execute-api.us-east-1.amazonaws.com/beta",
                edgeMappingServiceUrl: "https://4egz3ys3nj.execute-api.us-east-1.amazonaws.com/beta",
                lightControlServiceUrl: "https://vfyrmn7mnd.execute-api.us-east-1.amazonaws.com/beta",
                dynamicContainerizationServiceUrl: "https://auh2elva1j.execute-api.us-east-1.amazonaws.com/betaNA",
                goCartServiceUrl: "https://tfp4im9o1k.execute-api.us-west-2.amazonaws.com/beta",
                heiseServiceUrl: "https://beta-na.pinpoint.process-tech.ats.amazon.dev/",
                region: "us-east-1",
            }
        case StageEnum.GAMMA:
            return {
                loginUrl: "https://fcmenu.integ.amazon.com",
                credentialsUrl: "https://m54aa3o92e.execute-api.us-east-1.amazonaws.com/gamma/",
                edgeMappingServiceUrl: "https://mqveoh40db.execute-api.us-east-1.amazonaws.com/gamma/",
                lightControlServiceUrl: "https://pg6blngycd.execute-api.us-east-1.amazonaws.com/gamma/",
                dynamicContainerizationServiceUrl: "https://2lq71e0guh.execute-api.us-east-1.amazonaws.com/gammaNA",
                goCartServiceUrl: "https://oycswkt3ob.execute-api.us-east-1.amazonaws.com/gamma",
                heiseServiceUrl: "https://gamma-na.pinpoint.process-tech.ats.amazon.dev/",
                region: "us-east-1",
            }
        case StageEnum.PROD_NA: {
            return {
                loginUrl: "https://fcmenu.amazon.com",
                credentialsUrl: "https://cbsx01055h.execute-api.us-east-1.amazonaws.com/prod/",
                edgeMappingServiceUrl: "https://9xczw79fbj.execute-api.us-east-1.amazonaws.com/prod/",
                lightControlServiceUrl: "https://yx4cd5p0s5.execute-api.us-east-1.amazonaws.com/prod/",
                dynamicContainerizationServiceUrl: "https://2djaczgew1.execute-api.us-east-1.amazonaws.com/prodNA",
                goCartServiceUrl: "https://cqed33jide.execute-api.us-east-1.amazonaws.com/prod",
                heiseServiceUrl: "https://prod-na.pinpoint.process-tech.ats.amazon.dev/",
                region: "us-east-1",
            }
        }
        case StageEnum.PROD_EU: {
            return {
                loginUrl: "https://fcmenu.amazon.com",
                credentialsUrl: "https://fkfmvw8tqe.execute-api.eu-west-1.amazonaws.com/prod/",
                edgeMappingServiceUrl: "https://2d6thlhvvk.execute-api.eu-west-1.amazonaws.com/prod/",
                lightControlServiceUrl: "https://cf7mannnd6.execute-api.eu-west-1.amazonaws.com/prod/",
                dynamicContainerizationServiceUrl: "https://auh2elva1j.execute-api.us-east-1.amazonaws.com/betaNA",
                goCartServiceUrl: "https://w8g3qnchsc.execute-api.eu-west-1.amazonaws.com/prod",
                heiseServiceUrl: "https://prod-eu.pinpoint.process-tech.ats.amazon.dev/",
                region: "eu-west-1",
            }
        }
        default:
            throw new Error("prod configuration not yet configured")
    }
}
