/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import Alert from "@amzn/meridian/alert";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import Text from "@amzn/meridian/text";
import Toaster from "@amzn/meridian/toaster";
import { ToasterToasts } from "@amzn/meridian/toaster/toaster";
import React, { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { subMessagesMap } from "../../common/message/PlainMessage";
import ModalDialog, { ModalDialogProps } from "../../common/modal/ModalDialog";
import { BarcodeImage, ScanReader } from "../../common/scans/ScanReader";
import { SoundType } from "../../common/soundProvider/SoundProvider";
import GetDestinationsForContainer from "../../functions/getDestinationsForContainer/GetDestinationsForContainer";
import IdentifyByContainer, {
  IdentifyContainerResult,
} from "../../functions/identifyContainer/identifyByContainer/IdentifyByContainer";
import { ContainerInfoHeader, Paginate } from "../../functions/move/Move";
import useMoveContainer from "../../functions/move/MoveContainer";
import Workflow, {
  MoreOptionRefProps,
  Task as WorkflowTask,
} from "../Workflow";
import ProblemContainerWorkflow, {
  Task as ProblemType,
} from "./ProblemContainerWorkflow";
import ProgressMeter from "../../common/loading/ProgressMeter";
import { useLazyQuery } from "@apollo/client";
import { IdentifyContainerDocument } from "../../../graphql.generated";
import {FCMenuDecoder, FCMenuProperties} from "../../../environment/fcMenu";
import {DynamicContainerizationRepo} from "../../../repo/dynamicContainerizationRepo";
import Button from "@amzn/meridian/button";

const TRANSLATION_NS = "components.workflows.subworkflows.TrickleWorkflow";
const DEFAULT_DESTINATIONS_PAGE_SIZE = 3;

enum Task {
  /** Move packages by scanning source package and destination container one-at-a-time.  */
  ScanPackage,
  /** Prompt user to scan destination container barcode. */
  ScanDestination,
  /** Start workflow to move package to problem solve destination. */
  ProblemSolve,
  /** Start workflow to force move package to hazmat destination. */
  Hazmat,
}

enum Substate {
  None,
  ScanError,
  ProblemWithPackage,
}

interface TrickleWorkflowProps {
  currentTask?: Task;
  localizedBannerTitle?: string;
  moreOptionsList: () => Array<MoreOptionRefProps<Task>>;
  dynamicContainerizationRepo?: DynamicContainerizationRepo;
}

interface TrickleWorkflowStateData {
  currentTask: Task;
  currentSubstate: Substate;
  /** Source package container information. */
  sourceScannable: string;
  sourceDisplayName?: string;
  sourceContainerType?: string;
  sourceStackingFilter?: string;
  sourceScannableCpt?: string;
  sourceScannableVsm?: string;
  candidateDestinationScannables: string[];
  /** Destination container information. */
  destinationScannable?: string;
  destinationContainerType?: string;
  destinationStackingFilter?: string;
  destinationScannableCpt?: string;
  destinationListPageNumber: number;
  destinationWeightUtilization?: number;
  destinationVolumeUtilization?: number;
  /** Previous successful container scan, displayed as a toast. */
  toasts: ToasterToasts[];
  toastDisplayName?: string;
  toastContainerType?: string;
  /** Error modal dialog properties, displayed when move exception occurs */
  errorModalDialogProps: Omit<ModalDialogProps, "displayStatus">;
}

/**
 * Scan and move component that takes a source scannable and the user input for
 * the destination scannable, then executes move container from source to destination.
 * @param props inputs to this function
 * @returns a scan reader to get user input and executes move container
 */
const ScanContainer = (props: {
  sourceScannable: string;
  header: string;
  containerInfoHeader?: ContainerInfoHeader;
  subMessages: subMessagesMap[];
  barcodeImage?: BarcodeImage;
  onSuccess: (
    sourceScannable: string,
    destinationScannable: string,
    destinationWeightUtilization: number,
    destinationVolumeUtilization: number
  ) => void;
  onFailure: (errorType: string, message: string) => void;
  onUndo: () => void;
}) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const [destinationScannable, setDestinationScannable] = useState("");
  const header = t(props.header);
  const subMessages = props.subMessages.map((item: subMessagesMap) => {
    return {
      subTitle: item.subTitle,
      subMessageList: item.subMessageList,
      subVsmTitle: item.subVsmTitle,
      subVsmLabel: item.subVsmLabel,
    };
  });

  const [getWeightAndVolumeUtilization] = useLazyQuery(
    IdentifyContainerDocument,
    {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      variables: {
        input: {
          scannable: destinationScannable,
        },
        withHazmat: false,
        withHierarchyInfo: false,
        withAggregateProperty: true,
      },
      onCompleted: (data) => {
        const aggregateProperty = data?.identifyContainer?.aggregateProperty;
        const destinationWeightUtilization =
          aggregateProperty?.weightProperty?.utilization ?? -1;
        const destinationVolumeUtilization =
          aggregateProperty?.volumeProperty?.currentUtilization ?? -1;

        props.onSuccess(
          props.sourceScannable,
          destinationScannable,
          destinationWeightUtilization,
          destinationVolumeUtilization
        );
      },
      onError: (error) => {
        // Swallow the error if failed to get weight and volume information
        props.onSuccess(props.sourceScannable, destinationScannable, -1, -1);
      },
    }
  );

  const moveContainer = useMoveContainer();

  return (
    <React.Fragment>
      <Box className="title" spacingInset="small">
        <Text alignment="left">
          <Heading type={"h400"} level={1}>
            {header}
          </Heading>
        </Text>
      </Box>
      {props.containerInfoHeader && (
        <Box className="message" spacingInset="none medium">
          {props.containerInfoHeader.scannable && (
            <Text alignment="left" type="b400">
              <strong>
                {t("container-info-container-type", {
                  containerType: props.containerInfoHeader.containerType,
                })}
              </strong>{" "}
              {props.containerInfoHeader.scannable}
            </Text>
          )}
          {props.containerInfoHeader.packageCount && (
            <Text alignment="left" type="b400">
              <strong>{t("container-info-package-count")}</strong>{" "}
              {props.containerInfoHeader.packageCount}
            </Text>
          )}
          {props.containerInfoHeader.cpt && (
            <Text alignment="left" type="b400">
              <strong>{t("container-info-cpt")}</strong>{" "}
              {props.containerInfoHeader.cpt}
            </Text>
          )}
          {props.containerInfoHeader.stackingFilter && (
            <Text alignment="left" type="b400">
              <strong>{t("container-info-stacking-filter")}</strong>{" "}
              {props.containerInfoHeader.stackingFilter}
            </Text>
          )}
        </Box>
      )}
      <Box className="message" spacingInset="xxsmall">
        <ScanReader
          subMessages={subMessages}
          barcodeImage={props.barcodeImage}
          callback={(destinationScannable: string) => {
            setDestinationScannable(destinationScannable);
            moveContainer({
              sourceScannable: props.sourceScannable,
              destinationScannable: destinationScannable,
              isForceMove: false,
              onMoveSuccess: () => {
                getWeightAndVolumeUtilization();
              },
              onMoveFailure: (errorType, message) => {
                /** Treat MoveToSameParent as success. Associates do this to double-check the move. */
                if (errorType === "MoveToSameParent") {
                  getWeightAndVolumeUtilization();
                } else {
                  props.onFailure(errorType, message);
                }
              },
            });
          }}
        />
      </Box>
      <Box spacingInset="small">
        <Button size="large" minWidth="100%" onClick={props.onUndo}>
          Undo Scan
        </Button>
      </Box>
    </React.Fragment>
  );
};

export const TrickleWorkflow = (props: TrickleWorkflowProps) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const [fcMenuProps] = useState<FCMenuProperties|undefined>(FCMenuDecoder.decodeFcMenuCookie())

  const dateTimeFormat = new Intl.DateTimeFormat([], {
    dateStyle: "short",
    timeStyle: "long",
  });

  const [state, setState] = useReducer(
    (
      state: TrickleWorkflowStateData,
      newState: Partial<TrickleWorkflowStateData>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      currentTask: props.currentTask ?? Task.ScanPackage,
      currentSubstate: Substate.None,
      sourceScannable: "",
      candidateDestinationScannables: [],
      destinationListPageNumber: 0,
      toasts: [],
      errorModalDialogProps: {
        headerTitle: t("connection-issue-error-banner"),
        alertType: "error",
        alertTitle: t("move-issue-error-header"),
        message: t("move-issue-error-message"),
        primaryActionMessage: t("try-again"),
        soundType: SoundType.ERROR,
        onClickAction: () => {},
      },
    }
  );

  const resetState = (task: Task) => {
    setState({
      currentTask: task,
      currentSubstate: Substate.None,
      sourceScannable: undefined,
      sourceDisplayName: undefined,
      sourceContainerType: undefined,
      sourceStackingFilter: undefined,
      sourceScannableCpt: undefined,
      sourceScannableVsm: undefined,
      candidateDestinationScannables: [],
      destinationScannable: undefined,
      destinationContainerType: undefined,
      destinationStackingFilter: undefined,
      destinationScannableCpt: undefined,
      destinationListPageNumber: 0,
      toasts: [],
      toastDisplayName: undefined,
      toastContainerType: undefined,
    });
  };

  const onCloseToast = (id: string) =>
    setState({ toasts: state.toasts.filter((t) => t.id !== id) });

  const createMoreOptionsList = (
    parentMoreOptionsList: () => Array<MoreOptionRefProps<Task>>
  ) => {
    const moreOptionList = parentMoreOptionsList();
    if (state.currentTask === Task.ScanDestination) {
      moreOptionList.push(
        {
          displayName: t("see-more-destinations"),
          action: () =>
            setState({
              destinationListPageNumber: state.destinationListPageNumber + 1,
            }),
        },
        {
          displayName: t("problem-with-package"),
          action: () =>
            setState({ currentSubstate: Substate.ProblemWithPackage }),
        }
      );
    }
    return () => {
      return moreOptionList;
    };
  };

  const problemWithPackageOnClickAction = (optionNumber: number) => {
    setState({ currentSubstate: Substate.None });
    if (optionNumber === 0) {
      setState({ currentTask: Task.ProblemSolve });
    }
  };

  const identifyOnClickTryAgain = () => {
    setState({
      currentSubstate: Substate.None,
      currentTask: Task.ScanPackage,
    });
  };

  const moveOnClickTryAgain = () => {
    setState({
      currentSubstate: Substate.None,
      currentTask: Task.ScanDestination,
    });
  };

  const onClickPackageRemoved = () => {
    setState({
      currentSubstate: Substate.None,
      currentTask: Task.ScanDestination,
    });
  };

  // Go back to Trickle initial package scan
  const onClickBackToHome = () => {
    resetState(Task.ScanPackage);
  };

  const onClickSidelineContainer = () => {
    resetState(Task.ScanPackage);
  };

  const onClickBringToProblemSolve = () => {
    setState({
      currentSubstate: Substate.None,
      currentTask: Task.ProblemSolve,
    });
  };

  const onClickScanHazmat = () => {
    setState({
      currentSubstate: Substate.None,
      currentTask: Task.Hazmat,
    });
  };

  const onIdentifyFailureExceptionHandler = (errorType: string) => {
    switch (errorType) {
      case "UnexpectedNode": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("unexpected-node-error-header"),
            alertType: "error",
            alertTitle: t("unexpected-node-error-header"),
            message: t("unexpected-node-error-message"),
            primaryActionMessage: t("bring-to-problem-solve"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              onClickBringToProblemSolve();
            },
          },
        });
        break;
      }
      case "InvalidInput": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("invalid-input-error-header"),
            alertType: "error",
            alertTitle: t("invalid-input-error-header"),
            message: t("invalid-input-error-message"),
            primaryActionMessage: t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              identifyOnClickTryAgain();
            },
          },
        });
        break;
      }
      case "DependencyFailure":
      case "UnrecognizedContainer":
      case "InvalidOrMissingConfiguration":
      case "RetryableDependencyFailure":
      default: {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("connection-issue-error-banner"),
            alertType: "error",
            alertTitle: t("move-issue-error-header"),
            message: t("move-issue-error-message"),
            primaryActionMessage: t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              identifyOnClickTryAgain();
            },
          },
        });
      }
    }
  };

  const onMoveFailureExceptionHandler = (
    errorType: string,
    primaryActionMessage?: string,
    providedOnClickAction?: () => void
  ) => {
    switch (errorType) {
      case "ContainerNotCompatible": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("container-not-compatible-error-header"),
            alertType: "error",
            alertTitle: t("container-not-compatible-error-header"),
            message: t("container-not-compatible-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : moveOnClickTryAgain();
            },
          },
        });
        break;
      }
      case "InvalidStackingFilter": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("invalid-stacking-filter-error-header"),
            alertType: "error",
            alertTitle: t("invalid-stacking-filter-error-header"),
            message: t("invalid-stacking-filter-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : moveOnClickTryAgain();
            },
          },
        });
        break;
      }
      case "InvalidDestination": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("invalid-destination-header"),
            alertType: "error",
            alertTitle: t("invalid-destination-header"),
            message: t("invalid-destination-message"),
            primaryActionMessage: primaryActionMessage ?? t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : moveOnClickTryAgain();
            },
          },
        });
        break;
      }
      case "TooManyChildren": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("too-many-children-error-header"),
            alertType: "error",
            alertTitle: t("too-many-children-error-header"),
            message: t("too-many-children-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("package-removed"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickPackageRemoved();
            },
          },
        });
        break;
      }
      case "ContainerNotOpened": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("container-not-opened-error-header"),
            alertType: "error",
            alertTitle: t("container-not-opened-error-header"),
            message: t("container-not-opened-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : moveOnClickTryAgain();
            },
          },
        });
        break;
      }
      case "ParentContainerNotOpened": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("parent-container-not-opened-error-header"),
            alertType: "error",
            alertTitle: t("parent-container-not-opened-error-header"),
            message: t("parent-container-not-opened-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("back-to-home"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickBackToHome();
            },
          },
        });
        break;
      }
      case "InvalidInput": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("invalid-input-error-header"),
            alertType: "error",
            alertTitle: t("invalid-input-error-header"),
            message: t("invalid-input-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : moveOnClickTryAgain();
            },
          },
        });
        break;
      }
      case "InvalidTrailer": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("invalid-trailer-error-header"),
            alertType: "error",
            alertTitle: t("invalid-trailer-error-header"),
            message: t("invalid-trailer-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("back-to-home"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickBackToHome();
            },
          },
        });
        break;
      }
      case "ExclusiveParent": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("exclusive-parent-error-header"),
            alertType: "error",
            alertTitle: t("exclusive-parent-error-header"),
            message: t("exclusive-parent-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("back-to-home"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickBackToHome();
            },
          },
        });
        break;
      }
      case "ContainerWeightLimitExceeded": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("container-weight-limit-exceeded-error-header"),
            alertType: "error",
            alertTitle: t("container-weight-limit-exceeded-error-header"),
            message: t("container-weight-limit-exceeded-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("package-removed"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickPackageRemoved();
            },
          },
        });
        break;
      }
      case "UnexpectedNode": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("unexpected-node-error-header"),
            alertType: "error",
            alertTitle: t("unexpected-node-error-header"),
            message: t("unexpected-node-error-message"),
            primaryActionMessage:
              primaryActionMessage ?? t("bring-to-problem-solve"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickBringToProblemSolve();
            },
          },
        });
        break;
      }
      case "Hazmat": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("hazmat-error-header"),
            alertType: "warning",
            alertTitle: t("hazmat-error-header"),
            message: t("hazmat-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("scan-hazmat-area"),
            soundType: SoundType.WARNING,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickScanHazmat();
            },
          },
        });
        break;
      }
      case "CustomsFlagged": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("customs-flagged-error-header"),
            alertType: "warning",
            alertTitle: t("customs-flagged-error-header"),
            message: t("customs-flagged-error-message"),
            primaryActionMessage:
              primaryActionMessage ?? t("sideline-container"),
            soundType: SoundType.WARNING,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : onClickSidelineContainer();
            },
          },
        });
        break;
      }
      case "MoveToItself": {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("move-to-itself-error-header"),
            alertType: "error",
            alertTitle: t("move-to-itself-error-header"),
            message: t("move-to-itself-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : moveOnClickTryAgain();
            },
          },
        });
        break;
      }
      default: {
        setState({
          currentSubstate: Substate.ScanError,
          errorModalDialogProps: {
            headerTitle: t("connection-issue-error-banner"),
            alertType: "error",
            alertTitle: t("move-issue-error-header"),
            message: t("move-issue-error-message"),
            primaryActionMessage: primaryActionMessage ?? t("try-again"),
            soundType: SoundType.ERROR,
            onClickAction: () => {
              providedOnClickAction
                ? providedOnClickAction()
                : moveOnClickTryAgain();
            },
          },
        });
      }
    }
  };

  return (
    <React.Fragment>
      <ModalDialog
        displayStatus={state.currentSubstate === Substate.ScanError}
        {...state.errorModalDialogProps}
      />

      <ModalDialog
        displayStatus={state.currentSubstate === Substate.ProblemWithPackage}
        headerTitle={t("problem-with-package-banner-title")}
        alertType="error"
        alertTitle={t("problem-with-package-title")}
        message={t("problem-with-package-message")}
        localizedSubmessages={[
          t("container-info-container-type", {
            containerType: state.sourceContainerType,
          }) +
            " " +
            state.sourceDisplayName,
          t("problem-with-package-wet-package-sub-message"),
        ]}
        primaryActionMessage={t("bring-to-problem-solve")}
        secondaryActionMessage={t("cancel")}
        onClickAction={problemWithPackageOnClickAction}
      />

      <Workflow
        title={props.localizedBannerTitle ?? t("trickle-banner-title")}
        currentTaskId={state.currentTask}
        moreOptionsCallback={createMoreOptionsList(props.moreOptionsList)}
        updateCurrentTaskId={(currentTask: Task) =>
          setState({ currentTask: currentTask })
        }
      >
        <WorkflowTask taskId={Task.ScanPackage}>
          <Toaster toasts={state.toasts} onCloseToast={onCloseToast}>
            {(toast) => (
              <Alert toast={true} type="success" onClose={toast.onClose}>
                {t("scan-success-toast", {
                  containerType: state.toastContainerType,
                  scannable: state.toastDisplayName,
                })}
              </Alert>
            )}
          </Toaster>
          <IdentifyByContainer
            header={t("package-scan-title")}
            additionalContainerInfo={{
              withHazmat: true,
            }}
            barcodeImage={BarcodeImage.PACKAGE_BARCODE}
            onSuccess={(result: IdentifyContainerResult) => {
              setState({
                sourceScannable: result.directSourceScannable,
                sourceDisplayName:
                  result.containerInfo.containerLabel ??
                  result.directSourceScannable,
                sourceContainerType: result.containerInfo.containerType,
                sourceStackingFilter:
                  result.containerInfo.stackingFilter ?? undefined,
                sourceScannableCpt: result.containerInfo.cpt
                  ? dateTimeFormat.format(Number(result.containerInfo.cpt))
                  : undefined,
                sourceScannableVsm: result.containerInfo.vsm ?? undefined,
                toasts: [...state.toasts, { id: "scanPackage", timeout: 5000 }],
                toastDisplayName:
                  result.containerInfo.containerLabel ??
                  result.directSourceScannable,
                toastContainerType: result.containerInfo.containerType,
                currentTask: Task.ScanDestination,
              });
            }}
            onIdentifyErrorHandler={(scannable: string, errorType: string) => {
              setState({
                sourceScannable: scannable,
              });
              onIdentifyFailureExceptionHandler(errorType);
            }}
            onFailure={() => resetState(Task.ScanPackage)}
          />
          {state.destinationScannable && (
            <Box spacingInset={"medium"}>
              <Text alignment="left" type="b400">
                <strong>
                  {t("container-info-container-type", {
                    containerType: undefined,
                  })}
                </strong>{" "}
                {state.destinationScannable}
              </Text>
              {state.destinationWeightUtilization !== undefined &&
                state.destinationWeightUtilization >= 0 && (
                  <ProgressMeter
                    label={t("destination-weight-utilization-title")}
                    percentage={state.destinationWeightUtilization}
                  />
                )}
              {state.destinationVolumeUtilization !== undefined &&
                state.destinationVolumeUtilization >= 0 && (
                  <ProgressMeter
                    label={t("destination-volume-utilization-title")}
                    percentage={state.destinationVolumeUtilization}
                  />
                )}
            </Box>
          )}
        </WorkflowTask>

        <WorkflowTask taskId={Task.ScanDestination}>
          <Toaster toasts={state.toasts} onCloseToast={onCloseToast}>
            {(toast) => (
              <Alert toast={true} type="success" onClose={toast.onClose}>
                {t("scan-success-toast", {
                  containerType: state.toastContainerType,
                  scannable: state.toastDisplayName,
                })}
              </Alert>
            )}
          </Toaster>
          <GetDestinationsForContainer
            setAdditionalActions={() => {}}
            scannable={state.sourceScannable}
            onSuccess={(
              candidateDestinations: subMessagesMap[],
              candidateDestinationsScannables: string[]
            ) => {
              props.dynamicContainerizationRepo?.packageScan({
                fcMenuProps: fcMenuProps,
                packageId: state.sourceScannable,
                targetStackingAreas: candidateDestinationsScannables,
              });
              setState({
                candidateDestinationScannables: candidateDestinationsScannables,
              });
            }}
          />
          <ScanContainer
            header={t("scan-destination-by-vsm-title")}
            containerInfoHeader={{
              scannable: state.sourceDisplayName,
              containerType: state.sourceContainerType,
              cpt: state.sourceScannableCpt,
              stackingFilter: state.sourceStackingFilter,
              vsm: state.sourceScannableVsm,
            }}
            subMessages={[
              {
                subTitle: t("destination-list-title"),
                subMessageList: Paginate(
                  state.candidateDestinationScannables,
                  DEFAULT_DESTINATIONS_PAGE_SIZE,
                  state.destinationListPageNumber
                ),
                subVsmTitle: "VSM",
                subVsmLabel: state.sourceScannableVsm,
              },
            ]}
            barcodeImage={BarcodeImage.QR_BARCODE}
            sourceScannable={state.sourceScannable}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string,
              destinationWeightUtilization: number,
              destinationVolumeUtilization: number
            ) => {
              props.dynamicContainerizationRepo?.destinationScan({
                fcMenuProps: fcMenuProps
              });
              setState({
                destinationScannable: destinationScannable,
                destinationListPageNumber: 0,
                destinationWeightUtilization: destinationWeightUtilization,
                destinationVolumeUtilization: destinationVolumeUtilization,
                toasts: [
                  ...state.toasts,
                  { id: "scanDestination", timeout: 5000 },
                ],
                toastDisplayName: destinationScannable,
                toastContainerType: undefined,
                currentTask: Task.ScanPackage,
              });
            }}
            onFailure={(errorType: string, message: string) => {
              onMoveFailureExceptionHandler(errorType);
            }}
            onUndo={() => {
              resetState(Task.ScanPackage);
            }}
          />
        </WorkflowTask>

        <WorkflowTask taskId={Task.ProblemSolve}>
          <ProblemContainerWorkflow
            problemType={ProblemType.ProblemSolve}
            localizedBannerTitle={
              props.localizedBannerTitle ?? t("trickle-banner-title")
            }
            sourceScannable={state.sourceScannable}
            sourceContainerType={state.sourceContainerType}
            moreOptionsList={(): Array<MoreOptionRefProps<ProblemType>> => {
              const trickleMoreOptionsList = createMoreOptionsList(
                props.moreOptionsList
              )();
              const newMoreOptionsList: MoreOptionRefProps<ProblemType>[] =
                trickleMoreOptionsList.map((moreOptionsRef) => {
                  return {
                    displayName: moreOptionsRef.displayName,
                    action: moreOptionsRef.action,
                  };
                });
              return newMoreOptionsList;
            }}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string
            ) => {
              setState({
                destinationScannable: destinationScannable,
                destinationListPageNumber: 0,
                toasts: [
                  ...state.toasts,
                  { id: "scanDestination", timeout: 5000 },
                ],
                toastDisplayName: destinationScannable,
                toastContainerType: undefined,
                currentTask: Task.ScanPackage,
              });
            }}
            onFailure={(errorType: string, message: string) => {
              onMoveFailureExceptionHandler(errorType, t("try-again"), () => {
                setState({
                  currentSubstate: Substate.None,
                  currentTask: Task.ProblemSolve,
                });
              });
            }}
          />
        </WorkflowTask>

        <WorkflowTask taskId={Task.Hazmat}>
          <ProblemContainerWorkflow
            problemType={ProblemType.Hazmat}
            localizedBannerTitle={
              props.localizedBannerTitle ?? t("trickle-banner-title")
            }
            sourceScannable={state.sourceScannable}
            sourceContainerType={state.sourceContainerType}
            moreOptionsList={(): Array<MoreOptionRefProps<ProblemType>> => {
              const trickleMoreOptionsList = createMoreOptionsList(
                props.moreOptionsList
              )();
              const newMoreOptionsList: MoreOptionRefProps<ProblemType>[] =
                trickleMoreOptionsList.map((moreOptionsRef) => {
                  return {
                    displayName: moreOptionsRef.displayName,
                    action: moreOptionsRef.action,
                  };
                });
              return newMoreOptionsList;
            }}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string
            ) => {
              setState({
                destinationScannable: destinationScannable,
                destinationListPageNumber: 0,
                toasts: [
                  ...state.toasts,
                  { id: "scanDestination", timeout: 5000 },
                ],
                toastDisplayName: destinationScannable,
                toastContainerType: undefined,
                currentTask: Task.ScanPackage,
              });
            }}
            onFailure={(errorType: string, message: string) => {
              onMoveFailureExceptionHandler(errorType, t("try-again"), () => {
                setState({
                  currentSubstate: Substate.None,
                  currentTask: Task.Hazmat,
                });
              });
            }}
          />
        </WorkflowTask>
      </Workflow>
    </React.Fragment>
  );
};

export default TrickleWorkflow;
