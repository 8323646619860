export enum ScanType {
    CART,
    SHUTTLE,
    TAG,
    LIGHT,
    RESOURCE_ID,
    INVALID
}

const CART_ID_REGEX = /^CART_/
const SHUTTLE_ID_REGEX = /^GAYLORD_/
const TAG_ID_REGEX = /^\d{8}$/
const LIGHT_ID_REGEX = /^[\dA-Fa-f]{12}$/
const UUID_REGEX = /^[\da-fA-F]{8}\b-[\da-fA-F]{4}\b-[\da-fA-F]{4}\b-[\da-fA-F]{4}\b-[\da-fA-F]{12}$/

export class ScanIdentifierUtil {
    public static readonly containerScanTypes = new Set([ScanType.CART, ScanType.SHUTTLE])

    static getScanType(input: string): ScanType {
        if (CART_ID_REGEX.test(input)) {
            return ScanType.CART
        } else if (SHUTTLE_ID_REGEX.test(input)) {
            return ScanType.SHUTTLE
        } else if (TAG_ID_REGEX.test(input)) {
            return ScanType.TAG
        } else if (LIGHT_ID_REGEX.test(input)) {
            return ScanType.LIGHT
        } else if (UUID_REGEX.test(input)) {
            return ScanType.RESOURCE_ID
        } else {
            return ScanType.INVALID
        }
    }
}