import {
    DeviceType,
    EdgeDeviceMappingClient,
    ResourceType,
    ScannableIdType,
    TestLightCommand,
    TestLightCommandInput,
    TestLightCommandOutput,
    UpdateResourceCommand,
    UpdateResourceCommandInput,
    UpdateResourceCommandOutput,
} from "@amzn/edgedevicemappingservice-client"
import {Configuration} from "../configuration/configuration"
import {CredentialRepo} from "./credentialRepo"
import {ScanType} from "../components/util/ScanIdentifierUtil"

export interface CreateMappingInput {
    containerId: string
    tagId?: string
    lightId?: string
}



export class EdgeMappingRepo {
    private client: EdgeDeviceMappingClient
    constructor(credentialRepo: CredentialRepo, configuration: Configuration) {
        this.client = new EdgeDeviceMappingClient({
            endpoint: configuration.edgeMappingServiceUrl,
            region: configuration.region,
            credentials: credentialRepo.getCredentials,
            serviceId: "execute-api"
        })
    }
    async createMapping(input: CreateMappingInput): Promise<UpdateResourceCommandOutput> {
        const devices = []
        if (input.tagId) {
            devices.push({
                id: input.tagId,
                type: DeviceType.UWB_TAG,
                version: undefined,
                address: undefined
            })
        }
        if (input.lightId) {
            devices.push({
                id: input.lightId,
                type: DeviceType.WIRELESS_LIGHT,
                version: "MAXILUX_WIRELESS_V1", //TODO: replace this enum once it is published by EDMS
                address: input.lightId, //same as ID
            })
        }
        const command = new UpdateResourceCommand({
            resource: {
                id: input.containerId,
                type: ResourceType.CART,
            },
            devices,
        } as UpdateResourceCommandInput)
        const result = await this.client.send(command)
        console.log("createMapping", result)
        return result
    }

    async testLightFromAnyDevice(input: TestLightCommandInput): Promise<TestLightCommandOutput> {
        const command = new TestLightCommand(input)
        const result = await this.client.send(command)
        console.log("testLightFromAnyDevice", result)
        return result
    }


    scanTypeToScannableIdType(scanType: ScanType): ScannableIdType {
        switch (scanType) {
            case ScanType.CART:
                return ScannableIdType.CART
            case ScanType.SHUTTLE:
                return ScannableIdType.CART
            case ScanType.TAG:
                return ScannableIdType.UWB_TAG
            case ScanType.LIGHT:
                return ScannableIdType.WIRELESS_LIGHT
            case ScanType.RESOURCE_ID:
                return ScannableIdType.WIRED_LIGHT
            case ScanType.INVALID:
                throw new Error("Invalid is not a true Device Type")

        }
    }
}

