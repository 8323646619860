/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import { useQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  IdentifyContainerDocument,
  IdentifyContainerOutput,
} from "../../../../graphql.generated";
import { AppContext } from "../../../App";
import FreezeScreen from "../../../common/loading/FreezeScreen";
import ModalDialog from "../../../common/modal/ModalDialog";
import { BarcodeImage, ScanReader } from "../../../common/scans/ScanReader";
import { SoundType } from "../../../common/soundProvider/SoundProvider";

const MIN_RETRIES_BEFORE_ENABLE_REPORT_PROBLEM = 2;
const TRANSLATION_NS =
  "components.functions.identifyContainer.identifyByContainer.IdentifyByContainer";

interface IdentifyContainerProps {
  scannable: string;
  callbackOnly: boolean;
  enableReportProblem?: boolean;
  withAdditionalContainerInfo?: {
    withHazmat?: boolean;
    withAggregateProperty?: boolean;
    withHierarchyInfo?: boolean;
  };
  onSuccess: (containerInfo: IdentifyContainerOutput) => void;
  onFailure: (optionNumber: number) => void;
  /** If onIdentifyErrorHandler is provided, pass error back to parent component to handle. */
  onIdentifyErrorHandler: ((errorType: string) => void) | null;
}

export interface IdentifyContainerResult {
  __typename?: "IdentifyContainerResult";
  /** The scannable provided by the parent component or the string that was scanned by the associate */
  directSourceScannable: string;
  /** The output from IdentifyContainer API */
  containerInfo: IdentifyContainerOutput;
}

const IdentifyContainer = (props: IdentifyContainerProps) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const { trainingModeStatus, fetchPolicy } = useContext(AppContext);

  const inputVariable = useMemo(() => {
    return {
      input: {
        scannable: props.scannable,
      },
      withHazmat: props.withAdditionalContainerInfo?.withHazmat ?? false,
      withHierarchyInfo:
        props.withAdditionalContainerInfo?.withHierarchyInfo ?? false,
      withAggregateProperty:
        props.withAdditionalContainerInfo?.withAggregateProperty ?? false,
    };
  }, [props.scannable, props.withAdditionalContainerInfo]);

  const { loading, error, data, refetch } = useQuery(
    IdentifyContainerDocument,
    {
      fetchPolicy: { trainingModeStatus } ? fetchPolicy : "no-cache",
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      variables: inputVariable,
    }
  );

  useEffect(() => {
    refetch(inputVariable);
  }, [refetch, inputVariable]);

  if (loading) {
    return <FreezeScreen message={t("getting-container-information")} />;
  }

  if (error) {
    const errorType = error?.graphQLErrors[0]?.extensions?.["errorType"];
    if (props.onIdentifyErrorHandler) {
      props.onIdentifyErrorHandler(errorType);
    } else {
      if (props.callbackOnly) {
        props.onFailure(1);
      }
      return (
        <ModalDialog
          headerTitle={t("connection-issue")}
          displayStatus={!!error}
          alertType={"error"}
          alertTitle={t("barcode-error-title")}
          message={t("barcode-error")}
          primaryActionMessage={t("try-again")}
          secondaryActionMessage={
            props.enableReportProblem ? t("report-to-manager") : ""
          }
          soundType={SoundType.ERROR}
          onClickAction={props.onFailure}
        />
      );
    }
  } else {
    if (data?.identifyContainer) {
      props.onSuccess(data?.identifyContainer);
    }
  }

  return null;
};

export interface IdentifyByContainerProps {
  header?: string;
  message?: string;
  callbackOnly?: boolean;
  scannable?: string;
  additionalContainerInfo?: {
    withHazmat?: boolean;
    withAggregateProperty?: boolean;
    withHierarchyInfo?: boolean;
  };
  barcodeImage?: BarcodeImage;
  verify?: {
    label: string;
    verificationAlertHeader: string;
    verificationAlertTitle: string;
    verificationAlertMessage: string;
  };
  onSuccess: (containerInfo: IdentifyContainerResult) => void;
  onFailure: () => void;
  setIsModalDialogOpen?: (isOpen: boolean) => void;
  onIdentifyErrorHandler?: (scannable: string, errorType: string) => void;
}

export function IdentifyByContainer(props: IdentifyByContainerProps) {
  const { t } = useTranslation(TRANSLATION_NS);
  const [scannable, setScannable] = useState<string>(props.scannable ?? "");
  const [retries, setRetries] = useState<number>(0);
  const [verificationFail, setVerificationFail] = useState<boolean>(false);
  const [enableReportProblem, setEnableReportProblem] =
    useState<boolean>(false);

  function verifyIdentifiedContainer(containerInfo: IdentifyContainerOutput) {
    let validScan = true;
    if (props.verify && props.verify.label !== containerInfo.containerLabel) {
      validScan = false;
    }

    if (validScan) {
      props.onSuccess({
        directSourceScannable: scannable,
        containerInfo: containerInfo,
      });
    } else {
      props.callbackOnly ? props.onFailure() : setVerificationFail(true);
    }
  }

  function handleIdentifyContainerFailure(optionNumber: number) {
    if (optionNumber === 0) {
      setScannable("");
      setRetries(retries + 1);
      if (retries >= MIN_RETRIES_BEFORE_ENABLE_REPORT_PROBLEM) {
        setEnableReportProblem(true);
      }
    } else if (optionNumber === 1) {
      props.onFailure();
    }
  }

  if (props.setIsModalDialogOpen) {
    props.setIsModalDialogOpen(verificationFail);
  }

  return (
    <div>
      <ModalDialog
        displayStatus={verificationFail}
        headerTitle={"" + props.verify?.verificationAlertHeader}
        alertType={"error"}
        alertTitle={"" + props.verify?.verificationAlertTitle}
        message={"" + props.verify?.verificationAlertMessage}
        primaryActionMessage={t("scan-barcode")}
        soundType={SoundType.ERROR}
        onClickAction={() => {
          setVerificationFail(false);
          setScannable("");
        }}
      />
      {scannable && (
        <IdentifyContainer
          scannable={scannable}
          callbackOnly={props.callbackOnly ?? false}
          withAdditionalContainerInfo={props.additionalContainerInfo}
          onSuccess={verifyIdentifiedContainer}
          onFailure={handleIdentifyContainerFailure}
          onIdentifyErrorHandler={
            props.onIdentifyErrorHandler
              ? (errorType: string) => {
                  if (props.onIdentifyErrorHandler) {
                    props.onIdentifyErrorHandler(scannable, errorType);
                  }
                  setScannable("");
                }
              : null
          }
          enableReportProblem={enableReportProblem}
        />
      )}
      {!props.callbackOnly && (
        <ScanReader
          header={props.header}
          message={props.message}
          barcodeImage={props.barcodeImage}
          disableRead={!!scannable}
          callback={(scannable: string) => {
            setScannable(scannable);
          }}
        />
      )}
    </div>
  );
}

export default IdentifyByContainer;
