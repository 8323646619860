/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React, { useContext } from "react";
import { AppHeaderContext } from "../layout/PageLayout";
import Heading from "@amzn/meridian/heading";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import { OnClickActionProps } from "../common/list/ListActions";
import styles from "./Workflow.module.css";

export interface TaskProps<T> {
  /**
   * Represent the unique taskId
   * If multiple child Task component defined with the same taskId, only first one will be referred
   */
  taskId: T;

  /**
   * Boolean to display the menu button for a task, by default it will hide the menu button
   */
  showMenuButton?: boolean;

  /**
   * Represent the child component(s)
   */
  children: React.ReactNode;
}

/**
 * Only Task component should be immediate child components for any Workflow
 */
export class Task<T> extends React.Component<
  TaskProps<T>,
  React.ReactElement
> {}

/**
 * Interface to set the properties in more options from workflow task
 * Most of the cases, it will be another task.
 * It can also trigger another flow and action method can be used to perform the action.
 */
export interface MoreOptionRefProps<T> {
  /**
   * Display name
   */
  displayName: string;

  /**
   * action method (optional)
   */
  action?: () => void;

  /**
   * Represent the unique taskId, if it represent a task
   */
  taskId?: T;
}

export interface WorkflowProps<T> {
  /**
   * The display title on the header.
   */
  title: string;

  /**
   * Represent the current task id to load
   * If non-exist task id is provided, the workflow will render error message.
   */
  currentTaskId: T;

  /**
   * Reference to update the currentTaskId used to set in the moreOptionsCallback
   */
  updateCurrentTaskId: (taskId: T) => void;

  /**
   * Callback method to be called to set the more options for the current task.
   */
  moreOptionsCallback: (currentTask: T) => Array<MoreOptionRefProps<T>>;

  /**
   * The list of tasks the current workflow support, which should be defined under <Workflow> tag
   * Any child component other than <Task> will be ignored.
   */
  children: React.ReactElement<TaskProps<T>>[];
}

/**
 * A constant array that will be used to update the moreOptionsList in the header
 */
const CURRENT_MORE_ACTIONS_LIST: Array<OnClickActionProps> = [];

const NO_TASK_DEFINED = "NoTaskDefined";

/**
 * Only Task component should be immediate child components for any Workflow.
 * If any other component (other than Task) is provided, it will be ignored.
 * All the task should have unique taskId
 */
function Workflow<T>(props: WorkflowProps<T>) {
  /**
   * A utility function to convert the task reference into a moreOption handler
   */
  function getOptionButtonFor(
    moreOptionRefProps: MoreOptionRefProps<T>
  ): OnClickActionProps {
    return {
      displayText: moreOptionRefProps.displayName,
      onClick: () => {
        moreOptionRefProps.action && moreOptionRefProps.action();
        moreOptionRefProps.taskId &&
          props.updateCurrentTaskId(moreOptionRefProps.taskId);
      },
    };
  }

  /**
   * Function to update the moreOptionsList in the header
   */
  function updateMoreOptionsList(
    moreOptionsTasksList: Array<MoreOptionRefProps<T>>
  ) {
    CURRENT_MORE_ACTIONS_LIST.splice(0, CURRENT_MORE_ACTIONS_LIST.length);

    for (var moreOption of moreOptionsTasksList) {
      CURRENT_MORE_ACTIONS_LIST.push(getOptionButtonFor(moreOption));
    }
    setMoreOptionsList(CURRENT_MORE_ACTIONS_LIST);
  }

  const {
    setShowMenuButton,
    setTitle,
    setShowMoreOptionsButton,
    setMoreOptionsList,
  } = useContext(AppHeaderContext);

  const currentTask = GetCurrentTask(props);
  setTitle(props.title);

  if (currentTask.props.showMenuButton) {
    setShowMenuButton(true);
    setShowMoreOptionsButton(false);
  } else {
    setShowMenuButton(false);
    setShowMoreOptionsButton(true);
  }

  updateMoreOptionsList(props.moreOptionsCallback(props.currentTaskId));
  return <div className={styles["task"]}>{currentTask.props.children}</div>;
}

function GetCurrentTask<T>(props: WorkflowProps<T>) {
  if (props && props.children) {
    for (let i = 0; i < props.children.length; i++) {
      let aTask = props.children[i];
      if (aTask.props.taskId === props.currentTaskId) {
        return aTask;
      }
    }
  }

  return (
    <Task taskId={NO_TASK_DEFINED} showMenuButton={true}>
      <Alert type={"error"} size="xlarge">
        <Heading level={1} type={"d50"}>
          {"Task not defined!"}
        </Heading>
      </Alert>
      <Box className="title" spacingInset="medium">
        <Text alignment="left" type="b500">
          {props.currentTaskId + " not defined."}
        </Text>
      </Box>
    </Task>
  );
}

export default Workflow;
