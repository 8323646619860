/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React from "react";
import Sheet from "@amzn/meridian/sheet";
import DisplayLoader from "./DisplayLoader";

export interface FreezeScreenProps {
  message: string;
}

function FreezeScreen(props: FreezeScreenProps) {
  return (
    <Sheet type="overlay" side="bottom" spacingInset={"none"} open={true}>
      <DisplayLoader message={props.message} />
    </Sheet>
  );
}

export default FreezeScreen;
