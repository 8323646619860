import { CustomDetector } from "i18next-browser-languagedetector"
import {FCMenuDecoder} from "../environment/fcMenu";

/**
 * A language detector that works by reading the user's language from
 * values provided by FCMenu.
 */
export const FcMenuLanguageDetector: CustomDetector = {
    name: "fcMenuCookie",
    lookup: () => {
        return FCMenuDecoder.decodeFcMenuCookie()?.locale?.replace("_", "-")
    },
}
