import {Route, Switch} from "react-router"
import {ExperimentSelector} from "../experiment-selector"
import {TestDeviceMapping} from "../edgeDeviceMapping/TestDeviceMapping";
import {useContext} from "react";
import {EdgeDeviceMapping} from "../edgeDeviceMapping/EdgeDeviceMapping";
import {HelpPage} from "../edgeDeviceMapping/HelpPage";
import ContainerBuilderWorkflow from "../workflows/ContainerBuilderWorkflow";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {ApolloProvider} from "@apollo/client";
import { MockedProvider } from "@apollo/client/testing";
import {TrainingProviderMock} from "../dataProvider/TrainingProvider.mock";
import {AppContext} from "../App";
import {CheckInScan} from "../stackByLight/CheckInScan";
import {CptCartStorage} from "../edgeDeviceMapping/CptCartStorage";
import {CptCartChase} from "../edgeDeviceMapping/CptCartChase";
import { CartToRABMapping } from "../cartToRABMappingApp/CartToRABMapping"
import {SiteAudit} from "../audit/SiteAudit";
import {TrailerAudit} from "../audit/TrailerAudit";
import {RedTagCart} from "../redTagCartApp/RedTagCart";

export const URL_HOME = "/"
const httpLink = createHttpLink({
    uri: "/graphql",
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

function AppMainContent() {
    // TODO: Figure out a better way to pass history to the child components that use class
    const {trainingModeStatus, edgeMappingRepo, lcsClientRepo, dynamicContainerizationRepo, cptChasingRepo, goCartServiceRepo , heiseServiceRepo} = useContext(AppContext);
    console.log(`Training mode status: ${trainingModeStatus}`);

    console.log("Rendering AppMainContent");
    return (
        <Switch>
            <Route path="/edgeDeviceMapping">
                <EdgeDeviceMapping edgeMappingRepo={edgeMappingRepo!} lightControlClientRepo={lcsClientRepo!}/>
            </Route>
            <Route path="/testMapping">
                <TestDeviceMapping edgeDeviceMappingRepo={edgeMappingRepo!} lightControlClientRepo={lcsClientRepo!}/>
            </Route>
            <Route path='/checkIn'>
                <CheckInScan dynamicContainerizationRepo={dynamicContainerizationRepo!}/>
            </Route>
            <Route path='/audit/site'>
                <SiteAudit heiseServiceRepo={heiseServiceRepo!} />
            </Route>
            <Route path='/audit/trailer'>
                <TrailerAudit heiseServiceRepo={heiseServiceRepo!} />
            </Route>
            <Route path='/cartToRABMapping'>
                <CartToRABMapping goCartServiceRepo={goCartServiceRepo!} />
            </Route>
            <Route path='/redTagCart'>
                <RedTagCart heiseServiceRepo={heiseServiceRepo!} />
            </Route>
            <Route path={"/containerBuilder"}>
                {trainingModeStatus &&
                    <MockedProvider mocks={TrainingProviderMock} addTypename={false}>
                        <ContainerBuilderWorkflow />
                    </MockedProvider>
                }
                {!trainingModeStatus &&
                    <ApolloProvider client={client}>
                        <ContainerBuilderWorkflow />
                    </ApolloProvider>
                }
            </Route>
            <Route path="/help">
                <HelpPage/>
            </Route>
            <Route path="/cptCartStorage">
                <CptCartStorage cptChasingRepo={cptChasingRepo!}/>
            </Route>
            <Route path="/cptChase">
                <CptCartChase cptChasingRepo={cptChasingRepo!}/>
            </Route>
            <Route path="/">
                <ExperimentSelector/>
            </Route>
        </Switch>
    )
}

export default AppMainContent
