/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import { useEffect, useState } from "react";

export enum SoundType {
  /** An error has been encountered and needs the associate's attention. */
  ERROR,
  /** A warning that needs the associate's attention. */
  WARNING,
  /** Pending message to the user, currently from FANS. */
  MESSAGE,
}
export const SoundTypeUrlMap = new Map<SoundType, string>([
  [SoundType.ERROR, require("../../../media/audio/scanner-beep.ogg")],
  [SoundType.WARNING, require("../../../media/audio/scanner-beep-triple.ogg")],
  [SoundType.MESSAGE, require("../../../media/audio/scanner-beep-charge.ogg")],
]);

export interface soundProviderProps {
  soundType: SoundType;
  isVibration?: boolean;
}

function SoundProvider(props: soundProviderProps) {
  const [audio] = useState(new Audio(SoundTypeUrlMap.get(props.soundType)));
  const [isPlaySound, setIsPlaySound] = useState(true);

  useEffect(() => {
    isPlaySound ? audio.play() : audio.pause();
    if (props.isVibration) {
      navigator.vibrate(200);
    }
  }, [isPlaySound, props.isVibration, audio]);

  useEffect(() => {
    audio.addEventListener("ended", () => setIsPlaySound(false));
    return () => {
      audio.removeEventListener("ended", () => setIsPlaySound(false));
    };
  }, [audio]);

  return null;
}

export default SoundProvider;
