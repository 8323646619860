import {CredentialRepo} from "./credentialRepo"
import {Configuration} from "../configuration/configuration"
import {
    AddCPTChaserCartCommand, DeleteCPTCartLightsCommand,
    LightControlClient,
    TriggerCPTCartLight2Command,
    TriggerCPTCartLightCommand
} from "@amzn/lightcontrolservice-client"

export class CptChasingRepo {
    private client: LightControlClient

    constructor(credentialRepo: CredentialRepo, configuration: Configuration) {
        this.client = new LightControlClient({
            endpoint: configuration.lightControlServiceUrl,
            region: configuration.region,
            credentials: credentialRepo.getCredentials,
            serviceId: "execute-api"
        })
    }

    async addCartToCpt(cartId: string) {
        await this.client.send(new AddCPTChaserCartCommand({
            cartId: cartId
        }))
    }

    async triggerCptCarts(): Promise<string[]> {
        try {
            const result = await this.client.send(new TriggerCPTCartLightCommand({
                jay: "hello world"
            }))
            return result.cartIds!
        } catch (e) {
            console.log("First try failed! Booooooo,trying GET next")
            const result = await this.client.send(new TriggerCPTCartLight2Command({}))
            return result.cartIds!
        }
    }

    async clearCptCarts() {
        await this.client.send(new DeleteCPTCartLightsCommand({}))
    }
}