/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import {
  GetUserRolesDocument,
  RoleLevel,
  RoleName,
} from "../../../graphql.generated";

export const GetRolesMock = [
  {
    request: {
      query: GetUserRolesDocument,
    },
    result: {
      data: {
        roles: [
          {
            roleName: RoleName.DirectedWaterspider,
            roleLevel: RoleLevel.Level_2,
          },
          {
            roleName: RoleName.DirectedStager,
            roleLevel: RoleLevel.Level_2,
          },
          {
            roleName: RoleName.DirectedLoader,
            roleLevel: RoleLevel.Level_2,
          },
          {
            roleName: RoleName.DirectedCptChaser,
            roleLevel: RoleLevel.Level_2,
          },
          {
            roleName: RoleName.ContainerBuilder,
            roleLevel: RoleLevel.Level_2,
          },
          {
            roleName: RoleName.ContainerUnloader,
            roleLevel: RoleLevel.Level_2,
          },
        ],
      },
    },
  },
];

export const GetRolesMockError = [
  {
    request: {
      query: GetUserRolesDocument,
    },
    error: new Error("Backend Error"),
  },
];

export const GetRolesMockEmpty = [
  {
    request: {
      query: GetUserRolesDocument,
    },
    result: {
      data: {},
    },
  },
];
