import React from "react"
import Loader from "@amzn/meridian/loader"
import Row from "@amzn/meridian/row"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"

export interface DisplayLoaderProps {
    message: string
}

function DisplayLoader(props: DisplayLoaderProps) {
    return (
        <Row>
            <Box>
                <Loader type="circular" size="medium" />
            </Box>
            <Text alignment="left" type="b500">
                {props.message}
            </Text>
        </Row>
    )
}

export default DisplayLoader
