/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React from "react";
import VSM from "../vsm/VSM";

export interface PlainMessageProps {
  title?: string;
  message?: string;
  subMessages?: subMessagesMap[];
}

export type subMessagesMap = {
  subTitle: string;
  subMessageList: string[];
  subVsmTitle?: string;
  subVsmLabel?: string;
};

function PlainMessage(props: PlainMessageProps) {
  const subMessages = props.subMessages?.map((subPlainMessage, index) => (
    <Row widths={["50%", "50%"]}>
      {subPlainMessage.subVsmTitle && subPlainMessage.subVsmLabel && (
        <VSM
          localizedVSMTitle={subPlainMessage.subVsmTitle}
          localizedVSMLabel={subPlainMessage.subVsmLabel}
        />
      )}
      <Box className="title" spacingInset="medium" key={index}>
        <Text alignment="left" type="b400" key={subPlainMessage.subTitle}>
          <strong>{subPlainMessage.subTitle}</strong>
        </Text>
        <Text
          alignment="left"
          type="b400"
          key={subPlainMessage.subTitle + index}
        >
          {subPlainMessage.subMessageList.map((subMessage) => (
            <Text alignment="left" type="b400" key={subMessage}>
              {subMessage}
            </Text>
          ))}
        </Text>
      </Box>
    </Row>
  ));

  return (
    <div>
      {props.title && (
        <Box className="title" spacingInset="medium">
          <Heading type={"h400"} level={1}>
            {props.title}
          </Heading>
        </Box>
      )}
      {props.message && (
        <Box className="message" spacingInset="medium">
          <Text alignment="left" type="b500">
            {props.message}
          </Text>
        </Box>
      )}
      {props.subMessages && subMessages}
    </div>
  );
}

export default PlainMessage;
