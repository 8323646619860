import Box from "@amzn/meridian/box";
import Input from "@amzn/meridian/input";
import {useState} from "react"
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import {CartInput} from "../common/CartInput";
import Text from "@amzn/meridian/text"
import {FCMenuDecoder} from "../../environment/fcMenu";
import {errrorBeep, successBeep} from "../util/beep";
import Loader from "@amzn/meridian/loader"
import Alert from "@amzn/meridian/alert";
import React from "react";
import {HeiseServiceRepo} from "../../repo/heiseServiceRepo";
import Toaster from "@amzn/meridian/toaster";
import {CartState} from "../util/Constants";
import Row from "@amzn/meridian/row";

enum SiteAuidtMode {
    INITIALIZATION,
    SCANNING,
}

export interface RedTagCartProps {
    heiseServiceRepo: HeiseServiceRepo,
}

let toastIdCount = 0

export function RedTagCart(props: RedTagCartProps) {
    const userId = FCMenuDecoder.decodeFcMenuCookie()?.employeeLogin ?? 'unknown'
    const [siteId, setSiteId] = useState<string>(FCMenuDecoder.decodeFcMenuCookie()?.warehouseId ?? "")
    const [mode, setMode] = useState<SiteAuidtMode>(SiteAuidtMode.INITIALIZATION)
    const [loading, setLoading] = useState(false)
    const [cartId, setCartId] = useState<string>("")

    const [toasts, setToasts] = useState<{id: string, timeout: number, message: string}[]>([])
    const onCloseToast = (id: string) => setToasts(toasts.filter(t => t.id !== id))
    const onOpenToast = (message: string) => setToasts(toasts.concat({ id: `${++toastIdCount}`, timeout: 3000, message: message }))
    const [resetCartId, setResetCartId] = useState<boolean>(false)
    const [recentCartsScanned, setRecentCartsScanned] = useState<string[]>([])

    const initialSetup = () => (
        <Column>
            <Input
                id="siteId"
                value={siteId}
                onChange={(value) => setSiteId(value.toUpperCase())}
                type="text"
                placeholder="Enter Your Site ID (i.e. MSP1)"
            />
            <Button size={"large"} onClick={() => setMode(SiteAuidtMode.SCANNING)} disabled={siteId.length !== 4}>Start</Button>
        </Column>
    )

    const onSubmit = async () => {
        setLoading(true)
        if(recentCartsScanned.includes(cartId)){
            onOpenToast("This cart has already been scanned recently")
            setLoading(false)
            return
        }
        try {
            await props.heiseServiceRepo.redTagScan({
                timestamp: new Date(),
                nodeId: siteId,
                cartId: cartId,
                operationalState: CartState[CartState.BROKEN],
                userId: userId
            })
            setRecentCartsScanned(prevState => [cartId, ...prevState]);
            resetFields()
            successBeep()
            setLoading(false)
        }
        catch (error: any){
            errrorBeep()
            if (error instanceof Error) {
                onOpenToast(error.message)
            }
            else {
                onOpenToast(error.toString())
            }
            setLoading(false)
        }
    }

    const resetFields = () => {
        setResetCartId(prevState => !prevState)
        setCartId("")
    }

    const scanningMode = () => (
        <Column>
            <CartInput
                onValidCartId={setCartId}
                clearAfterSuccessfulScan={false}
                resetCartId={resetCartId}
            />
            <Button onClick={onSubmit} disabled={loading}>{loading ? <Loader size={"medium"} /> : "Submit"}</Button>
        </Column>
    )

    return (
        <Box spacingInset="100 300">
            <Toaster toasts={toasts} onCloseToast={onCloseToast}>
                {toast => <Alert type={"error"} toast={true}>{toast.message}</Alert>}
            </Toaster>
            <Heading level={2} alignment={"center"}>Red Tag Cart</Heading>
            {mode === SiteAuidtMode.INITIALIZATION && initialSetup()}
            {mode === SiteAuidtMode.SCANNING && scanningMode()}
            {mode !== SiteAuidtMode.INITIALIZATION &&
                <Box type={"fill"} spacingInset={"400"}>
                    <Row>
                        <Text>Recent:</Text>
                        <Text type="h600">{recentCartsScanned.length}</Text>
                    </Row>
                    {recentCartsScanned.slice(0,9).map((value, index) => (
                        <Text key={index}>{value}</Text>
                    ))}
                </Box>}
        </Box>
    )
}