import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import checkImage from "../../media/images/checkmark.svg";
import alert from "../../media/images/alert.svg";
import Button from "@amzn/meridian/button";
import React, {useEffect, useState} from "react";
import Expander from "@amzn/meridian/expander";
import { successBeep } from "../util/beep";

interface ApiCallStatusProps {
    success: boolean,
    message: React.ReactNode,
    debugLog: string[]
    startOverMessage: string,
    startOverCallback: () => void
    autoAdvanceOnSuccess: boolean
}

export function ApiCallStatus(props: ApiCallStatusProps) {
    const [debugOpen, setDebugOpen] = useState<boolean>(false)

    useEffect(() => {
        if (props.success && props.autoAdvanceOnSuccess) {
            successBeep()
            setTimeout(() => {
                props.startOverCallback();
            }, 1000)
        }
    }, [props])

    return (
        <>
        <Column minWidth="100%" height="100%" alignmentHorizontal={"center"}
                spacingInset={"small"}>
            <img
                src={props.success ? checkImage : alert}
                style={{display: "block", margin: "auto", width: "30%"}}
                alt={props.success ? "success" : "fail"}
            />
            {props.message}

            <br/>

            <Button size="large" onClick={props.startOverCallback}>
                {props.startOverMessage}
            </Button>
        </Column>
        {props.debugLog.length > 0 &&
            <Row>
                <Expander open={debugOpen} title="Debug Log" onChange={setDebugOpen}>
                    <pre>{props.debugLog}</pre>
                </Expander>
            </Row>
        }
        </>
    )
}


