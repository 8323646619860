/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React from "react";
import TaskSelect, { TaskSelectProps } from "./TaskSelect";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";

export interface TaskSelectGroupProps {
  taskList: Array<TaskSelectProps>;
}

function TaskSelectGroup(props: TaskSelectGroupProps) {
  return (
    <Row alignmentVertical="stretch" wrap="down" widths="50%" maxWidth={400}>
      {props.taskList.map((task) => (
        /** In order to make the task select component same size, need to assign height a fixed value **/
        <Box key={task.localizedTaskTitle} height={200}>
          <TaskSelect
            localizedTaskTitle={task.localizedTaskTitle}
            localizedTaskDescription={task.localizedTaskDescription}
            onClickAction={task.onClickAction}
          />
        </Box>
      ))}
    </Row>
  );
}

export default TaskSelectGroup;
