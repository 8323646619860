import {Configuration} from "../configuration/configuration";
import {CredentialRepo} from "./credentialRepo";
import {
    CheckInScanCommand,
    DestinationScanCommand,
    DynamicContainerizationClient,
    PackageScanCommand,
} from "@amzn/dynamic-containerization-service-client";
import {FCMenuProperties} from "../environment/fcMenu";

export interface PackageScan {
    fcMenuProps?: FCMenuProperties;
    targetStackingAreas?: string[];
    targetStackingFilter?: string;
    packageId?: string;
}

export interface DestinationScan {
    fcMenuProps?: FCMenuProperties;
    destinationId?: string;
}

export interface CheckInScan {
    fcMenuProps?: FCMenuProperties;
    stackingArea?: string;
}

export class DynamicContainerizationRepo {
    private client: DynamicContainerizationClient;
    constructor(credentialRepo: CredentialRepo, configuration: Configuration) {
        this.client = new DynamicContainerizationClient({
            endpoint: configuration.dynamicContainerizationServiceUrl,
            region: configuration.region,
            credentials: credentialRepo.getCredentials,
            serviceId: "execute-api",
            maxAttempts: 1
        });
    }
    packageScan(input: PackageScan) {
        if(input.fcMenuProps) {
            this.catchAndLogOnError(() => this.client.send(new PackageScanCommand({
                nodeId: input.fcMenuProps!.warehouseId,
                targetStackingAreas: input.targetStackingAreas,
                targetStackingFilter: input.targetStackingFilter,
                userId: input.fcMenuProps!.employeeLogin,
            })).catch((e) => {
                console.log("DCS Package Scan Error", e);
            }));
        }
    }
    destinationScan(input: DestinationScan) {
        if (input.fcMenuProps) {
            this.catchAndLogOnError(() => this.client.send(new DestinationScanCommand({
                nodeId: input.fcMenuProps!.warehouseId,
                userId: input.fcMenuProps!.employeeLogin
            })).catch((e) => {
                console.log("DCS Destination Scan Error", e);
            }));
        }
    }
    async checkinScan(input: CheckInScan) {
        const result = await this.client.send(new CheckInScanCommand({
            nodeId: input.fcMenuProps!.warehouseId,
            userId: input.fcMenuProps!.employeeLogin,
            stackingArea: input.stackingArea
        }));
        console.log("DCS Checkin Scan Result", result);
        return result;
    }
    private catchAndLogOnError(callback: () => void) {
        try {
            callback();
        } catch (e) {
            // be overcautious as we don't want to disturb the package move
            console.log(e);
        }
    }
}

