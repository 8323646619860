import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {useState} from "react";
import Row from "@amzn/meridian/row";
import {SimpleScanReader} from "../common/scans/SimpleScanReader";
import {ScanIdentifierUtil, ScanType} from "../util/ScanIdentifierUtil";
import Heading from "@amzn/meridian/heading";


export interface ScanTesterProps {
    onSuccessCallback: (id: string, scanType: ScanType) => void
    goBackCallback: () => void
}

export function ScanTester(props: ScanTesterProps) {
    const [invalidScan, setInvalidScan] = useState<boolean>(false);

    const onScan = (scan: string) => {
        const scanType = ScanIdentifierUtil.getScanType(scan)
        if (scanType === ScanType.INVALID) {
            setInvalidScan(true)
            return;
        }
        props.onSuccessCallback(scan, scanType)
    }
    return (
        <Box spacingInset={"medium"}>
            <Column alignmentHorizontal={"center"}>
                <Heading level={2}>Testing Tool</Heading>
                <SimpleScanReader
                    message={"Scan the Cart, UWB, or Light"}
                    callback={onScan}
                />
                <Row spacingInset={"small"} alignmentHorizontal={"center"}>
                    {invalidScan && invalidScanBox()}
                </Row>
            </Column>
        </Box>
    )
}

function invalidScanBox() {
    return (
        <Box>
            <Text type={"h300"} color={"error"}>
                Invalid barcode
            </Text>
            <Text type={"b100"} color={"error"}>
                <Text>Example Cart Id: CART_12345678_A</Text>
                <Text>Example UWB Id: 10001635</Text>
                <Text>Example Light Id: 686725ABDD6E</Text>
            </Text>
        </Box>
    )
}
