import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Masthead, {
    MastheadMenuButton,
    MastheadTitle,
} from "@amzn/meridian/masthead"
import "./layout.css"
import { AppContext } from "../App"
import Theme from "@amzn/meridian/theme"
import { useHistory } from "react-router-dom";

export interface AppHeaderProps {
    /**
     * Represent the current display state of the hamburger menu button on left of the header.
     */
    showMenuButton: boolean

    /**
     * This method will be called when hamburger menu button on left is clicked
     */
    openMenu: () => void

    /**
     * Display the title on the header
     */
    title: string
}

function AppHeader(props: AppHeaderProps) {
    const { t } = useTranslation("components.layout.AppHeader")
    const { trainingModeStatus } = useContext(AppContext)
    const headerBackgroundColor = trainingModeStatus ? "#FFAE5D" : "#FFFFFF"
    const history = useHistory();

    function handleClick() {
        history.push("/home");
    }

    console.log(`Training mode status: ${trainingModeStatus}`);
    return (
        <Theme tokens={{ mastheadBackgroundColor: headerBackgroundColor }}>
            <Masthead size={"large"}>
                <MastheadMenuButton
                    onClick={props.openMenu}
                    open={props.showMenuButton}
                />
                <MastheadTitle>
                    <div onClick={handleClick}>{props.title}</div>
                    <div>
                        {trainingModeStatus && t("training-mode-subtitle")}
                    </div>
                </MastheadTitle>
            </Masthead>
        </Theme>
    )
}

export default AppHeader
