import Input from "@amzn/meridian/input"
import React, {useEffect, useState} from "react"
import {errrorBeep} from "../util/beep"

export interface CartInputProps {
    onValidCartId: (cartId: string) => void
    clearAfterSuccessfulScan: boolean
    resetCartId?: boolean
}

/**
 * Starting with the ScanReader code below, I created this version which uses Meridian
 *
 * https://code.amazon.com/packages/ProcessTechLabsWebsite/blobs/a70df20bd6d47cad24336ec91a98500c96afa793/--/src/components/common/scans/ScanReader.tsx#L48
 */
export function CartInput(props: CartInputProps) {
    const [cartId, setCartId] = useState("")
    const cartRegex = /CART_/
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    let inputTimeoutHandler: NodeJS.Timeout | undefined

    useEffect(() => {
        setCartId("")
    }, [props.resetCartId]);

    const onChange = (value: string): void => {
        setCartId(value)
        if (!value) {
            return
        }
        if (inputTimeoutHandler) {
            clearTimeout(inputTimeoutHandler)
        }
        inputTimeoutHandler = setTimeout(() => {
            if (cartRegex.test(value)) {
                setErrorMessage(undefined)
                props.onValidCartId(value.trim())
                if (props.clearAfterSuccessfulScan) {
                    setCartId("")
                }
            } else {
                setErrorMessage(`${value} is not a valid cart id!`)
                setCartId("")
                errrorBeep()
            }
        }, 100)
    }


    return (
        <Input
            id="cartId"
            value={cartId}
            onChange={onChange}
            type="text"
            placeholder="Scan a cartId (i.e. CART_000k1jfr_R)"
            errorMessage={errorMessage}
            autoFocus={true}
        />
    )
}