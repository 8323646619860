import {AuditCommand, AuditEventInput, EchoCommand, EchoCommandOutput, GoCartClient} from "@amzn/gocartservice-client"
import {Configuration, getGoCartServiceRegion} from "../configuration/configuration"
import {CredentialRepo} from "./credentialRepo"

export class GoCartServiceRepo {
    private client: GoCartClient

    constructor(credentialRepo: CredentialRepo, configuration: Configuration) {
        this.client = new GoCartClient({
            endpoint: configuration.goCartServiceUrl,
            region: getGoCartServiceRegion(),
            credentials: credentialRepo.getCredentials,
            serviceId: "execute-api"
        })
    }

    async echoTest(): Promise<EchoCommandOutput> {
        const command: EchoCommand = new EchoCommand({string: "test"})
        return await this.client.send(command);
    }

    async postAuditEvent(event: AuditEventInput): Promise<void> {
        const command = new AuditCommand(event)
        console.log("sending audit to backend", command)
        await this.client.send(command)
        console.log("audit backend complete")

    }
}

