/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import { useQuery } from "@apollo/client";
import { GetNodeConfigDocument } from "../../graphql.generated";
import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";

interface PerfectNodeConfig {
  cmmUI: RfScannerConfig;
}

interface RfScannerConfig {
  hideLeaveContainerButtonEnabled: boolean;
  mergeContainerEnabled: boolean;
  displayScanRateEnabled: boolean;
  workflowOptimizationEnabled: boolean;
  createContainerUsingCmasEnabled: boolean;
  isFlowModeEnabledForContainerBuilder: boolean;
}

/**
 * Custom Hook to get perfect node configurations in json string. Return empty string if fails.
 */
export function useGetNodeConfig(fetchPolicy: WatchQueryFetchPolicy) {
  const { data, error } = useQuery(GetNodeConfigDocument, {
    fetchPolicy: fetchPolicy,
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  if (
    data &&
    data.getNodeConfig &&
    data.getNodeConfig.nodeConfigurations.length > 0
  ) {
    return data.getNodeConfig.nodeConfigurations;
  }

  if (error) {
    // Do nothing and return empty string.
  }

  return "";
}

/**
 * Parse node configuration json string and get RF Scanner configs.
 * @param nodeConfigurations
 */
export function getRfScannerConfig(nodeConfigurations: string) {
  try {
    const nodeConfig = JSON.parse(nodeConfigurations);
    if (isNodeConfig(nodeConfig)) {
      const rfScannerConfig = nodeConfig.cmmUI;
      if (isRfScannerConfig(rfScannerConfig)) {
        return rfScannerConfig;
      }
    }
  } catch (e) {}

  // Default feature flag
  return {
    hideLeaveContainerButtonEnabled: false,
    mergeContainerEnabled: false,
    displayScanRateEnabled: false,
    workflowOptimizationEnabled: false,
    createContainerUsingCmasEnabled: false,
    isFlowModeEnabledForContainerBuilder: true,
  };
}

function isNodeConfig(nodeConfig: unknown): nodeConfig is PerfectNodeConfig {
  return (
    typeof nodeConfig === "object" &&
    nodeConfig !== null &&
    "cmmUI" in nodeConfig
  );
}

function isRfScannerConfig(
  rfScannerConfig: unknown
): rfScannerConfig is RfScannerConfig {
  return (
    typeof rfScannerConfig === "object" &&
    rfScannerConfig !== null &&
    "hideLeaveContainerButtonEnabled" in rfScannerConfig &&
    "mergeContainerEnabled" in rfScannerConfig &&
    "displayScanRateEnabled" in rfScannerConfig &&
    "workflowOptimizationEnabled" in rfScannerConfig &&
    "createContainerUsingCmasEnabled" in rfScannerConfig &&
    "isFlowModeEnabledForContainerBuilder" in rfScannerConfig
  );
}
