import React, { createRef, RefObject, useCallback, useEffect, useState } from "react"
import Column from "@amzn/meridian/column";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import {GoCartServiceRepo} from "../../repo/goCartServiceRepo";
import Alert from "@amzn/meridian/alert"
import Loader from "@amzn/meridian/loader"
import Tab, { TabGroup } from "@amzn/meridian/tab"
import Icon from "@amzn/meridian/icon"
import arrowLeftLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-left-large"
import arrowRightLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-right-large"
import Modal from "@amzn/meridian/modal"
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import { HelpModalComponent } from "./HelpModalComponent"
import cartBarcodeImage from "../../media/images/cartBarcode.png"
import rabBeaconBarcodeImage from "../../media/images/rabBarcode.png"
import Row from "@amzn/meridian/row"
import { useCookies } from "react-cookie"
import {JsonUtil} from "../util/JsonUtil";
import checkCircleTokens from "@amzn/meridian-tokens/base/icon/check-circle"
import Text from "@amzn/meridian/text"
import Box from "@amzn/meridian/box"



export interface CartToRABMappingAppProps {
    goCartServiceRepo: GoCartServiceRepo,
}

export function CartToRABMapping(props: CartToRABMappingAppProps) {
    const [cartId1, setCartId1] = useState("")
    const [cartId2, setCartId2] = useState("")
    const [cartId3, setCartId3] = useState("")
    const [beaconPrintedBarcode, setBeaconPrintedBarcode] = useState("")

    const [httpStatus, setHttpStatus] = useState<{ message: string, code: number } | null>(null)
    const [loading, setLoading] = useState(false)

    const cartId1InputRef: RefObject<HTMLInputElement | null> = createRef();
    const cartId2InputRef: RefObject<HTMLInputElement | null> = createRef();
    const cartId3InputRef: RefObject<HTMLInputElement | null> = createRef();
    const beaconPrintedBarcodeInputRef: RefObject<HTMLInputElement | null> = createRef();

    const [currentTab, setCurrentTab] = useState("cartId1Tab")

    const [cookies, setCookie] = useCookies(['tutorialShown']);
    const [showModal, setShowModal] = useState(!JsonUtil.convertToBoolean(cookies['tutorialShown']))

    useEffect(() => {
        switch (currentTab){
            case "cartId1Tab":
                cartId1InputRef.current?.focus();
                break;
            case "cartId2Tab":
                cartId2InputRef.current?.focus();
                break;
            case "cartId3Tab":
                cartId3InputRef.current?.focus();
                break;
            case "beaconPrintedBarcodeTab":
                beaconPrintedBarcodeInputRef.current?.focus();
                break;
        }
    }, [currentTab, beaconPrintedBarcodeInputRef, cartId1InputRef, cartId2InputRef, cartId3InputRef])

    const isEntryValid = useCallback((inputId: string) => {
        let idValidity: { [key: string]: boolean } = {
            "Cart-ID-1": cartId1 !== "" && cartId1.length > 14 && cartId1.length < 24,
            "Cart-ID-2": cartId2 === cartId1,
            "Cart-ID-3": cartId3 === cartId1,
            "beaconPrintedBarcode": beaconPrintedBarcode !== "",
        };
        return idValidity[inputId];
    }, [cartId1, cartId2, cartId3, beaconPrintedBarcode]);

    function onSubmit() {
        setLoading(true)
        if(!isEntryValid("beaconPrintedBarcode")){
            setLoading(false)
            setHttpStatus({
                message: "Barcode Id is invalid",
                code: 400
            })
            return
        }
        if([
            isEntryValid("Cart-ID-1"),
            isEntryValid("Cart-ID-2"),
            isEntryValid("Cart-ID-3")
        ].some(it => !it)){
            setLoading(false)
            setHttpStatus({
                message: "Cart Ids are invalid",
                code: 400
            })
            return
        }
        else {
            setLoading(false)
            setHttpStatus({
                message: "Mapped Successfully",
                code: 200
            })
        }
    }

    const getAlertType = (code: Number | null) => {
        return code === 200 ? "success" : "error"
    }

    useEffect(() => {
        if(httpStatus === null) return
        setTimeout(() => {
            setHttpStatus(null)
        }, 3000)

        //reset scannables
        if(httpStatus?.code === 200){
            setCartId1("")
            setCartId2("")
            setCartId3("")
            setBeaconPrintedBarcode("")
            setCurrentTab("cartId1Tab")
        }
    }, [httpStatus])

    return (
        <React.Fragment>
            <Column spacingInset="100 300" height={"100%"} heights={["fit", "fit", "fit", "fill", "fit"]} >
                <Heading level={2} alignment={"center"} >Cart to RAB MApping</Heading>
                <Modal
                    title="Mapping Tutorial"
                    open={showModal}
                    scrollContainer="viewport"
                    aria-describedby="modal-description"
                    bodySpacingInset="450"
                >
                    <HelpModalComponent closeModal={() => setShowModal(false)} setCookie={setCookie}/>
                </Modal>
                <TabGroup
                    value={currentTab}
                    onChange={setCurrentTab}
                    aria-labelledby="tablist-heading"
                    fill={"tabs"}
                >
                    <Tab
                        value="cartId1Tab"
                        aria-controls="cartId1Tab"
                    >
                        Cart Id 1
                    </Tab>
                    <Tab
                        value="cartId2Tab"
                        aria-controls="cartId2Tab"
                    >
                        Cart Id 2
                    </Tab>
                    <Tab
                        value="cartId3Tab"
                        aria-controls="cartId3Tab"
                    >
                        Cart Id 3
                    </Tab>
                    <Tab
                        value="beaconPrintedBarcodeTab"
                        aria-controls="beaconPrintedBarcodeTab"
                    >
                        Beacon
                    </Tab>
                </TabGroup>
                <Row width="100%" widths={"25%"}>
                    <Box backgroundColor={isEntryValid("Cart-ID-1") ? "green":"red"}>
                        <Text alignment="center" breakWord={true}>{cartId1}</Text>
                    </Box>
                    <Box backgroundColor={isEntryValid("Cart-ID-2") ? "green":"red"}>
                        <Text alignment="center" breakWord={true}>{cartId2}</Text>
                    </Box>
                    <Box backgroundColor={isEntryValid("Cart-ID-3") ? "green":"red"}>
                        <Text alignment="center" breakWord={true}>{cartId3}</Text>
                    </Box>
                    <Box backgroundColor={isEntryValid("beaconPrintedBarcode") ? "green":"red"}>
                        <Text alignment="center" breakWord={true}>{beaconPrintedBarcode}</Text>
                    </Box>
                </Row>
                {currentTab === "cartId1Tab" && (
                    <CartIdScanningTab
                        inputRef={cartId1InputRef}
                        id={"Cart-ID-1"}
                        value={cartId1}
                        onChange={setCartId1}
                        image={cartBarcodeImage}
                        goBack={() => setShowModal(true)}
                        goToNextTab={() => setCurrentTab("cartId2Tab")}
                        setHttpStatus={() => setHttpStatus({message: "Cart Id 1 is invalid", code: 400})}
                        isValid={() => isEntryValid("Cart-ID-1")}
                    />
                )}
                {currentTab === "cartId2Tab" && (
                    <CartIdScanningTab
                        inputRef={cartId2InputRef}
                        id={"Cart-ID-2"}
                        value={cartId2}
                        onChange={setCartId2}
                        image={cartBarcodeImage}
                        goBack={() => setCurrentTab("cartId1Tab")}
                        goToNextTab={() => setCurrentTab("cartId3Tab")}
                        setHttpStatus={() => setHttpStatus({message: "Cart Id 2 is invalid or does not match Cart Id 1", code: 400})}
                        isValid={() => isEntryValid("Cart-ID-2")}
                    />
                )}
                {currentTab === "cartId3Tab" && (
                    <CartIdScanningTab
                        inputRef={cartId3InputRef}
                        id={"Cart-ID-3"}
                        value={cartId3}
                        onChange={setCartId3}
                        image={cartBarcodeImage}
                        goBack={() => setCurrentTab("cartId2Tab")}
                        goToNextTab={() => setCurrentTab("beaconPrintedBarcodeTab")}
                        setHttpStatus={() => setHttpStatus({message: "Cart Id 3 is invalid or does not match Cart Id 1 and Cart Id 2", code: 400})}
                        isValid={() => isEntryValid("Cart-ID-3")}
                    />
                )}
                {currentTab === "beaconPrintedBarcodeTab" && (
                    <Column spacingInset={"400"} spacing={"500"} alignmentHorizontal="center" >
                        <img
                            src={rabBeaconBarcodeImage}
                            style={{ maxWidth: '60%', height:"50%" }}
                            alt={"Correct cart barcode"}
                        />
                        <Input
                            id="beaconPrintedBarcode"
                            ref={beaconPrintedBarcodeInputRef}
                            value={beaconPrintedBarcode}
                            onChange={setBeaconPrintedBarcode}
                            type="text"
                            placeholder="Scan beacon printed barcode..."
                            label="Barcode ID"
                            onKeyDown={(event) => {
                                if(event.key === "Enter") {
                                    onSubmit()
                                }
                            }}
                        />
                        <Row>
                            <Button size={"large"} onClick={() => setCurrentTab("cartId3Tab")} ><Icon tokens={arrowLeftLargeTokens} /> Back</Button>
                            <Button size={"large"} onClick={onSubmit} disabled={loading}>{loading ? <Loader size="medium" /> : "Submit"}<Icon tokens={checkCircleTokens} /></Button>
                        </Row>
                    </Column>
                )}
                {httpStatus && <Alert type={getAlertType(httpStatus.code)} size="medium" >{httpStatus.message}</Alert>}
                <Button size={"large"} onClick={() => setShowModal(true)} >Help <Icon tokens={helpTokens} /></Button>
            </Column>
        </React.Fragment>
    )
}

interface CartIdScanningTabProps {
    inputRef: React.RefObject<HTMLInputElement | null>,
    id: string,
    value: string,
    onChange: (e: string) => void,
    image: string,
    goToNextTab: () => void,
    goBack: () => void,
    setHttpStatus: () => void,
    isValid: () => boolean,
}

export function CartIdScanningTab(props: CartIdScanningTabProps) {
    const onEnter = () => {
        if(props.isValid()) {
            props.goToNextTab()
        }
        else {
            props.setHttpStatus()
        }
    }

    return (
        <Column spacingInset={"400"} spacing={"500"} alignmentHorizontal="center" >
            <img
                src={props.image}
                style={{ maxWidth: '50%', height:"50%" }}
                alt={`${props.id} barcode`}
            />
            <Input
                ref={props.inputRef}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
                type="text"
                placeholder={`Scan ${props.id} BARCODE...`}
                label={props.id}
                onKeyDown={(event) => {
                    if(event.key === "Enter") {
                        onEnter()
                    }
                }}
            />
            <Row>
                <Button size={"large"} onClick={props.goBack} ><Icon tokens={arrowLeftLargeTokens} /> Back</Button>
                <Button size={"large"} onClick={onEnter} >Next <Icon tokens={arrowRightLargeTokens} /></Button>
            </Row>
        </Column>
    )
}



