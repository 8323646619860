/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import Box from "@amzn/meridian/box";
import { useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { RoleLevel, RoleName } from "../../graphql.generated";
import {
  getRfScannerConfig,
  useGetNodeConfig,
} from "../../utils/perfectnode/getNodeConfiguration";
import {AppContext, RoleContext} from "../App";
import PlainMessage from "../common/message/PlainMessage";
import ModalDialog from "../common/modal/ModalDialog";
import { TaskSelectProps } from "../common/taskSelect/TaskSelect";
import TaskSelectGroup from "../common/taskSelect/TaskSelectGroup";
import { URL_HOME } from "../layout/AppMainContent";
import styles from "./ContainerBuilderWorkflow.module.css";
import ContainerInfoWorkflow from "./subworkflows/ContainerInfoWorkflow";
import FlowWorkflow from "./subworkflows/FlowWorkflow";
import TrickleWorkflow from "./subworkflows/TrickleWorkflow";
import Workflow, { Task as WorkflowTask } from "./Workflow";

enum Task {
  /** No task is selected, show Container Builder homescreen to select the other tasks. */
  None,
  /** Move packages by scanning source package and destination container one-at-a-time.  */
  Trickle,
  /** Move packages by scanning destination container then source packages multiple-at-a-time. */
  Flow,
  /** Get package information by scanning the source package. */
  PackageInfo,
}

enum Substate {
  ChangeRole,
  ChangeTask,
}

interface ContainerBuilderWorkflowStateData {
  currentTask: Task;
  substate?: Substate;
}

interface ContainerBuilderWorkflowProps {
  currentTask?: Task;
}

/**
 * Checks if the user has the required role and level greater than or equal to the required level.
 * @param currentRole the SAM role
 * @param currentLevel the SAM role level
 * @param requiredRole the required SAM role
 * @param requiredLevel the minimum required SAM level
 * @returns boolean
 */
const hasPermission = (
  currentRole: string | undefined,
  currentLevel: string | undefined,
  requiredRole: RoleName,
  requiredLevel: RoleLevel
) => {
  if (currentRole && currentLevel && currentRole === requiredRole) {
    const levelValues: Array<string> = Object.values(RoleLevel);
    if (
      levelValues.indexOf(currentLevel) >= levelValues.indexOf(requiredLevel)
    ) {
      return true;
    }
  }
  return false;
};

export const ContainerBuilderWorkflow = (
  props: ContainerBuilderWorkflowProps
) => {
  const { t } = useTranslation("components.workflows.ContainerBuilderWorkflow");
  const history = useHistory();
  const { currentRole, currentRoleLevel } = useContext(RoleContext);
  const {dynamicContainerizationRepo} = useContext(AppContext);

  // Flow mode is configurable in Perfect Node. The user must also be an Intermediate Container Builder in FCLM.
  const isFlowModeEnabledForContainerBuilder =
    getRfScannerConfig(useGetNodeConfig("cache-and-network"))
      .isFlowModeEnabledForContainerBuilder &&
    hasPermission(
      currentRole,
      currentRoleLevel,
      RoleName.ContainerBuilder,
      RoleLevel.Level_2
    );

  const [state, setState] = useReducer(
    (
      state: ContainerBuilderWorkflowStateData,
      newState: Partial<ContainerBuilderWorkflowStateData>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      currentTask: props.currentTask ?? Task.None,
    }
  );

  const resetState = (task: Task) => {
    setState({
      currentTask: task,
      substate: undefined,
    });
  };

  const containerBuilderTaskSelectList: Array<TaskSelectProps> = [
    ...[
      {
        localizedTaskTitle: t("trickle-task-select-title"),
        localizedTaskDescription: t("trickle-description"),
        onClickAction: () => {
          setState({ currentTask: Task.Trickle });
        },
      },
      {
        localizedTaskTitle: t("package-info-task-select-title"),
        localizedTaskDescription: t("package-info-description"),
        onClickAction: () => {
          setState({ currentTask: Task.PackageInfo });
        },
      },
    ],
    ...(isFlowModeEnabledForContainerBuilder === true
      ? [
          {
            localizedTaskTitle: t("flow-task-select-title"),
            localizedTaskDescription: t("flow-description"),
            onClickAction: () => {
              setState({ currentTask: Task.Flow });
            },
          },
        ]
      : []),
  ];

  const createMoreOptionItem = (key: string, substate?: Substate) => ({
    displayName: t(key),
    action: () => setState({ substate: substate }),
  });

  const createMoreOptionsList = () => {
    return [
      createMoreOptionItem("change-role", Substate.ChangeRole),
      createMoreOptionItem("change-task", Substate.ChangeTask),
    ];
  };

  const changeRoleOnClickAction = (optionNumber: number) => {
    setState({ substate: undefined });
    if (optionNumber === 0) {
      history.push(URL_HOME);
    }
  };

  const changeTaskOnClickAction = (optionNumber: number) => {
    setState({ substate: undefined });
    if (optionNumber === 0) {
      resetState(Task.None);
    }
  };

  const goBackHomeOnClickAction = () => {
    resetState(Task.None);
  };

  return (
    <div className={styles["root"]}>
      <ModalDialog
        displayStatus={state.substate === Substate.ChangeRole}
        headerTitle={t("close-role-title")}
        alertType="warning"
        alertTitle={t("close-role-title")}
        message={t("close-role-message")}
        primaryActionMessage={t("close-role-primary-button")}
        secondaryActionMessage={t("close-role-secondary-button")}
        onClickAction={changeRoleOnClickAction}
      />

      <ModalDialog
        displayStatus={state.substate === Substate.ChangeTask}
        headerTitle={t("change-task-title")}
        alertType="warning"
        alertTitle={t("change-task-title")}
        message={t("change-task-message")}
        primaryActionMessage={t("change-task-primary-button")}
        secondaryActionMessage={t("change-task-secondary-button")}
        onClickAction={changeTaskOnClickAction}
      />

      <Workflow
        title={t("container-builder-banner-title")}
        currentTaskId={state.currentTask}
        moreOptionsCallback={createMoreOptionsList}
        updateCurrentTaskId={(currentTask) =>
          setState({ currentTask: currentTask })
        }
      >
        <WorkflowTask taskId={Task.None}>
          <PlainMessage title={t("select-task-title")} message={""} />
          <Box spacingInset={"medium"}>
            <TaskSelectGroup taskList={containerBuilderTaskSelectList} />
          </Box>
        </WorkflowTask>

        <WorkflowTask taskId={Task.Trickle}>
          <TrickleWorkflow
            localizedBannerTitle={t("container-builder-banner-title")}
            moreOptionsList={createMoreOptionsList}
            dynamicContainerizationRepo={dynamicContainerizationRepo!}
          />
        </WorkflowTask>

        <WorkflowTask taskId={Task.Flow}>
          <FlowWorkflow
            localizedBannerTitle={t("container-builder-banner-title")}
            moreOptionsList={createMoreOptionsList}
          />
        </WorkflowTask>

        <WorkflowTask taskId={Task.PackageInfo}>
          <ContainerInfoWorkflow
            localizedBannerTitle={t("container-builder-banner-title")}
            displayMessagesByContainerType={"PACKAGE"}
            additionalContainerInfo={{
              withHazmat: true,
            }}
            moreOptionsList={createMoreOptionsList}
            onGoBackHomeOnClick={goBackHomeOnClickAction}
          />
        </WorkflowTask>
      </Workflow>
    </div>
  );
};

export default ContainerBuilderWorkflow;
