/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React, { Component } from "react";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";

interface CallToActionButtonProps {
  text: string;
  disabled: boolean;
  buttonNumber: number;
  onClick: (optionNumber: number) => void;
}

export interface CallToActionProps {
  primaryButtonLabel: string;
  primaryButtonDisabled?: boolean;
  secondaryButtonLabel?: string;
  secondaryButtonDisabled?: boolean;
  onClick: (optionNumber: number) => void;
}

const CallToActionButton = (props: CallToActionButtonProps) => {
  return (
    <Button
      id={"button" + props.buttonNumber}
      onClick={() => {
        props.onClick(props.buttonNumber);
      }}
      size="large"
      type={props.buttonNumber === 0 ? "primary" : "tertiary"}
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  );
};

export class CallToAction extends Component<CallToActionProps, {}> {
  constructor(props: CallToActionProps) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  /** Handle removing CallToAction box after an option is clicked. */
  handleButtonClick(buttonNumber: number) {
    this.props.onClick(buttonNumber);
  }

  render() {
    const primaryButtonDisabled = this.props.primaryButtonDisabled ?? false;
    let buttonTwo;
    let secondaryButtonDisabled;
    if (this.props.secondaryButtonLabel) {
      secondaryButtonDisabled = this.props.secondaryButtonDisabled ?? false;
      buttonTwo = (
        <CallToActionButton
          text={this.props.secondaryButtonLabel}
          disabled={secondaryButtonDisabled}
          onClick={this.handleButtonClick}
          buttonNumber={1}
        />
      );
    }

    return (
      <Box spacingInset={"medium"}>
        <Column>
          <CallToActionButton
            text={this.props.primaryButtonLabel}
            disabled={primaryButtonDisabled}
            onClick={this.handleButtonClick}
            buttonNumber={0}
          />
          {buttonTwo}
        </Column>
      </Box>
    );
  }
}

export default CallToAction;
