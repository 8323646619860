import React, {useMemo} from "react";
import Column from "@amzn/meridian/column";
import DisplayLoader from "../common/loading/DisplayLoader";
import {FCMenuDecoder, FCMenuProperties} from "../../environment/fcMenu";
import {useHistory} from "react-router-dom";
import {DynamicContainerizationRepo} from "../../repo/dynamicContainerizationRepo";
import {StackingAreaScanner} from "./StackingAreaScanner";

interface CheckInScanProps {
    dynamicContainerizationRepo: DynamicContainerizationRepo
}

export function CheckInScan(props: CheckInScanProps) {
    const [loading, setLoading] = React.useState(false);
    const history = useHistory();
    const fcMenuProps = useMemo<FCMenuProperties>(() => {
        console.log("decrypting fcMenu cookie");
       return FCMenuDecoder.decodeFcMenuCookie()!;
    }, []);

    if(loading) {
        return (
            <Column alignmentHorizontal={"center"}
                    alignmentVertical={"center"}
                    minHeight={"100%"}>
                <DisplayLoader message={'Checking you in!'}/>
            </Column>
        )
    }

    const onCheckInScan = async (id: string) => {
        setLoading(true);
        try {
            const result = await props.dynamicContainerizationRepo.checkinScan({
                fcMenuProps: fcMenuProps,
                stackingArea: id
            });
            if (result.$metadata.httpStatusCode === 200) {
                // TODO: prevent user from needing to checkIn again. However, we dont have enough info to know if they change SPURs
            }
            history.push('/containerBuilder');
        } catch(e){
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    const onGoBackCallback = () => {
        history.push("/");
    }

    return <StackingAreaScanner onSuccessCallback={onCheckInScan} goBackCallback={onGoBackCallback}/>
}
