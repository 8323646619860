import Box from "@amzn/meridian/box";
import Input from "@amzn/meridian/input";
import React, {useRef, useState} from "react"
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import {CartInput} from "../common/CartInput";
import Text from "@amzn/meridian/text"
import {FCMenuDecoder} from "../../environment/fcMenu";
import {successBeep} from "../util/beep";
import Loader from "@amzn/meridian/loader"
import {CartStateDropdown} from "../util/Constants";
import Select, {SelectOption} from "@amzn/meridian/select";
import {HeiseServiceRepo} from "../../repo/heiseServiceRepo";

enum SiteAuditMode {
    INITIALIZATION,
    SCANNING
}

export interface TrailerAuditProps {
    heiseServiceRepo: HeiseServiceRepo,
}

export function TrailerAudit(props: TrailerAuditProps) {
    const userId = FCMenuDecoder.decodeFcMenuCookie()?.employeeLogin ?? 'unknown'
    const [siteId, setSiteId] = useState<string>(FCMenuDecoder.decodeFcMenuCookie()?.warehouseId ?? "")
    const [trailerId, setTrailerId] = useState<string>("")
    const [mode, setMode] = useState<SiteAuditMode>(SiteAuditMode.INITIALIZATION)
    const [numberScanned, setNumberScanned] = useState(0)
    const [recentCartsScanned, setRecentCartsScanned] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const trailerIdRef = useRef<HTMLInputElement | null>(null)
    const [cartState, setCartState] = useState<string>("")

    const initialSetup = () => (
        <Column>
            <Input
                id="siteId"
                value={siteId}
                onChange={(value) => setSiteId(value.toUpperCase())}
                type="text"
                placeholder="Enter Your Site ID (i.e. MSP1)"
            />
            <Input
                ref={trailerIdRef}
                id="trailerId"
                value={trailerId}
                onChange={(value) => setTrailerId(value.toUpperCase())}
                type="text"
                placeholder="Scan/Enter trailer id"
            />
            <Select
                value={cartState}
                size="large"
                onChange={setCartState}
                placeholder="Select Cart State..."
            >
                {
                    CartStateDropdown.map((item, index) => {
                        return <SelectOption key={index} value={item.value} label={item.label} />
                    })
                }
            </Select>
            <Button
                size={"large"}
                onClick={() => setMode(SiteAuditMode.SCANNING)}
                disabled={siteId.length !== 4 || trailerId === "" || cartState === ""}
            >
                Start
            </Button>
        </Column>
    )

    const handleCartScanned = async (cartId: string) => {
        console.log("cart scanned: " + cartId)
        setLoading(true)

        await props.heiseServiceRepo.cartAuditEvent({
            timestamp: new Date(),
            nodeId: siteId,
            cartId: cartId,
            operationalState: cartState.trim(),
            userId: userId,
            beaconInfoValidated: true,
            trailerInfo: trailerId
        })

        successBeep()
        setNumberScanned(prev => prev + 1)
        recentCartsScanned.unshift(cartId)
        setRecentCartsScanned(recentCartsScanned.splice(0, 10))
        setLoading(false)
    }

    const onNewTrailerLoad = () => {
        setTrailerId("")
        setCartState("")
        setRecentCartsScanned([])
        setMode(SiteAuditMode.INITIALIZATION)
    }

    const scanningMode = () => (
        <Column>
            <Heading level={2} alignment={"center"}>{siteId}-{trailerId}: Scanned Count: {numberScanned}</Heading>
            <CartInput
                onValidCartId={handleCartScanned} clearAfterSuccessfulScan={true}
            />
            <Button onClick={onNewTrailerLoad}>New Trailer Load</Button>
            {loading && <Loader />}

            <Heading level={4} alignment={"left"}>Recent Scans:</Heading>
            <Box type={"fill"} spacingInset={"400"}>
                {recentCartsScanned.map((value, index) => (
                    <Text key={index}>{value}</Text>
                ))
                }
            </Box>
        </Column>
    )

    return (
        <Box spacingInset="100 300">
            <Heading level={2} alignment={"center"}>Audit Carts On A Trailer</Heading>
            {mode === SiteAuditMode.INITIALIZATION && initialSetup()}
            {mode === SiteAuditMode.SCANNING && scanningMode()}
        </Box>
    )


}