/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import { GetRolesMock } from "../workflows/mockData/Roles.mock";

import { GetDestinationsForContainerMock } from "../workflows/mockData/GetDestinationsForContainer.mock";
import { GetNodeConfigMock } from "../workflows/mockData/GetNodeConfig.mock";
import {IdentifyByContainerMock} from "../workflows/mockData/IdentifyByContainer.mock";
import {IdentifyContainerBy2PackageMock} from "../workflows/mockData/IdentifyBy2Package.mock";
import {MoveContainerMock} from "../workflows/mockData/Move.mock";


/**
 * Below is the list of training mode mock data objects for all the GraphQL queries expected to run in a training mode.
 * If any query not defined in the below list, training mode will render error while getting the data.
 */
export const TrainingProviderMock = [
  ...GetRolesMock,
  ...IdentifyByContainerMock,
  ...IdentifyContainerBy2PackageMock,
  ...MoveContainerMock,
  ...GetDestinationsForContainerMock,
  ...GetNodeConfigMock,
];
