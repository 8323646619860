import jwtDecode from "jwt-decode"
import {Cookies} from "react-cookie"
import {getConfiguration} from "../configuration/configuration";

/**
 * Name of the main cookie set by FCMenu. It is also used as a query parameter name.
 */
export const FCMENU_SENSITIVE_COOKIE_NAME = "fcmenu-sensitive"

export interface FCMenuProperties {
    /** numerical employee id of the associate (this is not the badge id) */
    employeeId: string
    /** username of the associate */
    employeeLogin: string
    /** authentication mode used by fcmenu, for example "basic" or "openid" */
    authMode: string
    /** id of the node, for example "PHX8" */
    warehouseId: string
    /** id of the menu item the associate tapped to access SAM, for example "1285" */
    menuItemId: string
    /** level for the permission the user used in FCMenu to access SAM */
    employeePermissionLevel: string | undefined
    /** language preference of the associate */
    locale: string
    /** timestamp when this cookie will expire */
    expiry: Date
    /** website which issued this cookie, for example "fcmenu-iad-regionalized.corp.amazon.com" */
    issuer: string

    encodedValue?: string
}

export class FCMenuDecoder {

    /**
     * Fetches the fcmenu-sensitive value, parses it and returns the data contained within it.
     * Returns undefined if the value is missing or there was an error parsing it.
     */
    static decodeFcMenuCookie(): FCMenuProperties | undefined {
        const encodedValue =
            new URLSearchParams(window.location.search).get(
                FCMENU_SENSITIVE_COOKIE_NAME
            ) || new Cookies().get<string | undefined>(FCMENU_SENSITIVE_COOKIE_NAME)
        if (!encodedValue) {
            return undefined
        }
        try {
            // Values are defined in
            // https://code.amazon.com/packages/FCMenuWebsite/blobs/mainline/--/src/amazon/fcmenu/business/MenuSession.java
            const token = jwtDecode<{
                1: string
                2: string
                3: string
                4: string
                5: string
                6?: string
                7: string
                exp: number
                iss: string
            }>(encodedValue)
            return {
                /** numerical employee id of the associate (this is not the badge id) */
                employeeId: token["1"],
                /** username of the associate */
                employeeLogin: token["2"],
                /** authentication mode used by fcmenu, for example "basic" or "openid" */
                authMode: token["3"],
                /** id of the node, for example "PHX8" */
                warehouseId: token["4"],
                /** id of the menu item the associate tapped to access SAM, for example "1285" */
                menuItemId: token["5"],
                /** level for the permission the user used in FCMenu to access SAM */
                employeePermissionLevel: token["6"],
                /** language preference of the associate */
                locale: token["7"],
                /** timestamp when this cookie will expire */
                expiry: new Date(token.exp * 1000),
                /** website which issued this cookie, for example "fcmenu-iad-regionalized.corp.amazon.com" */
                issuer: token.iss,
                encodedValue: encodedValue,
            }
        } catch (e) {
            return undefined
        }
    }

    static redirectOnInvalidToken():FCMenuProperties | undefined {
        const cookie = this.decodeFcMenuCookie()
        if (cookie && cookie.expiry > new Date()) {
            return cookie//all good
        }
        window.location.href = getConfiguration().loginUrl
        return undefined
    }
}
