import { getConfiguration } from "../configuration/configuration"
import axios from "axios"
import { Credentials } from "@aws-sdk/types"
import { Credentials as STSCredentials } from "@aws-sdk/client-sts"
import moment from "moment";
import {FCMenuDecoder} from "../environment/fcMenu";

export class CredentialRepo {
    private readonly config = getConfiguration()
    private credentials: Credentials | undefined = undefined
    constructor() {
        console.log("created credential repo");
    }

    public getCredentials = async (): Promise<Credentials> => {
        let expiration = this.credentials?.expiration
        if (expiration && expiration > new Date()) {
            console.info("returning cached credentials")
            return this.credentials!
        }

        const fcmenu = FCMenuDecoder.decodeFcMenuCookie()
        const url = this.config.credentialsUrl
        const credsResponse = await axios.get<STSCredentials>(url, {
            headers: {
                "fcmenu-sensitive": fcmenu?.encodedValue || "",
            },
        })
        console.log("credentials repo response", credsResponse.status)
        let credsFromWebService = credsResponse.data
        const expirationDate = new Date(credsFromWebService.Expiration!)
        //subtract 30 seconds from the real expiration to ensure the creds don't expire mid-flight.
        const ourExpiration = moment(expirationDate).subtract(30, 'seconds')
        console.log("setting expiration to " + ourExpiration.fromNow())

        this.credentials = {
            accessKeyId: credsFromWebService.AccessKeyId,
            secretAccessKey: credsFromWebService.SecretAccessKey,
            sessionToken: credsFromWebService.SessionToken,
            expiration: ourExpiration.toDate(),
        } as Credentials

        return this.credentials
    }
}
