import React from "react"
import Text from "@amzn/meridian/text"
import Box from "@amzn/meridian/box"
import Heading from "@amzn/meridian/heading"
import Row from "@amzn/meridian/row";
import Link from "@amzn/meridian/link";
import {BarcodeImage, ScanReader} from "./ScanReader";

export interface ContainerInfoProps {
    containerAttribute1: string
    containerAttribute2: string
    barcodeImage?: BarcodeImage
}


export class SimpleScanReader extends ScanReader {

    render() {
        return (<Box>
                {this.props.header && (
                    <Heading level={2}>{this.props.header}</Heading>
                )}
                {this.props.message && <Text>{this.props.message}</Text>}
                {this.props.helpPageLink &&<Text>Check the <Link href={this.props.helpPageLink}>help page</Link> for information on where to find the barcodes</Text>}
                {this.props.ContainerInfo && (
                    <Box spacingInset="medium">
                        <Text alignment="left" type="b200">
                            {this.props.ContainerInfo?.containerAttribute1}
                        </Text>
                        <Text alignment="left" type="b200">
                            {this.props.ContainerInfo?.containerAttribute2}
                        </Text>
                    </Box>
                )}
                {
                    <Row alignmentHorizontal={"center"}>
                        <img
                            src={this.getBarcodeImage()}
                            style={{margin: "auto", maxWidth: this.props.imageMaxWidth ?? '80%'}}
                            alt={"barcode_with_laser"}
                        />
                    </Row>
                }

                <Row widths={["fill", "fit", "fill"]}>
                    <Box/>
                    <Box spacingInset={"medium"}>
                        <input
                            onBlur={this.setFocusOnInput}
                            // style={{ position: "absolute", top: "-1000px"}}
                            onKeyUp={this.onKeyupHandler}
                            ref={this.inputRef}
                            autoFocus
                        />
                    </Box>
                    <Box/>
                </Row>
            </Box>
        )
    }
}