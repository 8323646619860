/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { MockedResponse } from "@apollo/client/testing";
import { GraphQLError } from "graphql";
import { MOVE_CONTAINER_MUTATION } from "../../functions/move/Move.gql";

export const MoveContainerMock: ReadonlyArray<MockedResponse> = [
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "test_003",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "test_003",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "test_001",
          overrideValidation: false,
        },
      },
    },
    newData: () => {
      return {
        errors: [
          new GraphQLError(
            "IncompatibleContainerTypesException: Container of Type: PACKAGE cannot move into a Container of Type: PACKAGE",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            {
              rootCause: "HazmatContainerException",
              classification: "DataFetchingException",
            }
          ),
        ],
        data: null,
      };
    },
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "test_001",
          overrideValidation: true,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "test_001",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "test",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "MovementToItselfException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "MovementToItselfException",
            classification: "DataFetchingException",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "test_other",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "DCM_TEST_DOCK_DOOR",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Pallet_sj939js_01",
          destinationScannable: "DCM_TEST_DOCK_DOOR",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_001",
          destinationScannable: "Package_002",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_001",
          destinationScannable: "Package_002",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "Package_001",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_002",
          destinationScannable: "Package_001",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_001",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_001",
          destinationScannable: "PALLET_mVrVKGA_Z",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_002",
          destinationScannable: "PALLET_mVrVKGA_Z",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_003",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ContainerNotCompatible",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_004",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ContainerNotOpened",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_005",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ContainerWeightLimitExceeded",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_006",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "CustomsFlagged",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_007",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ExclusiveParent",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_008",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "Hazmat",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_008",
          destinationScannable: "HAZMAT_AREA",
          overrideValidation: true,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_008",
          destinationScannable: "HAZMAT_AREA",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_009",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidDestination",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_010",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidInput",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_011",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidStackingFilter",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_012",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidTrailer",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_013",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ParentContainerNotOpened",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_014",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "TooManyChildren",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_015",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "UnexpectedNode",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_016",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "FAKE_EXCEPTION",
            classification: "DataFetchingException",
            errorType: "FAKE_EXCEPTION",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "PALLET_mVrVKGA_Z",
          destinationScannable: "PALLET_mVrVKGA_Z",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "MoveToItself",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "Package_003",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "MoveToSameParent",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "Package_002",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "MoveToItself",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_01",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ContainerNotCompatible",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_02",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ContainerNotOpened",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_03",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ContainerWeightLimitExceeded",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_04",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "CustomsFlagged",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_05",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ExclusiveParent",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_06",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "Hazmat",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_07",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidDestination",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_08",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidInput",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_09",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidStackingFilter",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_10",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "InvalidTrailer",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_11",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "ParentContainerNotOpened",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_12",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "TooManyChildren",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "INVALID_DESTINATION_13",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      errors: [
        new GraphQLError(
          "otherException",
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          {
            rootCause: "OtherException",
            classification: "DataFetchingException",
            errorType: "UnexpectedNode",
          }
        ),
      ],
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "HAZMAT_AREA",
          overrideValidation: true,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_002",
          destinationScannable: "HAZMAT_AREA",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_002",
          destinationScannable: "PROBLEM_SOLVE_AREA",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_002",
          destinationScannable: "PROBLEM_SOLVE_AREA",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_008",
          destinationScannable: "HAZMAT_AREA",
          overrideValidation: true,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_015",
          destinationScannable: "HAZMAT_AREA",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "Package_015",
          destinationScannable: "PROBLEM_SOLVE_AREA",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "Package_015",
          destinationScannable: "PROBLEM_SOLVE_AREA",
        },
      },
    }),
  },
  {
    request: {
      query: MOVE_CONTAINER_MUTATION,
      variables: {
        input: {
          sourceScannable: "WRONG_NODE_SCANNABLE_01",
          destinationScannable: "PROBLEM_SOLVE_AREA",
          overrideValidation: false,
        },
      },
    },
    newData: () => ({
      data: {
        moveContainer: {
          sourceScannable: "WRONG_NODE_SCANNABLE_01",
          destinationScannable: "PROBLEM_SOLVE_AREA",
        },
      },
    }),
  },
];
