import React from "react"
import Box from "@amzn/meridian/box"
import Text from "@amzn/meridian/text"
import "./VSM.css"

export interface VSMProps {
    localizedVSMTitle: string
    localizedVSMLabel: string
}

function VSM(props: VSMProps) {
    return (
        <Box
            width={"100%"}
            height={"100%"}
            spacingInset={"400"}
            className={"vsmBorder"}
            backgroundColor={"#FCDE4D"}
        >
            <Text alignment={"center"} type={"h500"}>
                {props.localizedVSMTitle}
            </Text>
            <Text alignment={"center"} type={"d200"}>
                {props.localizedVSMLabel}
            </Text>
        </Box>
    )
}

export default VSM
