/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React, { Component } from "react";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import barcode from "../../../media/images/barcode.svg";
import packageBarcode from "../../../media/images/packageBarcode.svg";
import qrBarcode from "../../../media/images/qrBarcode.svg";
import aztecBarcode from "../../../media/images/aztec.png";
import PlainMessage, { subMessagesMap } from "../message/PlainMessage";
import { MetricsPublisher } from "../../../metrics/metricsPublisher";

export enum BarcodeImage {
  BARCODE,
  PACKAGE_BARCODE,
  QR_BARCODE,
  AZTEC_BARCODE,
  NONE,
}

export interface ContainerInfoProps {
  containerAttribute1: string;
  containerAttribute2: string;
}

export interface ScanReaderProps {
  header?: string;

  message?: string;

  disableRead?: boolean;

  subMessages?: subMessagesMap[];

  ContainerInfo?: ContainerInfoProps;

  barcodeImage?: BarcodeImage;

  callback: (scannable: string) => void;
  /** Optional metrics publisher, if provided an event will be emitted on every scan. */
  metricsPublisher?: MetricsPublisher;
  helpPageLink?: string
  imageMaxWidth?: string
}

export class ScanReader extends Component<ScanReaderProps> {
  protected readonly inputRef: React.RefObject<HTMLInputElement>;
  protected timeoutHandler: NodeJS.Timeout | undefined;
  protected inputTimeoutHandler: NodeJS.Timeout | undefined;

  constructor(props: ScanReaderProps) {
    super(props);
    this.inputRef = React.createRef();
    this.onKeyupHandler = this.onKeyupHandler.bind(this);
    this.setFocusOnInput = this.setFocusOnInput.bind(this);
  }

  onKeyupHandler = (): void => {
    if (this.inputRef.current) {
      const input = this.inputRef.current.value.trim();
      if (this.inputTimeoutHandler) {
        clearTimeout(this.inputTimeoutHandler);
      }
      this.inputTimeoutHandler = setTimeout(() => {
        if (input.length > 1) {
          if (!this.props.disableRead) {
            this.props.metricsPublisher?.emitMetrics({
              name: "BARCODE_SCAN_TIMESTAMP_MS",
              value: Date.now(),
              context: {
                barcode: input,
              },
            });
            this.props.callback(input);
          }
          if (this.inputRef.current) {
            this.inputRef.current.value = "";
          }
        }
      }, 100);
    }
  };

  //forcefully takes back the focus to the input element to receive scanned data
  setFocusOnInput() {
    //for firefox adding setTimeout to set the focus
    this.timeoutHandler = setTimeout(() => {
      this.inputRef.current?.focus();
    }, 10);
  }

  protected getBarcodeImage = (): string => {
    switch (this.props.barcodeImage) {
      case BarcodeImage.PACKAGE_BARCODE: {
        return packageBarcode;
      }
      case BarcodeImage.QR_BARCODE: {
        return qrBarcode;
      }
      case BarcodeImage.AZTEC_BARCODE: {
        return aztecBarcode;
      }
      default: {
        return barcode;
      }
    }
  };

  render() {
    console.log("image = " + this.props.barcodeImage)
    return (
      <div>
        <PlainMessage
          title={this.props.header}
          message={this.props.message}
          subMessages={this.props.subMessages}
        />
        <input
          onBlur={this.setFocusOnInput}
          // style={{ position: "absolute", top: "-1000px" }}
          onKeyUp={this.onKeyupHandler}
          ref={this.inputRef}
          autoFocus
        />
        {this.props.ContainerInfo && (
          <Box spacingInset="medium">
            <Text alignment="left" type="b200">
              {this.props.ContainerInfo?.containerAttribute1}
            </Text>
            <Text alignment="left" type="b200">
              {this.props.ContainerInfo?.containerAttribute2}
            </Text>
          </Box>
        )}
        {this.props.barcodeImage !== BarcodeImage.NONE && (
          <Box spacingInset="small">
            <img
              src={this.getBarcodeImage()}
              style={{ display: "block", margin: "auto" }}
              alt={"barcode_with_laser"}
            />
          </Box>
        )}
      </div>
    );
  }
}
