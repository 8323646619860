/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import Alert from "@amzn/meridian/alert";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";
import Modal from "@amzn/meridian/modal";
import Text from "@amzn/meridian/text";
import Theme from "@amzn/meridian/theme";
import { Markup } from "interweave";
import AtBottomAfterDivider from "../AtBottomAfterDivider";
import CallToAction from "../callToAction/CallToAction";
import SoundProvider, { SoundType } from "../soundProvider/SoundProvider";
import Column from "@amzn/meridian/column";

export type ModalDialogAlertType = "error" | "warning";

export interface ModalDialogProps {
  displayStatus: boolean;
  headerTitle: string;
  alertType: ModalDialogAlertType;
  alertTitle: string;
  message: string;
  localizedSubmessages?: string[];
  primaryActionMessage: string;
  primaryButtonDisabled?: boolean;
  secondaryActionMessage?: string;
  /** Plays a sound if a soundType is provided. */
  soundType?: SoundType;
  onClickAction: (optionNumber: number) => void;
}

function ModalDialog(props: ModalDialogProps) {
  const secondaryActionMsg = props.secondaryActionMessage ?? undefined;
  const headerBackgroundColor =
    props.alertType === "error" ? "#CC0C39" : "#FCDE4D";
  const headerForegroundColor =
    props.alertType === "error" ? "#FFFFFF" : "#232F3E";
  const subMessages = props.localizedSubmessages
    ? props.localizedSubmessages?.map((submessage, index) => (
        <div style={{ display: "flex" }}>
          <Box className="title" spacingInset="medium" key={index}>
            <Text alignment="left" type="b500" key={submessage}>
              {submessage}
            </Text>
          </Box>
        </div>
      ))
    : undefined;

  return (
    /*
    Overriding the default theme for model header background color,
     as meridian doesn't have an option to change the default background color: black
     */
    <Theme
      tokens={{
        alertBackgroundColorLargeError: headerBackgroundColor,
        alertBackgroundColorLargeWarning: headerBackgroundColor,
        alertForegroundColorLargeDefault: headerForegroundColor,
      }}
    >
      <Modal
        open={props.displayStatus}
        scrollContainer="viewport"
        bodySpacingInset="none"
      >
        <Column height={"100%"} heights={["fill", "fit"]}>
          <Box>
            <Alert
              type={props.alertType}
              size="large"
              title={props.headerTitle}
              children={undefined}
            />
            <Box spacingInset="medium">
              <Heading alignment={"center"} level={1} type={"h400"}>
                {props.alertTitle}
              </Heading>
              <Text alignment="left" type="b400">
                <Markup content={props.message} />
              </Text>
            </Box>
            {subMessages}

            {
              /** Plays sound when the Modal Dialog is visible and if sound type is provided. */
              props.displayStatus && props.soundType !== undefined && (
                <SoundProvider soundType={props.soundType} isVibration={true} />
              )
            }
          </Box>
          <AtBottomAfterDivider>
            <CallToAction
              primaryButtonLabel={props.primaryActionMessage}
              primaryButtonDisabled={props.primaryButtonDisabled}
              secondaryButtonLabel={secondaryActionMsg}
              onClick={props.onClickAction}
            />
          </AtBottomAfterDivider>
        </Column>
      </Modal>
    </Theme>
  );
}

export default ModalDialog;
