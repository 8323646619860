import React, {useState} from "react"
import AppLayout from "@amzn/meridian/app-layout"
import Masthead from "@amzn/meridian/masthead"
import SideMenu from "@amzn/meridian/side-menu"
import AppHeader from "./AppHeader"
import AppMainContent from "./AppMainContent"
import styles from "./PageLayout.module.css"
import {AppSideMenu} from "./AppSideMenu"
import { OnClickActionProps } from "../common/list/ListActions";

export const AppHeaderContext = React.createContext<{
    setShowMenuButton: (newStatus: boolean) => void;
    setTitle: (newTitle: string) => void;
    setShowMoreOptionsButton: (newStatus: boolean) => void;
    setMoreOptionsList: (newOptionsList: Array<OnClickActionProps>) => void;
}>({
    setShowMenuButton: () => alert("setShowMenuButton action not set"),
    setTitle: () => alert("setTitle action not set"),
    setShowMoreOptionsButton: () =>
        alert("setShowMoreOptionsButton action not set"),
    setMoreOptionsList: () => alert("setMoreOptionsList action not set"),
});

function PageLayout() {
    const [showMenuButton, setShowMenuButton] = useState(true)
    const [sideMenuOpenStatus, setSideMenuOpenStatus] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showMoreOptionsButton, setShowMoreOptionsButton] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [appHeaderTitle, setAppHeaderTitle] = useState("SAM");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [moreOptionsOpenStatus, setMoreOptionsOpenStatus] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [moreOptionsList, setMoreOptionsList] = useState<
        Array<OnClickActionProps>
        >([]);

    function getAppHeaderContext() {
        return {
            setShowMenuButton: (newStatus: boolean) => setShowMenuButton(newStatus),
            setTitle: (newTitle: string) => setAppHeaderTitle(newTitle),
            setShowMoreOptionsButton: (newStatus: boolean) =>
                setShowMoreOptionsButton(newStatus),
            setMoreOptionsList: (newOptionsList: Array<OnClickActionProps>) =>
                setMoreOptionsList(newOptionsList),
        };
    }

    return (
        <AppHeaderContext.Provider value={getAppHeaderContext()}>
            <AppLayout
                headerComponent={Masthead}
                sidebarComponent={SideMenu}
                backgroundColor={"primary"}
                mainClassName={styles["layout"]}
            >
                <AppHeader
                    showMenuButton={showMenuButton}
                    openMenu={() => setSideMenuOpenStatus(true)}
                    title={"Process Tech Labs"}
                />
                <AppSideMenu
                    openStatus={sideMenuOpenStatus}
                    closeMenu={() => setSideMenuOpenStatus(false)}
                />
                <div className={styles["content"]}>
                    <AppMainContent/>
                </div>
            </AppLayout>
        </AppHeaderContext.Provider>
    )
}

export default PageLayout
