import Box from "@amzn/meridian/box"
import Column from "@amzn/meridian/column"
import Heading from "@amzn/meridian/heading"
import {SimpleScanReader} from "../common/scans/SimpleScanReader"
import Row from "@amzn/meridian/row"
import {ScanIdentifierUtil} from "../util/ScanIdentifierUtil"
import Text from "@amzn/meridian/text"
import React, {useState} from "react"
import {CptChasingRepo} from "../../repo/cptChasingRepo"
import Button from "@amzn/meridian/button"

export interface CptDemoProps {
    cptChasingRepo: CptChasingRepo,
}

enum DeleteClickState {
    NOT_CLICKED = "Clear All Existing CPT Carts",
    PENDING_CONFIRM = "Click again to confirm delete"
}

export function CptCartStorage(props: CptDemoProps) {
    const [invalidScan, setInvalidScan] = useState<boolean>(false)
    const [saveSuccess, setSaveSuccess] = useState<boolean | undefined>(undefined)
    const [clearSuccess, setClearSuccess] = useState<boolean | undefined>(undefined)
    const [deleteClickState, setDeleteClickState] = useState(DeleteClickState.NOT_CLICKED)

    const onScan = async (scan: string) => {
        setSaveSuccess(undefined)
        const scanType = ScanIdentifierUtil.getScanType(scan)

        if (!ScanIdentifierUtil.containerScanTypes.has(scanType)) {
            setInvalidScan(true)
            return
        }
        try {
            setInvalidScan(false)
            await props.cptChasingRepo.addCartToCpt(scan)
            setSaveSuccess(true)
        } catch (e) {
            setSaveSuccess(false)
        }
    }
    const onClear = async () => {
        console.log('OnClear')
        if (deleteClickState === DeleteClickState.NOT_CLICKED) {
            setDeleteClickState(DeleteClickState.PENDING_CONFIRM)
            return
        }
        try {
            await props.cptChasingRepo.clearCptCarts()
            setClearSuccess(true)
        } catch (e) {
            console.log("FAILED TO CLEAR CPT CARTS", e)
            setClearSuccess(false)
        }
        setTimeout(setClearSuccess, 3000, undefined)
        setDeleteClickState(DeleteClickState.NOT_CLICKED)
    }
    const buttonType = (deleteClickState === DeleteClickState.NOT_CLICKED) ? "secondary" : "primary"
    return (
        <>
            <Box spacingInset={"medium"}>
                <Column alignmentHorizontal={"center"}>
                    <Heading level={2}>CPT Cart Select Tool</Heading>
                    <SimpleScanReader
                        message={"Scan the Carts"}
                        callback={onScan}
                    />
                    {saveSuccess !== undefined && <Row spacingInset={"small"} alignmentHorizontal={"center"}>
                        <Text type={"h300"} color={saveSuccess ? "primary" : "error"}>
                            {saveSuccess ? 'Successfully saved' : 'Failed to save'}
                        </Text>
                    </Row>}
                    {clearSuccess !== undefined && <Row spacingInset={"small"} alignmentHorizontal={"center"}>
                        <Text type={"h300"} color={clearSuccess ? "primary" : "error"}>
                            {clearSuccess ? 'Successfully cleared all CPT Carts' : 'Failed to clear CPT Carts'}
                        </Text>
                    </Row>}
                    {invalidScan && <Row spacingInset={"small"} alignmentHorizontal={"center"}>
                        <Text type={"h300"} color={"error"}>
                            Invalid barcode Scan the Tag Id
                        </Text>
                    </Row>}
                    <Button onClick={onClear} type={buttonType}>
                        {deleteClickState}
                    </Button>
                </Column>
            </Box>
        </>
    )
}
