/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { GetNodeConfigDocument } from "../../../graphql.generated";

export const GetNodeConfigMock = [
  {
    request: {
      query: GetNodeConfigDocument,
    },
    newData: () => ({
      data: {
        getNodeConfig: {
          nodeConfigurations:
            "{" +
            '"cmmUI":{' +
            '"hideLeaveContainerButtonEnabled":true,' +
            '"mergeContainerEnabled":true,' +
            '"displayScanRateEnabled":true,' +
            '"workflowOptimizationEnabled":true,' +
            '"createContainerUsingCmasEnabled":true,' +
            '"wrongBuildingSidelinePackageEnabled":true,' +
            '"isFlowModeEnabledForContainerBuilder":true' +
            "}," +
            '"containerSpecs":{' +
            '"PALLET":{' +
            '"isManualCreationAllowed":true,' +
            '"numberOfLabelsToPrint":null,' +
            '"weight":null,' +
            '"closeWorkflowEnabled":false,' +
            '"auditRequiredOnClose":true,' +
            '"contentAuditRate":null,' +
            '"maxChildLimit":null},' +
            '"GAYLORD":{' +
            '"isManualCreationAllowed":true,' +
            '"numberOfLabelsToPrint":null,' +
            '"weight":null,' +
            '"closeWorkflowEnabled":false,' +
            '"auditRequiredOnClose":null,' +
            '"contentAuditRate":null,' +
            '"maxChildLimit":null},' +
            '"BAG":{' +
            '"isManualCreationAllowed":true,' +
            '"numberOfLabelsToPrint":null,' +
            '"weight":null,' +
            '"closeWorkflowEnabled":false,' +
            '"auditRequiredOnClose":null,' +
            '"contentAuditRate":null,' +
            '"maxChildLimit":null}}}',
        },
      },
    }),
  },
];
