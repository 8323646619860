/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React from "react";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";

export interface TaskSelectProps {
  /** Localized task title and description should be passed in by parent component **/
  localizedTaskTitle: string;
  localizedTaskDescription: string;
  onClickAction: (optionNumber: number) => void;
}

function TaskSelect(props: TaskSelectProps) {
  return (
    <Box height="100%" width="100%" spacingInset="xsmall" type="outline">
      <Column
        height="100%"
        width="100%"
        heights={["fit", "fill", "fit"]}
        spacing="medium"
      >
        <Text type="h200">{props.localizedTaskTitle}</Text>
        <div style={{ overflowY: "auto" }}>
          <Text>{props.localizedTaskDescription}</Text>
        </div>
        <Button
          type="tertiary"
          size="small"
          minWidth="100%"
          onClick={props.onClickAction}
        >
          {props.localizedTaskTitle}
        </Button>
      </Column>
    </Box>
  );
}

export default TaskSelect;
