import React, { useCallback, useState } from "react"
import Column from "@amzn/meridian/column"
import ProgressTracker, { ProgressTrackerStep } from "@amzn/meridian/progress-tracker"
import Text from "@amzn/meridian/text"
import Button from "@amzn/meridian/button"
import Row from "@amzn/meridian/row"
import rabBeaconImage from "../../media/images/rabBeacon.png";
import rabOnCartImage from "../../media/images/rabOnCart.png";
import cartBarcodeImage from "../../media/images/cartBarcode.png";
import wrongCartBarcodeImage from "../../media/images/wrongCartBarcode.png";
import rabBeaconBarcodeImage from "../../media/images/rabBarcode.png";
import oldBeaconBarcodeImage from "../../media/images/oldBeacon.png";
import arrowRightLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-right-large"
import Icon from "@amzn/meridian/icon"
import arrowLeftLargeTokens from "@amzn/meridian-tokens/base/icon/arrow-left-large"
import thumbsUpTokens from "@amzn/meridian-tokens/base/icon/thumbs-up"

const tutorialTabs : { [key: string]: number } = {
    "beacon" : 1,
    "beaconOnCart" : 2,
    "cartBarcode" : 3,
    "beaconBarcode" : 4
}

interface HelpModalComponentProps {
    closeModal: () => void,
    setCookie: (name: "tutorialShown", value: any, options?: { path: string; maxAge: number }) => void
}

export function HelpModalComponent(props: HelpModalComponentProps) {
    const [currentTab, setCurrentTab] = useState("beacon")

    const generateProgressType = useCallback((thisTab: string): "present" | "future" | "past" => {
        if (tutorialTabs[currentTab] > tutorialTabs[thisTab]) return "past"
        if (tutorialTabs[currentTab] < tutorialTabs[thisTab]) return "future"
        return "present"
    }, [currentTab]);

    function onStartScanning(){
        props.setCookie('tutorialShown', 'true', { path: '/', maxAge: 24 * 60 * 60 }); // Set cookie with 1 days expiration
        props.closeModal()
    }

    return (
        <Column alignmentHorizontal="center" height={"100%"} >
            <ProgressTracker
                direction="row"
            >
                <ProgressTrackerStep type={generateProgressType("beacon")} label="Beacon" showLabel={false}/>
                <ProgressTrackerStep type={generateProgressType("beaconOnCart")} label="Beacon On Cart" showLabel={false} />
                <ProgressTrackerStep type={generateProgressType("cartBarcode")} label="Cart Barcode" showLabel={false} />
                <ProgressTrackerStep type={generateProgressType("beaconBarcode")} label="Beacon Barcode" showLabel={false} />
            </ProgressTracker>
            {currentTab === "beacon" && (
                <Column spacingInset={"400"} spacing={"500"} height={"100%"} alignmentHorizontal="center" heights={["fit", "fill", "fit"]}  >
                    <Text type={"h600"}>RAB Beacon</Text>
                    <img
                        src={rabOnCartImage}
                        style={{ margin: "auto", maxWidth: '60%'}}
                        alt={"RAB beacon"}
                    />
                    <Button size={"large"} onClick={() => setCurrentTab("beaconOnCart")} >Continue <Icon tokens={arrowRightLargeTokens} /></Button>
                </Column>
            )}
            {currentTab === "beaconOnCart" && (
                <Column spacingInset={"400"} spacing={"500"} height={"100%"} alignmentHorizontal="center" heights={["fit", "fill", "fit"]}  >
                    <Text type={"h600"} >RAB Beacon On Cart</Text>
                    <img
                        src={rabBeaconImage}
                        style={{ maxWidth: '80%' }}
                        alt={"RAB beacon attached to a cart"}
                    />
                    <Row>
                        <Button size={"large"} onClick={() => setCurrentTab("beacon")} ><Icon tokens={arrowLeftLargeTokens} /> Back</Button>
                        <Button size={"large"} onClick={() => setCurrentTab("cartBarcode")} >Continue <Icon tokens={arrowRightLargeTokens} /></Button>
                    </Row>
                </Column>
            )}
            {currentTab === "cartBarcode" && (
                <Column spacingInset={"400"} spacing={"500"} height={"100%"} alignmentHorizontal="center" heights={["fit", "fill", "fit"]}  >
                    <Text type={"h600"} >GoCart BARCODE</Text>
                    <img
                        src={cartBarcodeImage}
                        style={{ maxWidth: '50%', border: "solid 10px #22DD22"}}
                        alt={"Correct GoCart barcode"}
                    />
                    <div
                        style={{
                            position: "relative",
                            display: "inline-block",
                            maxWidth: '40%',
                        }}
                    >
                        <img
                            src={wrongCartBarcodeImage}
                            style={{ maxWidth: '100%', height: "auto" }}
                            alt={"Wrong GoCart barcode"}
                        />
                        <RedX />
                    </div>
                    <Row>
                        <Button size={"large"} onClick={() => setCurrentTab("beaconOnCart")} ><Icon tokens={arrowLeftLargeTokens} /> Back</Button>
                        <Button size={"large"} onClick={() => setCurrentTab("beaconBarcode")} >Continue <Icon tokens={arrowRightLargeTokens} /></Button>
                    </Row>
                </Column>
            )}
            {currentTab === "beaconBarcode" && (
                <Column spacingInset={"400"} spacing={"500"} height={"100%"} alignmentHorizontal="center" heights={["fit", "fill", "fit"]}  >
                    <Text type={"h600"} >Beacon BARCODE</Text>
                    <img
                        src={rabBeaconBarcodeImage}
                        style={{ maxWidth: '60%', border: "solid 10px #22DD22"}}
                        alt={"Correct cart barcode"}
                    />
                    <div
                        style={{
                            position: "relative",
                            display: "inline-block",
                            maxWidth: '40%',
                        }}
                    >
                        <img
                            src={oldBeaconBarcodeImage}
                            style={{ maxWidth: '100%', height: "auto" }}
                            alt={"Wrong cart barcode"}
                        />
                        <RedX />
                    </div>
                    <Row>
                        <Button size={"large"} onClick={() => setCurrentTab("cartBarcode")} ><Icon tokens={arrowLeftLargeTokens} /> Back</Button>
                        <Button size={"large"} onClick={onStartScanning} >Start Scannning! <Icon tokens={thumbsUpTokens} /></Button>
                    </Row>
                </Column>
            )}
        </Column>
    )
}

function RedX(){
    return (
        <div
            style={{
                position: "absolute",
                bottom: "-50%",
                left: "-50%",
                width: "100%",
                height: "100%",
            }}
        >
            <div style={{
                position: "absolute",
                width: "200%",
                height: "3px",
                backgroundColor: "red",
                transform: 'rotate(45deg)'
            }} />
            <div style={{
                position: "absolute",
                width: "200%",
                height: "3px",
                backgroundColor: "red",
                transform: 'rotate(-45deg)'
            }} />
        </div>
    )
}