/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import React from "react";
import Box from "@amzn/meridian/box";
import Loader from "@amzn/meridian/loader";
import Text from "@amzn/meridian/text";
import { TextType } from "@amzn/meridian/text/text";
import { LoaderSize } from "@amzn/meridian/loader/loader";

export interface ProgressMeterProps {
  label: string;
  percentage: number;
  labelTextType?: TextType;
  loaderSize?: LoaderSize;
}

function ProgressMeter(props: ProgressMeterProps) {
  return (
    <Box height="100%" width="100%">
      <Text alignment="left" type={props.labelTextType}>
        {props.label}
      </Text>
      <Loader
        type="linear"
        size={props.loaderSize}
        percentage={Math.round(props.percentage)}
      />
    </Box>
  );
}

export default ProgressMeter;
