import React, {Component} from "react"
import PageLayout from "../layout/PageLayout"

export class PrimaryServiceProvider extends Component {
    render() {
        return (
            <PageLayout/>
        )
    }
}
