import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {useState} from "react";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import {SimpleScanReader} from "../common/scans/SimpleScanReader";
import {BarcodeImage} from "../common/scans/ScanReader";

export const RESOURCE_ID_REGEX = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

export interface StackingAreaScannerProps {
    onSuccessCallback: (id: string) => void
    goBackCallback:() => void
}

export function StackingAreaScanner(props: StackingAreaScannerProps) {
    const [invalidScan, setInvalidScan] = useState<boolean>(false);
    const onScan = (scan: string) => {
        if(!isValidStackingArea(scan)) {
            setInvalidScan(true);
            return;
        }
        props.onSuccessCallback(scan)
    }
    return (<Column height="100%" heights={["fill", "fit"]}>
        <Box spacingInset="medium">
            <SimpleScanReader
                header={"Scan the Stacking Area"}
                message={''}
                callback={onScan}
                barcodeImage={BarcodeImage.AZTEC_BARCODE}
            />
            <Row spacingInset={"small"} alignmentHorizontal={"center"}>
                {invalidScan && invalidScanBox()}
            </Row>
        </Box>
        <Box spacingInset={"small"}>
            <Button size="large" minWidth="100%" onClick={() => props.goBackCallback()}>
                Main Menu
            </Button>
        </Box>
    </Column>
    )
}

function invalidScanBox() {
    return (
        <Box>
            <Text type={"h300"} color={"error"}>
                Invalid Stacking Area code. Please Scan the QR Code of any Stacking Area in the Spur.
            </Text>
        </Box>
    )
}

function isValidStackingArea(cartId: string):boolean{
    return RESOURCE_ID_REGEX.test(cartId);
}
