export const CartStateDropdown = [
    {label: "Empty/Available", value: "AVAILABLE" },
    {label: "WIP", value: "IN_USE" },
    {label: "Damaged/Red Tagged", value: "BROKEN" },
    //OTHER would be converted to AVAILABLE onSubmit, cannot set it available here
    //as the dropdown does not support duplicate values
    {label: "Storage/Other (Not Broken + Used for Other Purposes)", value: "OTHER" }
]

export enum CartState {
    AVAILABLE,
    IN_USE,
    BROKEN,
    OTHER
}