import Heading from "@amzn/meridian/heading"
import Box from "@amzn/meridian/box"
import Column from "@amzn/meridian/column"
import Button from "@amzn/meridian/button"
import React, {useState} from "react"
import Text from "@amzn/meridian/text"
import {CptChasingRepo} from "../../repo/cptChasingRepo"

export interface CptDemoProps {
    cptChasingRepo: CptChasingRepo
}

export function CptCartChase(props: CptDemoProps) {
    const [cptCarts, setCptCarts] = useState<string[]>([])

    const onLightCartsUp = async () => {
        try {
            const carts = await props.cptChasingRepo.triggerCptCarts()
            setCptCarts(carts)
        } catch (e) {
            console.error(e)
        }
    }

    return <>
        <Box spacingInset={"medium"}>
            <Column alignmentHorizontal={"center"}>
                <Heading level={2}>CPT Chasing</Heading>
                <Button onClick={onLightCartsUp}>
                    Light All Carts near CPT
                </Button>

                {cptCarts.length > 0 &&
                    <>
                        <Text>Found {cptCarts.length} carts</Text>
                        <Text tag="ul">
                            {cptCarts.map((cartId, index) => {
                                return <li key={index}>{cartId}</li>
                            })
                            }
                        </Text>
                    </>
                }
            </Column>
        </Box>
    </>
}