/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { gql } from "@apollo/client/core";

export const MOVE_CONTAINER_MUTATION = gql`
  mutation moveContainer($input: MoveContainerInput!) {
    moveContainer(input: $input) {
      sourceScannable
      destinationScannable
    }
  }
`;
