export class JsonUtil {
    static prettyPrint(input: any): string {
        return JSON.stringify(input, null, 3)
    }

    static convertToBoolean(value: any): boolean {
        if (typeof value === 'boolean') {
            return value;
        } else if (typeof value === 'string') {
            return value.toLowerCase() === 'true';
        } else {
            return Boolean(value);
        }
    }
}