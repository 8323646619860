import Text from "@amzn/meridian/text"
import React, {useEffect, useState} from "react"

import Column from "@amzn/meridian/column"
import DisplayLoader from "../common/loading/DisplayLoader"
import {EdgeMappingRepo} from "../../repo/edgeMappingRepo"
import {ApiCallStatus} from "./ApiCallStatus"
import Box from "@amzn/meridian/box"
import Table, {TableCell, TableRow} from "@amzn/meridian/table"
import {LightControlClientRepo} from "../../repo/lightControlServiceRepo"
import Link from "@amzn/meridian/link"
import {SimpleScanReader} from "../common/scans/SimpleScanReader";
import {ScanIdentifierUtil, ScanType} from "../util/ScanIdentifierUtil";
import {JsonUtil} from "../util/JsonUtil";


export interface EdgeDeviceMappingProps {
    edgeMappingRepo: EdgeMappingRepo,
    lightControlClientRepo: LightControlClientRepo
}

interface ScanStatusTableProps {
    cartId?: string
    tagId?: string
    lightId?: string
}

function getMessageTextBlock(success: boolean, testLightOperationSuccess: boolean, cartId: string | undefined, lightId: string | undefined) {
    let lightMessage
    if (lightId) {
        if (testLightOperationSuccess) {
            lightMessage = <Text alignment="center" type="h100">{cartId} should be blinking green for 5 seconds</Text>
        } else {
            lightMessage = <Text alignment="center" type="h100"
                                 color={"error"}>Auto trigger of light failed, Test the mapping using <Link href={'/testMapping'}>testing page</Link>
            </Text>
        }
    }
    if (success) {
        return (<div>
            <Text alignment="center" type="h500">
                Success!
            </Text>
            <br/>
            {lightMessage}
        </div>)
    }

    return (<div>
        <Text alignment="center" type="h400" color={"error"}>
            Failed to save, try again
        </Text>
        <Text alignment="center" type="h100">
            Try to connect the equipment again. If the problem persists contact your Shift or Area Manager
        </Text>
    </div>);
}

export function EdgeDeviceMapping(props: EdgeDeviceMappingProps) {
    const [invalidScan, setInvalidScan] = useState<boolean>(false)
    const [containerId, setContainerId] = useState<string | undefined>(undefined)
    const [tagId, setTagId] = useState<string | undefined>(undefined)
    const [lightId, setLightId] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false)
    const [mappingResultStatusCode, setMappingResultStatusCode] = useState<number | undefined>(undefined)
    const [testLightOperationSuccess, setTestLightOperationSuccess] = useState<boolean>(false)
    const [debugLog, setDebugLog] = useState<string[]>([])


    function startOver() {
        setContainerId(undefined)
        setTagId(undefined)
        setLightId(undefined)
        setInvalidScan(false)
        setMappingResultStatusCode(undefined)
        setTestLightOperationSuccess(false)
        setDebugLog([])
    }

    const appendDebugLog = (s: string) => {
        debugLog.push(s + "\n")
        setDebugLog(debugLog)
    }

    const onScan = async (scan: string) => {
        const scanType = ScanIdentifierUtil.getScanType(scan)
        if (scanType === ScanType.INVALID) {
            setInvalidScan(true)
            return
        }
        setInvalidScan(false)
        if (ScanIdentifierUtil.containerScanTypes.has(scanType)) {
            setContainerId(scan)
        } else if (scanType === ScanType.TAG) {
            setTagId(scan)
        } else if (scanType === ScanType.LIGHT) {
            setLightId(scan)
        }
    }

    const submitMapping = async () => {
        setLoading(true)
        setDebugLog([])
        try {
            appendDebugLog("submitting mapping to EDMS")
            const response = await props.edgeMappingRepo.createMapping({
                containerId: containerId!,
                tagId: tagId,
                lightId: lightId
            })
            appendDebugLog("create mapping response code: " + response.$metadata.httpStatusCode)
            appendDebugLog("create mapping response: " + JsonUtil.prettyPrint(response))
            setMappingResultStatusCode(response.$metadata.httpStatusCode)

            setTestLightOperationSuccess(response.lightTurnedOn ?? false)
        } catch (e) {
            console.error(e);
            appendDebugLog("ERROR: " + JSON.stringify(e))
            if (!mappingResultStatusCode) {
                appendDebugLog("mapping result unknown, setting to 500")
                setMappingResultStatusCode(500);
            } else {
                appendDebugLog("setting light status = false")
                setTestLightOperationSuccess(false);
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const readyToSubmit: boolean = containerId !== undefined && (tagId !== undefined || lightId !== undefined)
        console.log(`readyToSubmit: ${readyToSubmit}, cartId: ${containerId}, tagId: ${tagId}, lightId: ${lightId}`)
        if (readyToSubmit) {
            // noinspection JSIgnoredPromiseFromCall
            submitMapping()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containerId, tagId, lightId])

    if (loading) {
        return (
            <Column alignmentHorizontal={"center"}
                    alignmentVertical={"center"}
                    minHeight={"100%"}
            >
                <DisplayLoader message={'Saving Mapping'}/>
            </Column>
        )
    }

    if (mappingResultStatusCode) {
        const success = mappingResultStatusCode === 200;
        const message = getMessageTextBlock(success, testLightOperationSuccess, containerId, lightId);
        const debugMessagesForDisplay = success ? [] : debugLog
        return <ApiCallStatus
            success={success}
            message={message}
            debugLog={debugMessagesForDisplay}
            startOverMessage={'Map Next Device'}
            startOverCallback={startOver}
            autoAdvanceOnSuccess={true}
        />
    }


    return (<Column alignmentHorizontal={"center"}>
            <Box spacingInset="medium">
                <SimpleScanReader
                    header={"Start Scanning"}
                    imageMaxWidth={"40%"}
                    callback={onScan}
                />
                {invalidScan && invalidScanBox()}
                <ScanStatusTable cartId={containerId} lightId={lightId} tagId={tagId}/>
            </Box>
        </Column>
    )
}

function ThingRow(props: { id: string | undefined, deviceType: string }) {
    return <TableRow>
        <TableCell>{props.deviceType}</TableCell>
        <TableCell>{props.id ?? "Not Scanned"}</TableCell>
    </TableRow>
}

function ScanStatusTable(props: ScanStatusTableProps) {
    return <Table headerRows={1} rowComponents={[TableRow, ThingRow]}>
        <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Scanned Id</TableCell>
        </TableRow>
        <ThingRow id={props.cartId} deviceType={'Cart/Shuttle'}/>
        <ThingRow id={props.tagId} deviceType={'UWB Tag'}/>
        <ThingRow id={props.lightId} deviceType={'Light'}/>
    </Table>
}

function invalidScanBox() {
    return (
        <Box>
            <Text type={"h200"} color={"error"}>
                Invalid scan
            </Text>
        </Box>
    )
}
