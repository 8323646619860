/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import Box from "@amzn/meridian/box";
import React from "react";
import { useTranslation } from "react-i18next";
import { BarcodeImage, ScanReader } from "../../common/scans/ScanReader";
import { ContainerInfoHeader } from "../../functions/move/Move";
import useMoveContainer from "../../functions/move/MoveContainer";
import Workflow, {
  MoreOptionRefProps,
  Task as WorkflowTask,
} from "../Workflow";

const TRANSLATION_NS =
  "components.workflows.subworkflows.ProblemContainerWorkflow";

export enum Task {
  ProblemSolve,
  Hazmat,
}

interface ProblemContainerWorkflowProps {
  problemType: Task;
  localizedBannerTitle?: string;
  sourceScannable: string;
  sourceContainerType?: string;
  moreOptionsList: () => Array<MoreOptionRefProps<Task>>;
  onSuccess: (sourceScannable: string, destinationScannable: string) => void;
  onFailure: (errorType: string, message: string) => void;
}

/**
 * Scan and move component that takes a source scannable and the user input for
 * the destination scannable, then executes move container from source to destination.
 * @param props inputs to this function
 * @returns a scan reader to get user input and executes move container
 */
const ScanContainer = (props: {
  localizedHeader: string;
  localizedMessage: string;
  sourceScannable: string;
  isForceMove: boolean;
  containerInfoHeader?: ContainerInfoHeader;
  barcodeImage?: BarcodeImage;
  onSuccess: (sourceScannable: string, destinationScannable: string) => void;
  onFailure: (errorType: string, message: string) => void;
}) => {
  const moveContainer = useMoveContainer();

  return (
    <Box className="message" spacingInset="xxsmall">
      <ScanReader
        header={props.localizedHeader}
        message={props.localizedMessage}
        subMessages={[]}
        barcodeImage={props.barcodeImage}
        callback={(destinationScannable: string) => {
          moveContainer({
            sourceScannable: props.sourceScannable,
            destinationScannable: destinationScannable,
            isForceMove: props.isForceMove,
            onMoveSuccess: (
              sourceScannable: string,
              destinationScannable: string
            ) => {
              props.onSuccess(sourceScannable, destinationScannable);
            },
            onMoveFailure: (errorType, message) => {
              props.onFailure(errorType, message);
            },
          });
        }}
      />
    </Box>
  );
};

export const ProblemContainerWorkflow = (
  props: ProblemContainerWorkflowProps
) => {
  const { t } = useTranslation(TRANSLATION_NS);

  const createMoreOptionsList = (
    parentMoreOptionsList: () => Array<MoreOptionRefProps<Task>>
  ) => {
    const moreOptionList = parentMoreOptionsList();
    return () => {
      return moreOptionList;
    };
  };

  return (
    <React.Fragment>
      <Workflow
        title={
          props.localizedBannerTitle ??
          t("problem-container-workflow-banner-title")
        }
        currentTaskId={props.problemType}
        moreOptionsCallback={createMoreOptionsList(props.moreOptionsList)}
        updateCurrentTaskId={() => {}}
      >
        <WorkflowTask taskId={Task.ProblemSolve}>
          <ScanContainer
            localizedHeader={t("problem-solve-workflow-step-title")}
            localizedMessage={t("problem-solve-workflow-step-message", {
              containerType: props.sourceContainerType,
            })}
            sourceScannable={props.sourceScannable}
            isForceMove={false}
            barcodeImage={BarcodeImage.BARCODE}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string
            ) => {
              props.onSuccess(props.sourceScannable, destinationScannable);
            }}
            onFailure={props.onFailure}
          />
        </WorkflowTask>

        <WorkflowTask taskId={Task.Hazmat}>
          <ScanContainer
            localizedHeader={t("hazmat-workflow-step-title")}
            localizedMessage={t("hazmat-workflow-step-message")}
            sourceScannable={props.sourceScannable}
            isForceMove={true}
            barcodeImage={BarcodeImage.BARCODE}
            onSuccess={(
              sourceScannable: string,
              destinationScannable: string
            ) => {
              props.onSuccess(props.sourceScannable, destinationScannable);
            }}
            onFailure={props.onFailure}
          />
        </WorkflowTask>
      </Workflow>
    </React.Fragment>
  );
};

export default ProblemContainerWorkflow;
