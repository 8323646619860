import React, {useMemo, useState} from "react"
import { useLocation } from "react-router"
import { Cookies } from "react-cookie"
import { URL_HOME } from "./layout/AppMainContent"
import { PrimaryServiceProvider } from "./dataProvider/PrimaryServiceProvider"
import {Configuration, getConfiguration} from "../configuration/configuration"
import {FCMENU_SENSITIVE_COOKIE_NAME, FCMenuDecoder} from "../environment/fcMenu"
import { RoleLevel, RoleName } from "../graphql.generated"
import { FetchPolicy } from "@apollo/client"
import {EdgeMappingRepo} from "../repo/edgeMappingRepo"
import {LightControlClientRepo} from "../repo/lightControlServiceRepo"
import {CredentialRepo} from "../repo/credentialRepo"
import {DynamicContainerizationRepo} from "../repo/dynamicContainerizationRepo"
import {CptChasingRepo} from "../repo/cptChasingRepo"
import {GoCartServiceRepo} from "../repo/goCartServiceRepo";
import {HeiseServiceRepo} from "../repo/heiseServiceRepo";

const cookies = new Cookies()

interface IAppContext {
    trainingModeStatus: boolean
    toggleTrainingMode: () => void
    configuration: Configuration
    fetchPolicy: FetchPolicy,
    lcsClientRepo?: LightControlClientRepo,
    edgeMappingRepo?: EdgeMappingRepo,
    dynamicContainerizationRepo?: DynamicContainerizationRepo,
    cptChasingRepo? : CptChasingRepo,
    goCartServiceRepo?: GoCartServiceRepo
    heiseServiceRepo?: HeiseServiceRepo
}

export const AppContext = React.createContext<IAppContext>({
    trainingModeStatus: false,
    toggleTrainingMode: () => alert("toggleTrainingMode action not set"),
    configuration: getConfiguration(),
    fetchPolicy: "cache-first",
    lcsClientRepo: undefined,
    edgeMappingRepo: undefined,
    dynamicContainerizationRepo: undefined,
    cptChasingRepo: undefined,
    goCartServiceRepo: undefined,
    heiseServiceRepo: undefined,
})

export const RoleContext = React.createContext<{
    currentRole?: RoleName
    currentRoleLevel?: RoleLevel
    currentRoleUrl?: string
    setCurrentRole: (newRole: RoleName, newRoleLevel: RoleLevel, newRoleUrl: string) => void
}>({
    currentRole: undefined,
    currentRoleLevel: undefined,
    currentRoleUrl: URL_HOME,
    setCurrentRole: (newRole: RoleName, newRoleLevel: RoleLevel, newRoleUrl: string) =>
        alert("setCurrentRole action not set"),
})

export const PrinterNameContext = React.createContext({
    printerName: undefined as string | undefined,
    setPrinterName: (_: string | undefined) => {},
})

function App() {
    const [trainingMode, setTrainingMode] = useState(false)
    const [role, setRole] = useState<RoleName | undefined>(undefined)
    const [roleLevel, setRoleLevel] = useState<RoleLevel | undefined>(undefined)
    const [roleUrl, setRoleUrl] = useState<string>("/")
    const [printerName, setPrinterName] = useState<string | undefined>(
        undefined
    )
    const params = new URLSearchParams(useLocation().search)

    function getRoleContext() {
        return {
            currentRole: role,
            currentRoleLevel: roleLevel,
            currentRoleUrl: roleUrl,
            setCurrentRole: (newRole: RoleName, newRoleLevel: RoleLevel, newRoleUrl: string): void => {
                setRole(newRole)
                setRoleLevel(newRoleLevel)
                setRoleUrl(newRoleUrl)
            },
        }
    }

    function setURLParamInCookie(paramKey: string) {
        const paramVal = params.get(paramKey)
        if (paramVal) {
            cookies.set(paramKey, paramVal, {
                path: "/",
                sameSite: "strict",
            })
        }
        FCMenuDecoder.redirectOnInvalidToken() //if the cookie doesn't exist or isn't valid, redirect
    }

    const [edgeMappingRepo, lightControlClientRepo, dynamicContainerizationRepo, cptChasingRepo, goCartServiceRepo, heiseServiceRepo] = useMemo<[EdgeMappingRepo, LightControlClientRepo, DynamicContainerizationRepo, CptChasingRepo, GoCartServiceRepo, HeiseServiceRepo]>(() => {
        console.log("AppMainContent credentialsRepo useMemo")
        const credentialsRepo = new CredentialRepo()
        const configuration = getConfiguration()
        const edgeMappingRepo = new EdgeMappingRepo(credentialsRepo, configuration)
        const lightControlClientRepo = new LightControlClientRepo(credentialsRepo, configuration)
        const dynamicContainerizationRepo = new DynamicContainerizationRepo(credentialsRepo, configuration)
        const cptChasingRepo = new CptChasingRepo(credentialsRepo, configuration)
        const goCartServiceRepo = new GoCartServiceRepo(credentialsRepo, configuration)
        const heiseServiceRepo = new HeiseServiceRepo(credentialsRepo, configuration)
        return [edgeMappingRepo, lightControlClientRepo, dynamicContainerizationRepo, cptChasingRepo, goCartServiceRepo, heiseServiceRepo]
    }, [])

    setURLParamInCookie(FCMENU_SENSITIVE_COOKIE_NAME)

    if (window.location.hostname.startsWith("fcmenu")) {
        //a redirect was issued, don't render anything else
        return (<></>)
    }

    function getAppContext(): IAppContext {
        return {
            trainingModeStatus: trainingMode,
            toggleTrainingMode: () => setTrainingMode(!trainingMode),
            configuration: getConfiguration(),
            fetchPolicy: "cache-first",
            lcsClientRepo: lightControlClientRepo,
            edgeMappingRepo: edgeMappingRepo,
            dynamicContainerizationRepo: dynamicContainerizationRepo,
            cptChasingRepo: cptChasingRepo,
            goCartServiceRepo: goCartServiceRepo,
            heiseServiceRepo: heiseServiceRepo,
        }
    }

    return (
        <AppContext.Provider value={getAppContext()}>
            <RoleContext.Provider value={getRoleContext()}>
                <PrinterNameContext.Provider
                    value={{ printerName, setPrinterName }}
                >
                    <PrimaryServiceProvider/>
                </PrinterNameContext.Provider>
            </RoleContext.Provider>
        </AppContext.Provider>
    )
}

export default App
