/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import ModalDialog from "../../common/modal/ModalDialog";
import { BarcodeImage, ScanReader } from "../../common/scans/ScanReader";
import { subMessagesMap } from "../../common/message/PlainMessage";
import Alert from "@amzn/meridian/alert";
import GetDestinationsForContainer from "../getDestinationsForContainer/GetDestinationsForContainer";
import {
  forceMovableExceptionMap,
  otherMoveExceptionMap,
  moveToSameParentException,
  exceptionObject,
  alertType,
} from "./MoveConstants";
import { MOVE_CONTAINER_MUTATION } from "./Move.gql";
import FreezeScreen from "../../common/loading/FreezeScreen";
import Text from "@amzn/meridian/text";
import Box from "@amzn/meridian/box";
import Heading from "@amzn/meridian/heading";

const TRANSLATION_NS = "components.functions.move.Move";
const DEFAULT_PAGE_SIZE = 3;
const DEFAULT_PAGE_NUMBER = 0;

export type MoveInternalState =
  | "initial"
  | "scanDestinationByVsm"
  | "moreDestinations"
  | "isForceMovable"
  | "isForceMoved"
  | "noAdditionalActions"
  | "scanDestinationWithContainerUnloaderWorkflow";

export interface MoveContainerProps {
  sourceScannable: string;
  destinationScannable: string;
  secondaryActionMessage: string;
  isForceMove: boolean;
  isDirectMove: boolean;
  setAdditionalOptions: React.Dispatch<React.SetStateAction<MoveInternalState>>;
  onForceMovable: (
    header: string,
    message: string,
    alertType: alertType
  ) => void;
  onClick: (optionNumber: number) => void;
  onSuccess: (destinationScannable: string) => void;
}

export function MoveContainer(props: MoveContainerProps) {
  const { t } = useTranslation(TRANSLATION_NS);
  const [isShowModalDialog, setIsShowModalDialog] = useState(false);

  const [message, setMessage] = useState<string>(t("move-issue-message"));
  const [header, setHeader] = useState<string>(t("move-issue-header"));
  const [alertType, setAlertType] = useState<alertType>("error");

  const [moveContainer, { loading }] = useMutation(MOVE_CONTAINER_MUTATION, {
    variables: {
      input: {
        sourceScannable: props.sourceScannable,
        destinationScannable: props.destinationScannable,
        overrideValidation: props.isForceMove,
      },
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (
        data &&
        data.moveContainer &&
        data.moveContainer.destinationScannable
      ) {
        setIsShowModalDialog(false);
        props.onSuccess(data.moveContainer.destinationScannable);
      }
    },
    onError: (error) => {
      if (error) {
        const rootCause = error.graphQLErrors[0]?.extensions?.["rootCause"];
        const message = error.graphQLErrors[0]?.message;

        // show success move for moveToSameParentException
        if (rootCause === moveToSameParentException) {
          setIsShowModalDialog(false);
          props.onSuccess(props.destinationScannable);
          return;
        }

        // if props.destinationScannable is provided, will not have force move page.
        // all exception messages will be shown in modal dialog
        if (props.isDirectMove) {
          if (
            forceMovableExceptionMap.has(rootCause) ||
            otherMoveExceptionMap.has(rootCause)
          ) {
            const exception: exceptionObject | undefined =
              otherMoveExceptionMap.get(rootCause) ||
              forceMovableExceptionMap.get(rootCause);
            if (exception) {
              setHeader(t(exception.header));
              setMessage(t(exception.message));
              setAlertType(exception.alertType);
            }
          } else if (message) {
            setMessage(message);
          }
          setIsShowModalDialog(true);
        } else {
          if (forceMovableExceptionMap.has(rootCause)) {
            setIsShowModalDialog(false);
            props.setAdditionalOptions("isForceMovable");

            const forceMovableException: exceptionObject =
              forceMovableExceptionMap.get(rootCause)!;
            props.onForceMovable(
              forceMovableException.header,
              forceMovableException.message,
              forceMovableException.alertType
            );
          } else if (otherMoveExceptionMap.has(rootCause)) {
            const otherException: exceptionObject =
              otherMoveExceptionMap.get(rootCause)!;
            setHeader(t(otherException.header));
            setMessage(t(otherException.message));
            setAlertType(otherException.alertType);
            setIsShowModalDialog(true);
          } else if (message) {
            setMessage(message);
            setIsShowModalDialog(true);
          } else {
            setIsShowModalDialog(true);
          }
        }
      }
    },
  });

  // Execute move container
  useEffect(() => {
    moveContainer();
  }, [moveContainer, props.destinationScannable, props.isForceMove]);

  if (loading) {
    return <FreezeScreen message={t("moving-container")} />;
  }

  return (
    <ModalDialog
      headerTitle={t("connection-issue")}
      displayStatus={isShowModalDialog}
      alertType={alertType}
      alertTitle={header}
      message={message}
      primaryActionMessage={t("try-again")}
      secondaryActionMessage={t(props.secondaryActionMessage)}
      onClickAction={props.onClick}
    />
  );
}

export interface ContainerInfoHeader {
  scannable?: string;
  parentContainerScannable?: string;
  containerType?: string;
  cpt?: string;
  packageCount?: number;
  stackingFilter?: string;
  vsm?: string;
  volumeUtilization?: number;
  isShowVolumeUtilizationAlert?: boolean;
  destinationPageSize?: number;
  destinationPageNumber?: number;
}

export interface MoveProps {
  header: string;
  sourceScannable: string;
  destinationScannable?: string;
  isShowGuidedDestinations?: boolean;
  // Filters destinations from the displayed options list
  filterDestinationLabels?: (destinationLabel: string) => boolean;
  containerInfoHeader?: ContainerInfoHeader;
  message?: string;
  barcodeImage?: BarcodeImage;
  moveInternalState: MoveInternalState;
  setMoveInternalState: React.Dispatch<React.SetStateAction<MoveInternalState>>;
  onSuccess: (destinationScannable: string) => void;
  onFailure: () => void;
}

type subMessagesMapToLocalize = subMessagesMap & {
  isSubTitleTranslationNeeded?: boolean;
};

type scanReaderObject = {
  header: { value: string; isAlreadyTranslated: boolean };
  containerInfoHeader?: ContainerInfoHeader;
  message: string;
  candidateDestinations: subMessagesMapToLocalize[];
  isForceMove: boolean;
  isForceMovableAlert: boolean;
  alertType: alertType;
};

const MoveScanReader = (props: {
  header: { value: string; isAlreadyTranslated: boolean };
  message: string;
  containerInfoHeader?: ContainerInfoHeader;
  subMessages: subMessagesMapToLocalize[];
  barcodeImage?: BarcodeImage;
  callback: (scannable: string) => void;
}) => {
  const { t } = useTranslation(TRANSLATION_NS);
  const header = props.header.isAlreadyTranslated
    ? props.header.value
    : t(props.header.value);
  const subMessages = props.subMessages.map(
    (item: subMessagesMapToLocalize) => {
      return {
        subTitle: item.isSubTitleTranslationNeeded
          ? t(item.subTitle)
          : item.subTitle,
        subMessageList: item.subMessageList,
        subVsmTitle: item.subVsmTitle,
        subVsmLabel: item.subVsmLabel,
      };
    }
  );

  return (
    <React.Fragment>
      <Box className="title" spacingInset="medium">
        <Text alignment="left">
          <Heading type={"d50"} level={1}>
            {header}
          </Heading>
        </Text>
      </Box>
      {props.containerInfoHeader && (
        <Box className="message" spacingInset="none medium">
          {props.containerInfoHeader.scannable && (
            <Text alignment="left" type="b500">
              <strong>
                {t("container-info-container-type", {
                  containerType: props.containerInfoHeader.containerType,
                })}
              </strong>{" "}
              {props.containerInfoHeader.scannable}
            </Text>
          )}
          {props.containerInfoHeader.packageCount && (
            <Text alignment="left" type="b500">
              <strong>{t("container-info-package-count")}</strong>{" "}
              {props.containerInfoHeader.packageCount}
            </Text>
          )}
          {props.containerInfoHeader.cpt && (
            <Text alignment="left" type="b500">
              <strong>{t("container-info-cpt")}</strong>{" "}
              {props.containerInfoHeader.cpt}
            </Text>
          )}
          {props.containerInfoHeader.stackingFilter && (
            <Text alignment="left" type="b500">
              <strong>{t("container-info-stacking-filter")}</strong>{" "}
              {props.containerInfoHeader.stackingFilter}
            </Text>
          )}
          {props.containerInfoHeader.volumeUtilization && (
            <Text
              alignment="left"
              type="b500"
              color={
                props.containerInfoHeader.isShowVolumeUtilizationAlert
                  ? "error"
                  : "primary"
              }
            >
              <strong>{t("container-info-volume-utilization")}</strong>{" "}
              {props.containerInfoHeader.volumeUtilization}%
            </Text>
          )}
          {props.containerInfoHeader.isShowVolumeUtilizationAlert && (
            <>
              <Text alignment="left" type="h500" color={"error"}>
                <strong>{t("container-info-eligible-for-merging")}</strong>
              </Text>
              <Text alignment="left" type="h500" color={"error"}>
                <strong>{t("container-info-move-to-merging-area")}</strong>
              </Text>
            </>
          )}
        </Box>
      )}
      <Box className="message" spacingInset="xxsmall">
        <ScanReader
          message={t(props.message)}
          subMessages={subMessages}
          barcodeImage={props.barcodeImage}
          callback={props.callback}
        />
      </Box>
    </React.Fragment>
  );
};

/**
 * Returns a paginated shallow copy of the array, starting at index 0.
 * If pageSize exceeds array's length, the entire content of the array is returned.
 * If pageNumber is greater than or equal to the maximum number of pages (array length / page size), then it will restart at 0.
 * @param array The array to paginate.
 * @param pageSize The number of elements that make up a single page.
 * @param pageNumber The page number.
 * @returns A shallow copy of elements starting at pageNumber * pageSize and ending at (pageNumber + 1) * pageSize.
 */
export function Paginate<T>(array: T[], pageSize: number, pageNumber: number) {
  const maxPages = Math.ceil(array.length / pageSize);
  const pageNumberWrappedAround = (pageNumber % maxPages) + 1;
  return array.slice(
    (pageNumberWrappedAround - 1) * pageSize,
    pageNumberWrappedAround * pageSize
  );
}

export class Move extends Component<
  MoveProps,
  {
    retries: number;
    secondaryActionMessage: string;
    destinationScannable: string;
    allCandidateDestinations: subMessagesMap[];
    allCandidateDestinationLabels: string[];
    isShowStaticDestinationMessage: boolean;
    forceMovableAlertHeader: string;
    forceMovableAlertMessage: string;
    forceMovableAlertType: alertType;
  }
> {
  constructor(props: MoveProps) {
    super(props);
    this.state = {
      retries: 0,
      secondaryActionMessage: "",
      destinationScannable: this.props.destinationScannable ?? "",
      allCandidateDestinations: [],
      allCandidateDestinationLabels: [],
      isShowStaticDestinationMessage: false,
      forceMovableAlertHeader: "",
      forceMovableAlertMessage: "",
      forceMovableAlertType: "error",
    };
  }

  static defaultProps = {
    isShowGuidedDestinations: true,
  };

  render() {
    let secondaryActionMessage = "";
    if (this.state.retries >= 2) {
      secondaryActionMessage = "report-to-manager";
    }

    let scanReaderParameter: scanReaderObject = {
      header: { value: this.props.header, isAlreadyTranslated: true },
      message: this.props.message ?? "",
      containerInfoHeader: {},
      isForceMove: false,
      isForceMovableAlert: false,
      candidateDestinations: [],
      alertType: "warning",
    };

    switch (this.props.moveInternalState) {
      case "initial": {
        scanReaderParameter.candidateDestinations =
          this.state.allCandidateDestinations.slice(0, 1);
        break;
      }
      case "scanDestinationByVsm": {
        scanReaderParameter.header = {
          value: "scan-destination-by-vsm-title",
          isAlreadyTranslated: false,
        };
        scanReaderParameter.candidateDestinations = [
          {
            isSubTitleTranslationNeeded: true,
            subTitle: "destination-list-title",
            subMessageList: Paginate(
              this.state.allCandidateDestinationLabels,
              this.props.containerInfoHeader?.destinationPageSize ??
                DEFAULT_PAGE_SIZE,
              this.props.containerInfoHeader?.destinationPageNumber ??
                DEFAULT_PAGE_NUMBER
            ),
            subVsmTitle: "VSM",
            subVsmLabel: this.props.containerInfoHeader?.vsm,
          },
        ];
        scanReaderParameter.containerInfoHeader =
          this.props.containerInfoHeader;
        break;
      }
      case "scanDestinationWithContainerUnloaderWorkflow": {
        scanReaderParameter.containerInfoHeader =
          this.props.containerInfoHeader;
        break;
      }
      case "moreDestinations": {
        scanReaderParameter.header = {
          value: "destinations-and-scan-barcode",
          isAlreadyTranslated: false,
        };
        scanReaderParameter.candidateDestinations =
          this.state.allCandidateDestinations.slice(1);
        break;
      }
      case "isForceMovable": {
        scanReaderParameter = {
          header: {
            value: this.state.forceMovableAlertHeader,
            isAlreadyTranslated: false,
          },
          message: this.state.forceMovableAlertMessage,
          isForceMove: false,
          isForceMovableAlert: true,
          candidateDestinations: [],
          alertType: this.state.forceMovableAlertType,
        };
        break;
      }
      case "isForceMoved": {
        scanReaderParameter.isForceMove = true;
        break;
      }
      case "noAdditionalActions": {
        scanReaderParameter.message = "move-to-general-area";
        break;
      }
      default:
        break;
    }

    return (
      <div>
        {scanReaderParameter.isForceMovableAlert && (
          // just need an alert icon on top of page, no text in needed for the alert
          <Alert size="xlarge" type={scanReaderParameter.alertType}>
            {" "}
          </Alert>
        )}
        {!this.props.destinationScannable &&
          this.props.isShowGuidedDestinations && (
            <GetDestinationsForContainer
              setAdditionalActions={this.props.setMoveInternalState}
              scannable={this.props.sourceScannable}
              onSuccess={this.setCandidateDestinations}
            />
          )}
        {this.state.allCandidateDestinations !== undefined && (
          <MoveScanReader
            header={scanReaderParameter.header}
            message={scanReaderParameter.message}
            containerInfoHeader={scanReaderParameter.containerInfoHeader}
            subMessages={scanReaderParameter.candidateDestinations}
            barcodeImage={this.props.barcodeImage}
            callback={(scannable: string) => {
              this.setState({
                destinationScannable: scannable,
              });
            }}
          />
        )}
        {this.state.destinationScannable && (
          <MoveContainer
            sourceScannable={this.props.sourceScannable}
            destinationScannable={this.state.destinationScannable}
            isDirectMove={!!this.props.destinationScannable}
            secondaryActionMessage={secondaryActionMessage}
            isForceMove={scanReaderParameter.isForceMove}
            onClick={this.retryCallback}
            setAdditionalOptions={this.props.setMoveInternalState}
            onForceMovable={(header, message, alertType) => {
              this.setState({
                forceMovableAlertHeader: header,
                forceMovableAlertMessage: message,
                forceMovableAlertType: alertType,
              });
            }}
            onSuccess={this.props.onSuccess}
          />
        )}
      </div>
    );
  }

  setCandidateDestinations = (
    candidateDestinations: subMessagesMap[],
    candidateDestinationsLabels: string[],
    isShowStaticDestinationMessage: boolean
  ) => {
    // Filter out destinations if a filtering function was provided
    if (this.props.filterDestinationLabels) {
      // Filter empty destination lists
      candidateDestinations = candidateDestinations.filter(
        (subMessagesMapValue: subMessagesMap) => {
          // Filter the destination list
          subMessagesMapValue.subMessageList =
            subMessagesMapValue.subMessageList.filter(
              this.props.filterDestinationLabels!
            );

          // Filter out items that may now have an empty message list due to the above filter
          return subMessagesMapValue.subMessageList.length !== 0;
        }
      );

      // Filter destinations
      candidateDestinationsLabels = candidateDestinationsLabels.filter(
        this.props.filterDestinationLabels
      );
    }

    this.setState({
      allCandidateDestinations: candidateDestinations,
      allCandidateDestinationLabels: candidateDestinationsLabels,
      isShowStaticDestinationMessage: isShowStaticDestinationMessage,
    });
  };

  retryCallback = (optionNumber: number) => {
    if (optionNumber === 0) {
      this.setState({
        destinationScannable: this.props.destinationScannable ?? "",
        retries: this.state.retries + 1,
      });
      if (this.state.isShowStaticDestinationMessage) {
        this.props.setMoveInternalState("noAdditionalActions");
      } else {
        this.props.setMoveInternalState("initial");
      }
    } else if (optionNumber === 1) {
      this.props.onFailure();
    }
  };
}

export default Move;
