/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
export type exceptionObject = {
  header: string;
  message: string;
  alertType: alertType;
};

export type alertType = "error" | "warning";

export const moveToSameParentException = "MovementToSameParentException";

export const forceMovableExceptionMap = new Map<string, exceptionObject>([
  [
    "HazmatContainerException",
    {
      header: "hazmat-container-exception-header",
      message: "hazmat-container-exception-message",
      alertType: "warning",
    },
  ],
  [
    "LoadGroupIdMismatchException",
    {
      header: "load-group-id-mismatch-exception-header",
      message: "load-group-id-mismatch-exception-message",
      alertType: "error",
    },
  ],
  [
    "BusinessRulePropertiesNotPresentException",
    {
      header: "business-rule-properties-not-present-exception-header",
      message: "business-rule-properties-not-present-exception-message",
      alertType: "error",
    },
  ],
  [
    "StackingFilterAllocationNotFoundException",
    {
      header: "stacking-filter-allocation-not-found-exception-header",
      message: "stacking-filter-allocation-not-found-exception-message",
      alertType: "error",
    },
  ],
  [
    "ContainerClosedException",
    {
      header: "container-closed-exception-header",
      message: "container-closed-exception-mesage",
      alertType: "error",
    },
  ],
  [
    "ParentContainerClosedException",
    {
      header: "parent-container-closed-exception-header",
      message: "parent-container-closed-exception-message",
      alertType: "error",
    },
  ],
  [
    "CustomsFlaggedValidationException",
    {
      header: "customs-flagged-validation-exception-header",
      message: "customs-flagged-validation-exception-message",
      alertType: "warning",
    },
  ],
  [
    "ReservationKeyMismatchException",
    {
      header: "reservation-key-mismatch-exception-header",
      message: "reservation-key-mismatch-exception-header",
      alertType: "error",
    },
  ],
  [
    "StackingFilterMismatchException",
    {
      header: "stacking-filter-mismatch-exception-header",
      message: "stacking-filter-mismatch-exception-message",
      alertType: "error",
    },
  ],
  [
    "ContainerInterceptedException",
    {
      header: "container-intercepted-exception-header",
      message: "container-intercepted-exception-message",
      alertType: "warning",
    },
  ],
]);

export const otherMoveExceptionMap = new Map<string, exceptionObject>([
  [
    "IncompatibleContainerTypesException",
    {
      header: "incompatible-container-types-exception-header",
      message: "incompatible-container-types-exception-message",
      alertType: "error",
    },
  ],
  [
    "MovementToItselfException",
    {
      header: "movement-to-itself-exception-header",
      message: "movement-to-itself-exception-message",
      alertType: "error",
    },
  ],
  [
    "UnexpectedNodeException",
    {
      header: "unexpected-node-exception-header",
      message: "unexpected-node-exception-message",
      alertType: "warning",
    },
  ],
  [
    "OpenContainerNotFoundException",
    {
      header: "open-container-not-found-exception-header",
      message: "open-container-not-found-exception-message",
      alertType: "warning",
    },
  ],
  [
    "ContainerNotClosedException",
    {
      header: "container-not-closed-exception-header",
      message: "container-not-closed-exception-message",
      alertType: "warning",
    },
  ],
  [
    "ExclusiveParentException",
    {
      header: "exclusive-parent-exception-header",
      message: "exclusive-parent-exception-message",
      alertType: "error",
    },
  ],
  [
    "UnrecognizedContainerException",
    {
      header: "unrecognized-container-exception-header",
      message: "unrecognized-container-exception-message",
      alertType: "error",
    },
  ],
  [
    "TrailerNotFoundException",
    {
      header: "trailer-not-found-exception-header",
      message: "trailer-not-found-exception-message",
      alertType: "error",
    },
  ],
]);
