import React, {useMemo, useState} from "react";
import {ScanTester} from "./ScanTester";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import DisplayLoader from "../common/loading/DisplayLoader";
import {FCMenuDecoder, FCMenuProperties} from "../../environment/fcMenu";
import {useHistory} from "react-router-dom";
import {ApiCallStatus} from "./ApiCallStatus";
import {ScanType} from "../util/ScanIdentifierUtil";
import {EdgeMappingRepo} from "../../repo/edgeMappingRepo";
import {TestLightCommandInput, TestLightCommandOutput} from "@amzn/edgedevicemappingservice-client";
import Checkbox from "@amzn/meridian/checkbox";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import {JsonUtil} from "../util/JsonUtil";
import {LightControlClientRepo, TriggerCartLight} from "../../repo/lightControlServiceRepo";

interface TestDeviceMappingProps {
    edgeDeviceMappingRepo: EdgeMappingRepo,
    lightControlClientRepo: LightControlClientRepo,
}

const LIGHT_COLOR_GREEN: string = "#00FF00";
const LIGHT_TIMEOUT_SECONDS: number = 5;

export function TestDeviceMapping(props: TestDeviceMappingProps) {
    const [scannedId, setScannedId] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);
    const [lcsResult, setLcsResult] = React.useState<number | undefined>(undefined);
    const [debugLog, setDebugLog] = useState<string[]>([])
    const [autoAdvance, setAutoAdvance] = useState(true)
    const [testApiResponse, setTestApiResponse] = useState<TestLightCommandOutput | undefined>()


    const history = useHistory();
    const fcMenuProps = useMemo<FCMenuProperties>(() => {
        return FCMenuDecoder.decodeFcMenuCookie()!;
    }, [])

    const wiredLightsResourceIdToLabel = new Map<string, string>([
        ["08c18ee3-4dcd-b51b-3b4c-19c416192667", "STG-South-F-18"],
        ["1cc18ee3-4f12-4716-fcdb-ca7a3448de3f", "STG-South-F-15"],
        ["20c18ee3-4c74-8f81-71ee-2f79f88bc5f2", "STG-South-F-6"],
        ["20c18ee3-4de0-7490-7769-35a8fb858d27", "STG-South-F-12"],
        ["28c18ee3-4e03-c1b7-33e5-f9e6b8996229", "STG-South-F-3"],
        ["34c18ee3-4e22-8894-8f22-29c0420bd8ca", "STG-South-F-10"],
        ["44c18ee3-4ef1-e833-1e53-67433099b08a", "STG-South-F-5"],
        ["48c18ee3-4c4e-8236-1dd7-b2449f023908", "STG-South-F-1"],
        ["6cc18ee3-4f03-4c35-c122-2c77c601303d", "STG-South-F-23"],
        ["74c19ecb-3038-9313-a260-655e5a946dda", "STG-South-F-28"],
        ["7ec18ee3-4e84-9e06-86c8-ba99384ab17b", "STG-South-F-4"],
        ["9cc18ee3-4f70-048a-7345-4a97ee16e9d9", "STG-South-F-24"],
        ["a6c19ecb-2fc2-ab2a-8fc1-e2939ef7bd0d", "STG-South-F-27"],
        ["a8c18ee3-4d41-a0f1-c331-4a0c0a75f02e", "STG-South-F-2"],
        ["acc18ee3-4ea5-b17d-26a7-7a656c5deed3", "STG-South-F-14"],
        ["aec18ee3-4d40-f061-9fd1-bc3ffd1edd1e", "STG-South-F-8"],
        ["b0c18ee3-4d72-e304-a15d-1e29bf7661cb", "STG-South-F-17"],
        ["b2c18ee3-4d13-b271-3f4c-dbe5a6398b4c", "STG-South-F-16"],
        ["b2c18ee3-4d9a-d355-fff7-d99925c3f921", "STG-South-F-9"],
        ["b4c18ee3-4ebf-5970-2a4b-8df4d76bfbba", "STG-South-F-20"],
        ["c6c18ee3-4e3f-d328-4ec3-ae80116e59c5", "STG-South-F-13"],
        ["cec19ecb-2f4e-17c8-9777-86324a4f37a3", "STG-South-F-26"],
        ["d4c18ee3-4cfe-448c-f2f7-ad0607d05c1b", "STG-South-F-11"],
        ["d4c19ecb-2eaf-2763-ab30-e1da2666e46f", "STG-South-F-25"],
        ["d8c18ee3-4e81-9ff2-99e2-c6a42a5606c6", "STG-South-F-22"],
        ["dcc18ee3-4e47-7343-ad8e-114f6dfbaa3d", "STG-South-F-19"],
        ["ecc18ee3-4e20-4035-9e25-d28b05f8c1ae", "STG-South-F-21"],
        ["fcc18ee3-4cd4-2c30-ae5f-b7e00f39f811", "STG-South-F-7"],
        ["00c18ee3-4d70-c3b0-89c7-475745c099e5", "STG-South-P-25"],
        ["0ec18ee3-4e57-a3d5-81c5-6011c85c9c82", "STG-South-P-27"],
        ["22c18ee3-4f71-2f70-ef9e-89fd70d00fd1", "STG-South-P-5"],
        ["22c18ee3-5055-07a8-71b0-86bc848f0706", "STG-South-P-1"],
        ["28c18ee3-4fe6-4788-4aee-8835ad4c8a37", "STG-South-P-18"],
        ["44c18ee3-5054-3c82-595c-cc03a527be08", "STG-South-P-13"],
        ["50c18ee3-5110-2598-a49d-217913285448", "STG-South-P-3"],
        ["56c18ee3-4f25-8aac-f8a3-8d86f47a4fac", "STG-South-P-16"],
        ["5ac18ee3-4ff5-3a8a-7ca2-471b32970c13", "STG-South-P-23"],
        ["5cc18ee3-4e29-c7da-1530-1a42c517f9c7", "STG-South-P-19"],
        ["5cc18ee3-50b4-4b58-6a00-2f9f96bb1a12", "STG-South-P-2"],
        ["5ec18ee3-4ec8-101f-2f3f-273b593eec7c", "STG-South-P-9"],
        ["64c18ee3-4e60-1514-9ef2-2a5554009484", "STG-South-P-14"],
        ["6ac18ee3-503f-0d05-f258-0f76ea011dcd", "STG-South-P-7"],
        ["74c18ee3-4f31-e4f3-1b41-79ea46568ab2", "STG-South-P-10"],
        ["7ac18ee3-4ffa-7a50-917e-c916703503a5", "STG-South-P-12"],
        ["7cc18ee3-50a1-90b9-393d-46e51db59fe9", "STG-South-P-8"],
        ["86c18ee3-4df5-451e-67eb-3d47289891d4", "STG-South-P-26"],
        ["8ec18ee3-4ec5-ec4a-1d96-96ba9d35b92b", "STG-South-P-15"],
        ["9ac18ee3-4f89-f6a8-ffb9-f60e87169206", "STG-South-P-11"],
        ["a4c18ee3-4d1b-6711-efd0-d7031b1dde3f", "STG-South-P-24"],
        ["aac18ee3-4f7b-3b0c-fe11-e169865dc1f3", "STG-South-P-17"],
        ["aec18ee3-4e93-ebc4-fab9-fda09bcf38ec", "STG-South-P-20"],
        ["c6c18ee3-4f7e-ac8d-9c7f-04a9a77959ce", "STG-South-P-22"],
        ["c6c18ee3-4fdb-e20b-6a52-0df7e9479873", "STG-South-P-6"],
        ["c8c18ee3-4efb-81be-a6a6-99816b520465", "STG-South-P-4"],
        ["fac18ee3-4ed5-fc18-91b7-677c8106707e", "STG-South-P-28"],
        ["fac18ee3-4efd-dfe0-05f4-d0715b9f084d", "STG-South-P-21"],
    ])

    const appendDebugLog = (s: string) => {
        debugLog.push(s + "\n")
        setDebugLog(debugLog)
    }

    const startOver = () => {
        setScannedId(undefined);
        setLoading(false);
        setLcsResult(undefined);
        setDebugLog([])
    }
    const onScan = async (id: string, scanType: ScanType) => {
        setLoading(true);
        setScannedId(id);
        try {
            if (scanType === ScanType.RESOURCE_ID) {
                if (wiredLightsResourceIdToLabel.has(id)) {
                    const testWiredLightInput: TriggerCartLight = {
                        nodeId: fcMenuProps.warehouseId,
                        cartId: "FAKE_ASSET_ID",
                        location: wiredLightsResourceIdToLabel.get(id)
                    }

                    appendDebugLog("calling lcs trigger cart light API: " + JsonUtil.prettyPrint(testWiredLightInput))

                    const response = await props.lightControlClientRepo.triggerCartLight(testWiredLightInput)
                    setTestApiResponse(response)
                    appendDebugLog("response: " + JsonUtil.prettyPrint(response))
                    setLcsResult(200)
                } else {
                    appendDebugLog(`mapping does not exist for scanned resource: ${id}`)
                    setLcsResult(400)
                }
            } else {
                const testApiInput: TestLightCommandInput = {
                    nodeId: fcMenuProps.warehouseId,
                    deviceId: id,
                    deviceType: props.edgeDeviceMappingRepo.scanTypeToScannableIdType(scanType),
                    color: LIGHT_COLOR_GREEN,
                    pattern: "SLOW",
                    timeoutInSeconds: LIGHT_TIMEOUT_SECONDS
                }

                appendDebugLog("calling edms test API: " + JsonUtil.prettyPrint(testApiInput))

                const response = await props.edgeDeviceMappingRepo.testLightFromAnyDevice(testApiInput)
                setTestApiResponse(response)
                appendDebugLog("response: " + JsonUtil.prettyPrint(response))
                setLcsResult(200)
            }
        } catch (e) {
            appendDebugLog("ERROR: " + e)
            setLcsResult(500);
        } finally {
            setLoading(false);
        }
    }

    const onGoBackCallback = () => {
        history.push("/");
    }

    if (!scannedId) {
        return (
            <Column alignmentHorizontal={"center"}>
                <ScanTester
                    onSuccessCallback={onScan}
                    goBackCallback={onGoBackCallback}
                />
                <Checkbox checked={autoAdvance} onChange={setAutoAdvance}>
                    Auto-advance on success
                </Checkbox>
            </Column>
        )

    }
    if (loading) {
        return (
            <Column alignmentHorizontal={"center"}
                    alignmentVertical={"center"}
                    minHeight={"100%"}>
                <DisplayLoader message={'Triggering Light'}/>
            </Column>
        )
    }
    if (lcsResult) {
        const success = lcsResult === 200
        let message

        if (success) {
            message = (
                <Table headerColumns={1} spacing={"small"}>
                    <TableRow>
                        <TableCell>Cart Id</TableCell>
                        <TableCell>{testApiResponse?.cartId}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>UWB TagId</TableCell>
                        <TableCell>{testApiResponse?.UWBTagId}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Light Id</TableCell>
                        <TableCell>{testApiResponse?.lightId}</TableCell>
                    </TableRow>
                </Table>
            )
        } else {
            message = (<div>
                <Text alignment="center" type="h400" color={"error"}>Failed to trigger light for the cart.</Text>
                <Text alignment="center" type="h100">Try to connect the equipment again. If the problem persists contact your Shift or Area Manager</Text>
            </div>)
        }

        return <ApiCallStatus
            success={success}
            message={message}
            debugLog={debugLog}
            startOverMessage={'Scan Again'}
            startOverCallback={startOver}
            autoAdvanceOnSuccess={autoAdvance}
        />
    }

    return <Text>Unknown state encountered.</Text>
}
