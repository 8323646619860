import {
    EchoCommand,
    EchoCommandOutput,
    HeiseClient,
    GoCartAuditCommand,
    GoCartAuditCommandInput,
    GoCartAuditCommandOutput,
    GoCartOperationalStateUpdateCommand,
    GoCartOperationalStateUpdateCommandInput,
    GoCartOperationalStateUpdateCommandOutput
} from "@amzn/heiseservice-client"
import {Configuration, getHeiseServiceRegion} from "../configuration/configuration"
import {CredentialRepo} from "./credentialRepo"

export class HeiseServiceRepo {
    private client: HeiseClient

    constructor(credentialRepo: CredentialRepo, configuration: Configuration) {
        this.client = new HeiseClient({
            endpoint: configuration.heiseServiceUrl,
            region: getHeiseServiceRegion(),
            credentials: credentialRepo.getCredentials,
            serviceId: "execute-api"
        })
    }

    async echoTest(): Promise<EchoCommandOutput> {
        const command: EchoCommand = new EchoCommand({string: "test"})
        return await this.client.send(command);
    }

    async cartAuditEvent(event: GoCartAuditCommandInput): Promise<GoCartAuditCommandOutput> {
        const command = new GoCartAuditCommand(event)
        return await this.client.send(command);
    }

    async redTagScan(event: GoCartOperationalStateUpdateCommandInput): Promise<GoCartOperationalStateUpdateCommandOutput> {
        const command = new GoCartOperationalStateUpdateCommand(event)
        return await this.client.send(command);
    }
}

