import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Expander from "@amzn/meridian/expander";
import React, {useState} from "react";
import tagImage from "../../media/images/tag.png";
import lightImage from "../../media/images/light.svg";

const ControlledExpander = (props:any) => {
    const [open, setOpen] = useState()
    return <Expander open={open} onChange={setOpen} {...props} />
}

export function HelpPage() {
    return <Box minHeight={"100%"} minWidth={"100%"}>
        <ControlledExpander title="Cart Barcode" type="section">
            <Text>The barcode to be scanned for the cart is the Cart's main barcode</Text>
        </ControlledExpander>
        <ControlledExpander title="Tag Barcode" type="section">
            <Text>The barcode needed will be present on the tag like so</Text>
            <img
                src={tagImage}
                style={{ margin: "auto", maxWidth: '80%'}}
                alt={"Front side of UWB Tag"}
            />
        </ControlledExpander>
        <ControlledExpander title="Light Barcode" type="section">
            <Text>The QR code needed will be present on the light like so</Text>
            <img
                src={lightImage}
                style={{ margin: "auto", maxWidth: '80%'}}
                alt={"Front side of wireless light"}
            />
        </ControlledExpander>
    </Box>;
}
