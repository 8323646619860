/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { gql } from "@apollo/client/core";

export const GET_DESTINATIONS_FOR_CONTAINER_QUERY = gql`
  query getDestinationsForContainer($input: GetDestinationsForContainerInput!) {
    getDestinationsForContainer(input: $input) {
      candidateDestinationContainers {
        containerType
        labels
      }
      isShowStaticDestinationMessage
    }
  }
`;
