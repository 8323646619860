/**
 * Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */

import React from "react";
import Divider from "@amzn/meridian/divider";
import "./bottom.css";

interface AtBottomAfterDividerProps {
  children: React.ReactElement;
}

function AtBottomAfterDivider(props: AtBottomAfterDividerProps) {
  return (
    <div className="bottomPosition">
      <Divider size="small" spacingBefore="none" spacingAfter="none" />
      {props.children}
    </div>
  );
}

export default AtBottomAfterDivider;
