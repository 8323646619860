/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import styles from "./ContainerInfoWorkflow.module.css";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import IdentifyByContainer, {
  IdentifyContainerResult,
} from "../../functions/identifyContainer/identifyByContainer/IdentifyByContainer";
import Workflow, {
  MoreOptionRefProps,
  Task as WorkflowTask,
} from "../Workflow";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import AtBottomAfterDivider from "../../common/AtBottomAfterDivider";
import CallToAction from "../../common/callToAction/CallToAction";
import { BarcodeImage } from "../../common/scans/ScanReader";

enum Task {
  /** Identify container by scanning container barcode.  */
  ScanContainer,
  /** Display the scanned container information. */
  ShowContainerInfo,
}

interface ContainerInfoWorkflowProps {
  currentTask?: Task;
  localizedBannerTitle?: string;
  additionalContainerInfo?: {
    withHazmat?: boolean;
    withAggregateProperty?: boolean;
    withHierarchyInfo?: boolean;
  };
  /** The provided container type adjusts message displayed to associate (see localization file to see what is supported). */
  displayMessagesByContainerType?: string;
  moreOptionsList: () => Array<MoreOptionRefProps<Task>>;
  onGoBackHomeOnClick: (optionNumber: number) => void;
}

interface ContainerInfoWorkflowStateData {
  currentTask: Task;
  sourceScannable?: string;
  sourceDisplayName?: string;
  sourceContainerType?: string;
  sourceStackingFilter?: string;
  sourceScannableCpt?: string;
  sourceScannableVsm?: string;
  sourceContainerPackageCount?: number;
  sourceContainerCurrentUtilization?: number;
}

export const ContainerInfoWorkflow = (props: ContainerInfoWorkflowProps) => {
  const { t } = useTranslation(
    "components.workflows.subworkflows.ContainerInfoWorkflow"
  );

  const dateTimeFormat = new Intl.DateTimeFormat([], {
    dateStyle: "short",
    timeStyle: "long",
  });

  const [state, setState] = useReducer(
    (
      state: ContainerInfoWorkflowStateData,
      newState: Partial<ContainerInfoWorkflowStateData>
    ) => ({
      ...state,
      ...newState,
    }),
    {
      currentTask: props.currentTask ?? Task.ScanContainer,
    }
  );

  const resetState = (task: Task) => {
    setState({
      currentTask: task,
      sourceScannable: undefined,
      sourceDisplayName: undefined,
      sourceContainerType: undefined,
      sourceStackingFilter: undefined,
      sourceScannableCpt: undefined,
      sourceScannableVsm: undefined,
      sourceContainerPackageCount: undefined,
      sourceContainerCurrentUtilization: undefined,
    });
  };

  return (
    <Workflow
      title={
        props.localizedBannerTitle ??
        t("container-info-banner-title", {
          containerType: props.displayMessagesByContainerType,
        })
      }
      currentTaskId={state.currentTask}
      moreOptionsCallback={props.moreOptionsList}
      updateCurrentTaskId={(currentTask: Task) =>
        setState({ currentTask: currentTask })
      }
    >
      <WorkflowTask taskId={Task.ScanContainer}>
        <IdentifyByContainer
          header={t("container-scan-title", {
            containerType: props.displayMessagesByContainerType,
          })}
          message={""}
          additionalContainerInfo={{
            withHazmat: props.additionalContainerInfo?.withHazmat,
            withAggregateProperty:
              props.additionalContainerInfo?.withAggregateProperty,
            withHierarchyInfo: props.additionalContainerInfo?.withHierarchyInfo,
          }}
          barcodeImage={BarcodeImage.QR_BARCODE}
          onSuccess={(result: IdentifyContainerResult) => {
            setState({
              sourceScannable: result.containerInfo.containerLabel,
              sourceDisplayName:
                result.containerInfo.containerLabel ??
                result.directSourceScannable,
              sourceContainerType: result.containerInfo.containerType,
              sourceStackingFilter:
                result.containerInfo.stackingFilter ?? undefined,
              sourceScannableCpt:
                dateTimeFormat.format(Number(result.containerInfo.cpt)) ??
                undefined,
              sourceScannableVsm: result.containerInfo.vsm ?? undefined,
              sourceContainerPackageCount:
                result.containerInfo.hierarchyInfo?.packageChildrenCount ??
                undefined,
              sourceContainerCurrentUtilization:
                result.containerInfo.aggregateProperty?.volumeProperty
                  ?.currentUtilization ?? undefined,
              currentTask: Task.ShowContainerInfo,
            });
          }}
          onFailure={() => resetState(Task.ScanContainer)}
        />
      </WorkflowTask>

      <WorkflowTask taskId={Task.ShowContainerInfo}>
        <div className={styles["task"]}>
          <div className={styles["info"]}>
            <Box className="title" spacingInset="medium">
              <Text alignment="left">
                <Heading type={"h400"} level={1}>
                  {t("container-info-title", {
                    containerType: props.displayMessagesByContainerType,
                  })}
                </Heading>
              </Text>
            </Box>
            <Box className="message" spacingInset="medium">
              <Text alignment="left" type="b400">
                <strong>
                  {t("container-info-container-type", {
                    containerType: state.sourceContainerType,
                  })}
                </strong>{" "}
                {state.sourceDisplayName}
              </Text>
              <Text alignment="left" type="b400">
                <strong>{t("container-info-stacking-filter")}</strong>{" "}
                {state.sourceStackingFilter}
              </Text>
              <Text alignment="left" type="b400">
                <strong>{t("container-info-cpt")}</strong>{" "}
                {state.sourceScannableCpt}
              </Text>
              {state.sourceContainerPackageCount && (
                <Text alignment="left" type="b400">
                  <strong>{t("container-info-package-count")}</strong>{" "}
                  {state.sourceContainerPackageCount}
                </Text>
              )}
              {state.sourceContainerCurrentUtilization && (
                <Text alignment="left" type="b400">
                  <strong>{t("container-info-utilization")}</strong>{" "}
                  {state.sourceContainerCurrentUtilization}
                </Text>
              )}
            </Box>
          </div>
          <AtBottomAfterDivider>
            <CallToAction
              primaryButtonLabel={t("scan-another-container", {
                containerType: props.displayMessagesByContainerType,
              })}
              secondaryButtonLabel={t("back-to-home")}
              onClick={(optionNumber: number) => {
                if (optionNumber === 0) {
                  resetState(Task.ScanContainer);
                } else {
                  props.onGoBackHomeOnClick(optionNumber);
                }
              }}
            />
          </AtBottomAfterDivider>
        </div>
      </WorkflowTask>
    </Workflow>
  );
};

export default ContainerInfoWorkflow;
