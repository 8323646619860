import {
    LightCommandCommandInput,
    LightControlClient,
    CommandType, LightCommandCommandOutput, LightCommandCommand,
} from "@amzn/lightcontrolservice-client";
import {Configuration} from "../configuration/configuration";
import {CredentialRepo} from "./credentialRepo";

export interface TriggerCartLight {
    nodeId: string
    cartId: string
    location?: string
}

export class LightControlClientRepo {
    private static LIGHT_COLOR_GREEN: string = "#00FF00";
    private static LIGHT_TIMEOUT_SECONDS: number = 10;
    private client: LightControlClient;

    constructor(credentialRepo: CredentialRepo, configuration: Configuration) {
        this.client = new LightControlClient({
            endpoint: configuration.lightControlServiceUrl,
            region: configuration.region,
            credentials: credentialRepo.getCredentials,
            serviceId: "execute-api"
        });
    }

    async triggerCartLight(input: TriggerCartLight): Promise<LightCommandCommandOutput> {
        const command: LightCommandCommand = new LightCommandCommand({
            assetId: input.cartId,
            command: CommandType.ON,
            timeoutInSeconds: LightControlClientRepo.LIGHT_TIMEOUT_SECONDS,
            color: LightControlClientRepo.LIGHT_COLOR_GREEN,
            nodeId: input.nodeId,
            location: input.location,
        } as LightCommandCommandInput)
        const result = await this.client.send<LightCommandCommandInput, LightCommandCommandOutput>(command);
        console.log("LCS Triggered ", result);
        return result;
    }

}
