/**
 * Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 */
import { useMutation } from "@apollo/client";
import { MoveContainerDocument } from "../../../graphql.generated";

export interface MoveProps {
  sourceScannable: string;
  destinationScannable: string;
  isForceMove: boolean;
  onMoveSuccess: (
    sourceScannable: string,
    destinationScannable: string
  ) => void;
  onMoveFailure: (exception: string, message: string) => void;
}

/**
 * The move container custom hook which returns a move container mutation which
 * takes a @param props with sourceScannable, destinationScannable, and isForceMove
 * as the input. The mutation executes moving the source container to the destination.
 * @returns move container mutation
 */
export function useMoveContainer() {
  const [moveContainer] = useMutation(MoveContainerDocument);

  return (props: MoveProps) => {
    moveContainer({
      fetchPolicy: "no-cache",
      variables: {
        input: {
          sourceScannable: props.sourceScannable,
          destinationScannable: props.destinationScannable,
          overrideValidation: props.isForceMove,
        },
      },
      onCompleted: () => {
        props.onMoveSuccess(props.sourceScannable, props.destinationScannable);
      },
      onError: (error) => {
        const errorType = error?.graphQLErrors[0]?.extensions?.["errorType"];
        const message = error?.graphQLErrors[0]?.message;
        props.onMoveFailure(errorType, message);
      },
    });
  };
}

export default useMoveContainer;
